import {LanguageKey} from '../core/Translate';

export function autodetectLanguage(): LanguageKey {
  const location = window.location;
  if (location.pathname.includes('/fr/') || location.search.includes('lang=fr')) {
    return LanguageKey.FR;
  } else if (location.pathname.includes('/en/') || location.search.includes('lang=en')) {
    return LanguageKey.EN;
  } else if (location.pathname.includes('/nl/') || location.search.includes('lang=nl')) {
    return LanguageKey.NL;
  } else {
    const languages = navigator.languages || [navigator.language];
    for (let lang of languages) {
      const lowerLang = lang.toLocaleLowerCase();
      if (lowerLang.startsWith('nl')) {
        return LanguageKey.NL;
      } else if (lowerLang.startsWith('fr')) {
        return LanguageKey.FR;
      } else if (lowerLang.startsWith('en')) {
        return LanguageKey.EN;
      }
    }

    return LanguageKey.EN;
  }
}
