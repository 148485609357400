import BasicTab from '../components/BasicTab';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';

export default function ExistingChargerTab() {
  const [{existingCharger, keepExistingCharger}, readOnly] = useAppSelector(state => [
    state.session.data,
    isReadOnly(state)
  ]);
  const actor = useAppActor();

  return (
    <BasicTab tab={TabKey.ExistingCharger}>
      <YesNoRadioGroup
        value={existingCharger}
        onChange={value => actor.updateSession({existingCharger: value})}
        disabled={readOnly}
      />
      {existingCharger && (
        <>
          <p>{T('charger.existingCharger.keep')}</p>
          <YesNoRadioGroup
            value={keepExistingCharger}
            onChange={value => actor.updateSession({keepExistingCharger: value})}
            disabled={readOnly}
          />
        </>
      )}
    </BasicTab>
  );
}
