import styles from './index.module.scss';

interface CheckboxProps {
  id: string;
  checked: boolean;
  label: string | JSX.Element;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export default function Checkbox(props: CheckboxProps) {
  const {id, checked, label, onChange, disabled} = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.checked);
  };

  return (
    <div className={styles.checkboxwrap}>
      <label className={`custom-checkbox ${styles.checkbox}`}>
        <input
          id={id}
          className="tml-checkbox custom-checkbox-input"
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className="custom-checkbox__mark" />
      </label>
      <label className={styles.checkboxlabel} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
