import React, {useState} from 'react';
import {Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import Checkbox from '../components/inputs/Checkbox';
import Form from '../components/inputs/Form';
import SelectInput from '../components/inputs/SelectInput';
import TextInput from '../components/inputs/TextInput';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {Discount} from '../models/Discount';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {areActionCodesSupported} from '../utils/Features';
import {useFormState} from '../utils/FormState';
import {useDelayedEffect} from '../utils/Hooks';
import {validatePostcode, validateRequired} from '../utils/Validation';

export default function UCBInstallationBillingTab() {
  const [
    {
      firstName,
      lastName,
      email,
      phone,
      installationStreet,
      installationStreetNr,
      installationPostcode,
      installationCity,

      billingAtInstallation,
      billingFirstName,
      billingLastName,
      billingStreet,
      billingStreetNr,
      billingPostcode,
      billingCity,

      actionCode,
      agreesWithCallback = false
    },
    readOnly
  ] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();
  const formState = useFormState();

  const [discount, setDiscount] = useState<Discount>();
  useDelayedEffect(
    () => {
      if (readOnly) {
        return;
      }

      if (actionCode) {
        api.getDiscount(actionCode).then(discount => {
          setDiscount(discount);
          formState.setServerError(
            'action-code',
            discount.valid ? undefined : T('confirmation.quote.actionCode.invalid')
          );
        });
      } else {
        setDiscount(undefined);
        formState.setServerError('action-code', undefined);
      }
    },
    [actionCode],
    500
  );

  return (
    <BasicTab
      tab={TabKey.UCBInstallationBillingData}
      form={formState}
      nextDisabled={formState.getServerError('action-code') !== undefined}
    >
      <Form state={formState}>
        <Row>
          <TextInput
            name="firstName"
            value={firstName}
            onChange={value => actor.updateSession({firstName: value})}
            label={T('input.firstName')}
            sm={6}
            validate={validateRequired}
            disabled={readOnly}
          />
          <TextInput
            name="lastName"
            value={lastName}
            onChange={value => actor.updateSession({lastName: value})}
            label={T('input.lastName')}
            sm={6}
            validate={validateRequired}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <TextInput
            name="email"
            value={email}
            onChange={value => actor.updateSession({email: value})}
            label={T('input.email')}
            sm={6}
            validate={validateRequired}
            disabled={readOnly}
          />
          <TextInput
            name="phone"
            value={phone}
            onChange={value => actor.updateSession({phone: value})}
            label={T('input.phone')}
            sm={6}
            validate={validateRequired}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <TextInput
            name="street"
            value={installationStreet}
            onChange={value => actor.updateSession({installationStreet: value})}
            label={T('input.street')}
            sm={8}
            validate={validateRequired}
            disabled={readOnly}
          />
          <TextInput
            name="streetNr"
            value={installationStreetNr}
            onChange={value => actor.updateSession({installationStreetNr: value})}
            label={T('input.streetNr')}
            sm={4}
            validate={validateRequired}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <TextInput
            name="postcode"
            value={installationPostcode}
            onChange={value => actor.updateSession({installationPostcode: value})}
            label={T('input.postcode')}
            sm={4}
            validate={validatePostcode}
            disabled={readOnly}
          />
          <TextInput
            name="city"
            value={installationCity}
            onChange={value => actor.updateSession({installationCity: value})}
            label={T('input.city')}
            sm={8}
            validate={validateRequired}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <SelectInput value="BE" onChange={() => {}} label={T('input.country')} md={4} disabled>
            <option value="BE">{T('country.belgium')}</option>
          </SelectInput>
        </Row>
        <Checkbox
          id="billing_at_installation"
          checked={billingAtInstallation}
          label={T('confirmation.installationAndBilling.sameAsInstallation')}
          onChange={checked => actor.updateSession({billingAtInstallation: checked})}
          disabled={readOnly}
        />
        {!billingAtInstallation && (
          <>
            <Row>
              <TextInput
                name="billingFirstName"
                value={billingFirstName}
                onChange={value => actor.updateSession({billingFirstName: value})}
                label={T('input.firstName')}
                sm={6}
                validate={validateRequired}
                disabled={readOnly}
              />
              <TextInput
                name="billingLastName"
                value={billingLastName}
                onChange={value => actor.updateSession({billingLastName: value})}
                label={T('input.lastName')}
                sm={6}
                validate={validateRequired}
                disabled={readOnly}
              />
            </Row>
            <Row>
              <TextInput
                name="billingStreet"
                value={billingStreet}
                onChange={value => actor.updateSession({billingStreet: value})}
                label={T('input.street')}
                sm={8}
                validate={validateRequired}
                disabled={readOnly}
              />
              <TextInput
                name="billingStreetNr"
                value={billingStreetNr}
                onChange={value => actor.updateSession({billingStreetNr: value})}
                label={T('input.streetNr')}
                sm={4}
                validate={validateRequired}
                disabled={readOnly}
              />
            </Row>
            <Row>
              <TextInput
                name="billingPostcode"
                value={billingPostcode}
                onChange={value => actor.updateSession({billingPostcode: value})}
                label={T('input.postcode')}
                sm={4}
                validate={validatePostcode}
                disabled={readOnly}
              />
              <TextInput
                name="billingCity"
                value={billingCity}
                onChange={value => actor.updateSession({billingCity: value})}
                label={T('input.city')}
                sm={8}
                validate={validateRequired}
                disabled={readOnly}
              />
            </Row>
            <Row>
              <SelectInput value="BE" onChange={() => {}} label={T('input.country')} md={4} disabled>
                <option value="BE">{T('country.belgium')}</option>
              </SelectInput>
            </Row>
          </>
        )}

        <Row>
          <Checkbox
            id="agree_with_data_retention"
            checked={agreesWithCallback}
            onChange={checked => actor.updateSession({agreesWithCallback: checked})}
            disabled={readOnly}
            label={
              <span>
                {T('general.information.agree')}&nbsp;
                <a href="https://www.smappee.com/privacy/">{T('general.information.privacyPolicy')}</a>
              </span>
            }
          />
        </Row>

        {areActionCodesSupported() && (
          <>
            <p>{T('confirmation.quote.actionCode')}</p>
            <Row>
              <TextInput
                name="action-code"
                value={actionCode || ''}
                label={T('confirmation.quote.actionCode.placeholder')}
                onChange={code => actor.updateSession({actionCode: code})}
                md={4}
                isValid={discount !== undefined}
                disabled={readOnly}
              />
            </Row>
          </>
        )}
      </Form>
    </BasicTab>
  );
}
