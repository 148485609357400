import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  max: number;
  current: number;
  width: number;
}

export default function ProgressBar(props: ProgressBarProps) {
  const {max, current, width} = props;
  const percent = ((current / max) * 100) | 0;

  return (
    <div className={styles.progress} style={{width}}>
      <div className={styles.progressBar} style={{width: `${percent}%`}} />
    </div>
  );
}
