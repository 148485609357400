import {CableType, ConfiguratorData, PhaseType} from './ConfiguratorData';

export interface PublicPrices {
  EVWall3PhaseWithSocket?: number;
  EVWallBusinessWithSocket?: number;
  EVBase1SocketWithSocket?: number;
  EVBase2SocketWithSocket?: number;

  EVWall3PhaseWithCable8?: number;
  EVWallBusinessWithCable8?: number;
  EVBase1SocketWithCable5?: number;
  EVBase2SocketWithCable5?: number;

  InfinityKit1Phase?: number;
  InfinityKit1PhaseSolar?: number;
  InfinityKit3Phase?: number;
  InfinityKit3PhaseSolar?: number;

  Extension3PhaseSolar?: number;

  LazyRemoteSurvey?: number;
  OnSiteSurvey?: number;
}

function getWallHomePrice(prices: PublicPrices, is3Phase: boolean, cable: CableType) {
  if (cable === CableType.NoCable) {
    return prices.EVWall3PhaseWithSocket;
  } else if (cable === CableType.Cable8m) {
    return prices.EVWall3PhaseWithCable8;
  } else {
    return undefined;
  }
}

function getWallBusinessPrice(prices: PublicPrices, cable: CableType) {
  if (cable === CableType.NoCable) {
    return prices.EVWallBusinessWithSocket;
  } else if (cable === CableType.Cable8m) {
    return prices.EVWallBusinessWithCable8;
  } else {
    return undefined;
  }
}

export function getEVWallPrice(data: ConfiguratorData, prices: PublicPrices, cable: CableType): number | undefined {
  const is3Phase = data.phaseType === PhaseType.ThreePhaseDelta;
  if (data.numberOfChargingPoints === 1) {
    const basePrice = getWallHomePrice(prices, is3Phase, cable);
    if (basePrice === undefined) {
      return undefined;
    }

    if (data.hasSolar && is3Phase && prices.Extension3PhaseSolar !== undefined) {
      return basePrice + prices.Extension3PhaseSolar;
    } else {
      return basePrice;
    }
  } else {
    const basePrice = getWallBusinessPrice(prices, cable);
    if (basePrice === undefined) {
      return undefined;
    }

    const infinityKitPrice = data.hasSolar
      ? is3Phase
        ? prices.InfinityKit3PhaseSolar
        : prices.InfinityKit1PhaseSolar
      : is3Phase
      ? prices.InfinityKit3Phase
      : prices.InfinityKit1Phase;
    if (infinityKitPrice === undefined) {
      return basePrice;
    }

    return basePrice + infinityKitPrice;
  }
}

function getBase1SocketPrice(prices: PublicPrices, cable: CableType) {
  if (cable === CableType.NoCable) {
    return prices.EVBase1SocketWithSocket;
  } else if (cable === CableType.Cable5m) {
    return prices.EVBase1SocketWithCable5;
  } else {
    return undefined;
  }
}

function getBase2SocketPrice(prices: PublicPrices, cable: CableType) {
  if (cable === CableType.NoCable) {
    return prices.EVBase2SocketWithSocket;
  } else if (cable === CableType.Cable5m) {
    return prices.EVBase2SocketWithCable5;
  } else {
    return undefined;
  }
}

export function getEVBasePrice(data: ConfiguratorData, prices: PublicPrices, cable: CableType): number | undefined {
  const basePrice =
    data.numberOfChargingPoints === 1 ? getBase1SocketPrice(prices, cable) : getBase2SocketPrice(prices, cable);
  if (basePrice === undefined) {
    return undefined;
  }

  const is3Phase = data.phaseType === PhaseType.ThreePhaseDelta;
  const infinityKitPrice = data.hasSolar
    ? is3Phase
      ? prices.InfinityKit3PhaseSolar
      : prices.InfinityKit1PhaseSolar
    : is3Phase
    ? prices.InfinityKit3Phase
    : prices.InfinityKit1Phase;
  if (infinityKitPrice === undefined) {
    return basePrice;
  }

  return basePrice + infinityKitPrice;
}
