import {Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import Checkbox from '../components/inputs/Checkbox';
import Form from '../components/inputs/Form';
import TextInput from '../components/inputs/TextInput';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';
import {validateEmail, validateRequired} from '../utils/Validation';

export function PersonInformationTab(props: {requiresEmployeeNumber: boolean}) {
  const {requiresEmployeeNumber} = props;
  const [
    {firstName, lastName, email, billingCompanyName, employeeNumber, agreesWithCallback = false, buildingType},
    readOnly
  ] = useAppSelector(state => [state.session.data, isReadOnly(state)]);

  const actor = useAppActor();
  const formState = useFormState();

  const handleClearData = () => {
    actor.clearSession();
    localStorage.removeItem('session-token');
  };

  return (
    <BasicTab
      tab={TabKey.Information}
      form={formState}
      extraAction={
        buildingType &&
        handleClearData /* only present 'clear data' if the user already entered data for the next step */
      }
      extraActionText={T('actions.clearData')}
    >
      <Form state={formState}>
        <Row>
          <TextInput
            name="firstName"
            value={firstName}
            onChange={value => actor.updateSession({firstName: value})}
            label={T('input.firstName')}
            md={6}
            validate={validateRequired}
            disabled={readOnly}
          />
          <TextInput
            name="lastName"
            value={lastName}
            onChange={value => actor.updateSession({lastName: value})}
            label={T('input.lastName')}
            md={6}
            validate={validateRequired}
            disabled={readOnly}
          />
        </Row>
        <Row>
          <TextInput
            name="email"
            value={email}
            onChange={value => actor.updateSession({email: value})}
            label={T('input.email')}
            md={6}
            validate={validateEmail}
            disabled={readOnly}
          />
          <TextInput
            name="company-name"
            value={billingCompanyName}
            onChange={value =>
              actor.updateSession({
                billingAsCompany: value.length > 0,
                billingCompanyName: value
              })
            }
            label={T('input.companyName')}
            md={6}
            disabled={readOnly}
          />
        </Row>
        {requiresEmployeeNumber && (
          <Row>
            <TextInput
              name="employeeNumber"
              value={employeeNumber || ''}
              onChange={value => actor.updateSession({employeeNumber: value})}
              label={T('input.employeeNumber')}
              md={6}
              validate={validateRequired}
              disabled={readOnly}
            />
          </Row>
        )}
        <Checkbox
          id="agree_with_data_retention"
          checked={agreesWithCallback}
          onChange={checked => actor.updateSession({agreesWithCallback: checked})}
          disabled={readOnly}
          label={
            <span>
              {T('general.information.agree')}&nbsp;
              <a href="https://www.smappee.com/privacy/">{T('general.information.privacyPolicy')}</a>
            </span>
          }
        />
        <p>{T('general.skipwizard.disclaimer')}</p>
      </Form>
    </BasicTab>
  );
}
