import BasicTab from '../components/BasicTab';
import {T} from '../core/Translate';
import {SurveyMethod} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppSelector} from '../redux';

export default function SurveyConfirmationTab() {
  const {surveyMethod = SurveyMethod.Remote} = useAppSelector(state => state.session.data);
  return (
    <BasicTab tab={TabKey.SurveyConfirmation}>
      <ol>
        {surveyMethod === SurveyMethod.Remote && (
          <>
            <li>{T('confirmation.completed.step.contactForRemoteSurvey')}</li>
            <li>{T('contirmation.completed.step.remoteSurvey')}</li>
          </>
        )}
        {surveyMethod === SurveyMethod.OnSite && (
          <>
            <li>{T('confirmation.completed.step.contactForOnSiteSurvey')}</li>
            <li>{T('confirmation.completed.step.onSiteSurvey')}</li>
          </>
        )}
      </ol>
    </BasicTab>
  );
}
