import React, {useCallback, useState} from 'react';
import {Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import Col from '../components/Col';
import Form from '../components/inputs/Form';
import TextInput from '../components/inputs/TextInput';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';
import {imageUrl} from '../utils/Images';

function MaximumCurrentImages() {
  return (
    <>
      <h3>{T('electrical.maximumCurrent.info.fusebox')}</h3>
      <Row>
        <Col sm={3}>
          <img src={imageUrl('/assets/images/meterbox.jpg')} />
        </Col>
        <Col sm={4}>
          <img src={imageUrl('/assets/images/maximum-current-2p.jpg')} />
        </Col>
        <Col sm={5}>
          <img src={imageUrl('/assets/images/maximum-current-4p.jpg')} />
        </Col>
      </Row>
      <h3 style={{marginTop: '0.5rem'}}>{T('electrical.maximumCurrent.info.inspectionReport')}</h3>
      <img src={imageUrl('/assets/images/inspection-report.jpg')} />
    </>
  );
}

export default function MaximumCurrentTab() {
  const [{maximumCurrent}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  const [value, setValue] = useState(maximumCurrent === undefined ? '' : maximumCurrent.toString());
  const handleChange = (value: string) => {
    setValue(value);

    const numberValue = parseInt(value);
    actor.updateSession({maximumCurrent: isNaN(numberValue) ? undefined : numberValue});
  };

  const handleClickedDontKnow = useCallback(() => {
    actor.updateSession({maximumCurrent: undefined});
  }, [actor]);

  const formState = useFormState();
  return (
    <BasicTab
      tab={TabKey.MaximumCurrent}
      form={formState}
      onClickedDontKnow={handleClickedDontKnow}
      extraInfo={MaximumCurrentImages}
    >
      <Form state={formState}>
        <TextInput
          name="max-current"
          value={value}
          onChange={handleChange}
          suffix="A"
          label={T('electrical.maximumCurrent.current')}
          sm={4}
          md={3}
          disabled={readOnly}
        />
      </Form>
    </BasicTab>
  );
}
