import {CSSProperties} from 'react';

export class Column<T> {
  name: string;
  label: string;
  projection: (_value: T) => string;
  sort: (_a: T, _b: T) => number;
  style?: CSSProperties;

  constructor(
    name: string,
    label: string,
    projection: (value: T) => string,
    options: {
      sort?: () => number;
      style?: CSSProperties;
    } = {}
  ) {
    this.name = name;
    this.label = label;
    this.projection = projection;
    this.sort =
      options.sort ||
      function (a: T, b: T) {
        return projection(a).localeCompare(projection(b));
      };
    this.style = options.style;
  }
}
