import React from 'react';
import {Form as ReactForm} from 'react-bootstrap';

import {FormContextProvider} from '../../utils/FormContext';
import {FormState} from '../../utils/FormState';

import styles from './index.module.scss';

interface FormProps {
  state: FormState;
  children: React.ReactNode;
}

export default function Form(props: FormProps) {
  const {state, children} = props;
  return (
    <FormContextProvider value={state}>
      <ReactForm className={styles.form}>{children as any}</ReactForm>
    </FormContextProvider>
  );
}
