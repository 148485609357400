import {Alert} from 'react-bootstrap';

import {T} from '../core/Translate';

import styles from './SaveError.module.scss';

export default function SaveError() {
  return (
    <div className={styles.container}>
      <Alert variant="warning">{T('connectionLost')}</Alert>
    </div>
  );
}
