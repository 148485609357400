import {useMemo} from 'react';
import {Form} from 'react-bootstrap';

import {generateRandomId} from '../utils/RandomId';
import {classes} from '../utils/Styles';

import styles from './RadioGroup.module.scss';

export interface RadioGroupOption {
  value: string;
  label: string | JSX.Element;
  sublabel?: string;
  icon?: string;
  tooltip?: string;
}

interface RadioGroupProps {
  label?: string;
  value: string;
  options: RadioGroupOption[];
  setValue: (value: string) => void;
  disabled?: boolean;
}

export function RadioGroup(props: RadioGroupProps) {
  const {label, value, options, setValue, disabled} = props;
  const id = useMemo(() => generateRandomId(), []);

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      setValue(e.currentTarget.value);
    }
  };

  return (
    <div className={styles.group}>
      {label && <Form.Label>{label}</Form.Label>}
      {/*options.map(option => (
      <Form.Check
        id={`radio-${id}-${option.value}`}
        key={option.value}
        value={option.value}
        label={option.sublabel ? option.label + ' (' + option.sublabel + ')' : option.label}
        checked={value === option.value}
        type='radio'
        onChange={handleChecked}
        className={styles.radio}
      />
    ))*/}
      {options.map(option => (
        <CustomRadioButton
          id={`radio-${id}-${option.value}`}
          key={option.value}
          value={option.value}
          label={option.sublabel ? `${option.label} (${option.sublabel})` : option.label}
          tooltip={option.tooltip}
          checked={value === option.value}
          onChange={handleChecked}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

interface CustomRadioButtonProps {
  id: string;
  value: string;
  label: string | JSX.Element;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  tooltip?: string;
}

export function CustomRadioButton(props: CustomRadioButtonProps) {
  const {id, value, label, checked, onChange, disabled, tooltip} = props;

  return (
    <div className={classes('form-check', styles.radio)} title={tooltip}>
      <input
        id={id}
        className="form-check-input"
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <div className={styles.checkbox}>
        <div className={styles.checkboxInner} />
      </div>
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
