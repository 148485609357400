import React, {useCallback} from 'react';

import {Button, Form} from 'react-bootstrap';

import {T} from '../core/Translate';
import {classes} from '../utils/Styles';

import {Flex} from './Flex';
import styles from './Pagination.module.scss';

interface PaginationProps {
  className?: string;
  offset: number;
  limit: number;
  total: number;
  onPaginate: (offset: number, limit: number) => void;
  firstLastNav?: boolean;
}

export default function Pagination(props: PaginationProps) {
  const {className, offset, limit, total, onPaginate, firstLastNav = false} = props;

  const changeOffset = useCallback(
    (offset: number) => {
      onPaginate(offset, limit);
    },
    [onPaginate, limit]
  );

  const firstPage = useCallback(() => changeOffset(0), [changeOffset]);

  const prevPage = useCallback(() => {
    changeOffset(offset - limit);
  }, [changeOffset, offset, limit]);

  const nextPage = useCallback(() => {
    changeOffset(offset + limit);
  }, [changeOffset, offset, limit]);

  const lastPage = useCallback(() => {
    const remainder = total % limit;
    const diff = remainder !== 0 ? remainder : limit;
    changeOffset(total - diff);
  }, [changeOffset, limit, total]);

  const handleItemsPerPageChanged = useCallback(
    (limit: number) => {
      const newOffset = ((offset / limit) | 0) * limit;
      onPaginate(newOffset, limit);
    },
    [onPaginate, offset]
  );

  // Format numbers
  const page = Math.ceil(offset / limit);
  const from = total > 0 ? page + 1 : 0;
  const to = Math.ceil(total / limit);

  return (
    <Flex className={classes(styles.pagination, className)}>
      <span className={styles.itemsPerPage}>{T('paginator.itemsPerPage')}</span>

      <Form.Control
        as="select"
        style={{
          width: '4em',
          paddingLeft: 4,
          paddingRight: 4,
          minWidth: '3em',
          marginBottom: 0
        }}
        value={limit}
        onChange={e => handleItemsPerPageChanged(parseInt(e.currentTarget.value))}
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </Form.Control>

      <div style={{flexGrow: 1}} />

      <Button color="secondary" onClick={firstPage} disabled={offset <= 0} title={T('paginator.tooltip.firstPage')}>
        <span className="fal fa-angle-double-left" />
      </Button>

      <Button color="secondary" onClick={prevPage} disabled={offset <= 0} title={T('paginator.tooltip.previousPage')}>
        <span className="fal fa-angle-left" />
      </Button>

      <span className={styles.page}>
        {from}/{to}
      </span>

      <Button
        color="secondary"
        onClick={nextPage}
        disabled={offset + limit >= total}
        title={T('paginator.tooltip.nextPage')}
      >
        <span className="fal fa-angle-right" />
      </Button>

      <Button
        color="secondary"
        onClick={lastPage}
        disabled={offset + limit >= total}
        title={T('paginator.tooltip.lastPage')}
      >
        <span className="fal fa-angle-double-right" />
      </Button>
    </Flex>
  );
}
