import React, {useContext} from 'react';

import {DummyFormState, FormState, useFormState} from './FormState';

export const FormContext = React.createContext<FormState | undefined>(undefined);
export const FormContextProvider = FormContext.Provider;
export const FormContextConsumer = FormContext.Consumer;

export function useFormContext() {
  const value = useContext<FormState | undefined>(FormContext);
  if (value === undefined) {
    console.error('Attempting to use form context outside form!');
  }
  return value || DummyFormState;
}

export const FormProvider = (props: {children: React.ReactNode}) => {
  const {children} = props;
  const form = useFormState();
  return <FormContextProvider value={form}>{children}</FormContextProvider>;
};
