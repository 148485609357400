import React, {useCallback, useEffect} from 'react';
import {Form, InputGroup} from 'react-bootstrap';

import {useFormContext} from '../../utils/FormContext';
import {Hotkeys} from '../../utils/Keys';
import Col from '../Col';

import styles from './index.module.scss';

interface TextInputProps {
  name: string;
  value: string;
  label: string;
  sm?: number;
  md?: number;
  suffix?: string;
  validate?: (value: string, label: string) => string | undefined;
  onChange: (value: string) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  onBlur?: () => void;
  isValid?: boolean;
  disabled?: boolean;
}

export default function TextInput(props: TextInputProps) {
  const {name, value, label, sm, md, suffix, validate, onChange, onKeyPress, onEnter, onBlur, isValid, disabled} =
    props;
  const formState = useFormContext();

  const error = validate && validate(value, label);
  formState.setError(name, error);

  const handleKeyPress = onEnter
    ? (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === Hotkeys.ENTER) {
          if (formState.hasErrors()) {
            formState.setErrorVisible(name, error !== undefined);
            return;
          }
          onEnter();
        } else {
          onKeyPress && onKeyPress(e);
        }
      }
    : onKeyPress;

  const handleBlur = useCallback(() => {
    formState.setErrorVisible(name, error !== undefined);
    onBlur && onBlur();
  }, [formState, name, onBlur, error]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => formState.remove(name), [/*formState, */ name]);

  const shownError = formState.getShownError(name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  const control = (
    <Form.Control
      placeholder={label}
      value={value}
      onChange={handleChange}
      className={styles.input}
      isInvalid={shownError !== undefined}
      onKeyPress={handleKeyPress}
      onBlur={handleBlur}
      isValid={isValid}
      disabled={disabled}
    />
  );

  return (
    <Col sm={sm} md={md} className={styles.inputGroup}>
      <Form.Group>
        {suffix ? (
          <InputGroup>
            {control}
            <div className={styles.suffix}>{suffix}</div>
          </InputGroup>
        ) : (
          control
        )}
        {shownError && <Form.Control.Feedback type="invalid">{shownError}</Form.Control.Feedback>}
      </Form.Group>
    </Col>
  );
}
