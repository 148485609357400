import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, Store} from 'redux';

import {ConfiguratorData} from '../models/ConfiguratorData';

import {Photo} from '../models/Photo';
import {SessionStatus, StoredSessionData} from '../models/SessionData';
import {TabKey} from '../models/TabKeys';
import {areActionCodesSupported} from '../utils/Features';

import {AnyAppAction, AppActionType} from './Actions';
import {IAppState} from './AppState';

export type AppStore = Store<IAppState, AnyAppAction>;

export function useAppSelector<T>(selector: (state: IAppState) => T) {
  return useSelector<IAppState, T>(selector);
}

export function useAppDispatch() {
  return useDispatch<Dispatch<AnyAppAction>>();
}

class AppActor {
  private dispatch: Dispatch<AnyAppAction>;

  constructor(dispatch: Dispatch<AnyAppAction>) {
    this.dispatch = dispatch;
  }

  setSessionToken(token: string) {
    this.dispatch({type: AppActionType.SetSessionToken, payload: {token}});
  }

  setStep(step: TabKey) {
    this.dispatch({type: AppActionType.SetStep, payload: step});
  }

  clearSession() {
    this.dispatch({type: AppActionType.ClearSession, payload: {}});
  }

  updateSession(updates: Partial<ConfiguratorData>) {
    this.dispatch({type: AppActionType.UpdateSession, payload: updates});
  }

  uploadedPhoto(photos: Photo[]) {
    this.dispatch({type: AppActionType.AddPhotos, payload: photos});
  }

  deletePhoto(id: number) {
    this.dispatch({type: AppActionType.DeletePhoto, payload: id});
  }

  loadSession(session: StoredSessionData) {
    if (!areActionCodesSupported()) {
      session.data.actionCode = undefined;
    }
    this.dispatch({type: AppActionType.LoadSession, payload: session});
  }

  markReady() {
    this.dispatch({type: AppActionType.Ready, payload: {}});
  }

  markLoadError() {
    this.dispatch({type: AppActionType.MarkLoadError, payload: {}});
  }

  setSessionStatus(status: SessionStatus) {
    this.dispatch({type: AppActionType.SetSessionStatus, payload: status});
  }

  markSaveError() {
    this.dispatch({type: AppActionType.MarkSaveError, payload: {}});
  }

  clearSaveError() {
    this.dispatch({type: AppActionType.ClearSaveError, payload: {}});
  }
}

export function useAppActor() {
  const dispatch = useAppDispatch();
  return useMemo(() => new AppActor(dispatch), [dispatch]);
}
