import React, {CSSProperties} from 'react';

export interface IFlexProps {
  justifyContent?: string;
  alignItems?: string;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

export function Flex(props: IFlexProps) {
  let {justifyContent = 'between', alignItems = 'center', className, children, ...otherProps} = props;

  // Hook extra classes into the component
  className = [
    'd-flex',
    `justify-content-${justifyContent}`,
    `align-items-${alignItems}`,
    className ? className : ''
  ].join(' ');

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  );
}
