import React from 'react';
import {Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import Form from '../components/inputs/Form';
import TextareaInput from '../components/inputs/TextareaInput';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {useFormState} from '../utils/FormState';

export default function ContactSalesTab() {
  const {explanation} = useAppSelector(state => state.session.data);
  const actor = useAppActor();

  const handleSubmit = () => {
    // TODO
  };

  const formState = useFormState();
  return (
    <BasicTab
      tab={TabKey.ContactSales}
      form={formState}
      extraAction={handleSubmit}
      extraActionText={T('confirmation.contactSalesTab.confirm')}
    >
      <Form state={formState}>
        <Row>
          <TextareaInput
            name="explanation"
            label={T('confirmation.contactSalesTab.explanation')}
            value={explanation}
            onChange={value => actor.updateSession({explanation: value})}
            sm={12}
          />
        </Row>
      </Form>
    </BasicTab>
  );
}
