import React from 'react';
import {Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import Col from '../components/Col';
import PhotoUploader, {PhotoUploaderGroup} from '../components/PhotoUploader';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {Photo} from '../models/Photo';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {imageUrl} from '../utils/Images';

function TechnicalPhotosImages() {
  return (
    <Row>
      <Col md={4}>
        <img src={imageUrl('/assets/images/technical-room.jpg')} />
        <p style={{textAlign: 'center'}}>{T('electrical.technicalPhotos.overview')}</p>
      </Col>
      <Col md={4}>
        <img src={imageUrl('/assets/images/fusebox-detail.jpg')} />
        <p style={{textAlign: 'center'}}>{T('electrical.technicalPhotos.fusebox')}</p>
      </Col>
      <Col md={4}>
        <img src={imageUrl('/assets/images/main-switch.jpg')} />
        <p style={{textAlign: 'center'}}>{T('electrical.technicalPhotos.smartMeterDetail')}</p>
      </Col>
    </Row>
  );
}

export default function TechnicalPhotosTab() {
  const [token, photos, readOnly] = useAppSelector(state => [
    state.session.token,
    state.session.photos,
    isReadOnly(state)
  ]);
  const actor = useAppActor();

  const handleUpload = (photos: Photo[]) => {
    actor.uploadedPhoto(photos);
  };

  const handleDelete = (id: number) => {
    api.deletePhoto(token, id).then(() => {
      actor.deletePhoto(id);
    });
  };

  return (
    <BasicTab tab={TabKey.TechnicalPhotos} extraInfo={TechnicalPhotosImages}>
      <PhotoUploaderGroup>
        <PhotoUploader
          tag="technical-panel"
          label={T('electrical.technicalPhotos.overview')}
          photos={photos}
          onUploaded={handleUpload}
          onDelete={handleDelete}
          disabled={readOnly}
        />
        <PhotoUploader
          tag="fusebox"
          label={T('electrical.technicalPhotos.fusebox')}
          photos={photos}
          onUploaded={handleUpload}
          onDelete={handleDelete}
          disabled={readOnly}
        />
        <PhotoUploader
          tag="meter-detail"
          label={T('electrical.technicalPhotos.smartMeterDetail')}
          photos={photos}
          onUploaded={handleUpload}
          onDelete={handleDelete}
          disabled={readOnly}
        />
      </PhotoUploaderGroup>
    </BasicTab>
  );
}
