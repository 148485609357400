import React, {useEffect} from 'react';
import {Button, Container} from 'react-bootstrap';

import {api} from '../core/api';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

import styles from './AppLoadingIndicator.module.scss';

interface AppLoadingIndicatorProps {
  goToTab?: TabKey;
}

export default function AppLoadingIndicator(props: AppLoadingIndicatorProps) {
  const {goToTab} = props;

  const loadError = useAppSelector(state => state.loadError);
  const actor = useAppActor();

  useEffect(() => {
    const sessionToken = localStorage.getItem('session-token');
    if (sessionToken) {
      actor.setSessionToken(sessionToken);
      api
        .loadSession(sessionToken)
        .then(session => {
          if (session) {
            if (goToTab) {
              session.step = goToTab;
            }

            actor.loadSession(session);
          }
        })
        .catch(() => actor.markLoadError());
    } else {
      actor.markReady();
    }
  }, [actor]);

  const handleReload = () => {
    const sessionToken = localStorage.getItem('session-token');
    if (sessionToken) {
      api
        .loadSession(sessionToken)
        .then(session => session && actor.loadSession(session))
        .catch(() => actor.markLoadError());
    } else {
      actor.markReady();
    }
  };

  return (
    <Container>
      <div className={styles.container}>
        {!loadError && (
          <p>
            <span className="fas fa-spin fa-circle-notch" />
            <br />
            {T('generic.loading')}
          </p>
        )}
        {loadError && (
          <>
            <p>{T('generic.loadFailed')}</p>
            <Button onClick={handleReload} variant="primary">
              {T('actions.reload')}
            </Button>
            <br />
            <Button onClick={() => actor.clearSession()} variant="link">
              {T('actions.clearData')}
            </Button>
          </>
        )}
      </div>
    </Container>
  );
}
