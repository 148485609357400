import {useMemo} from 'react';

import BasicTab from '../components/BasicTab';
import {RadioGroup, RadioGroupOption} from '../components/RadioGroup';
import {T} from '../core/Translate';
import {ChargingSpeed, PhaseType} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';

export default function ChargingSpeedTab() {
  const [{chargingSpeed, phaseType, maximumCurrent = 25}, readOnly] = useAppSelector(state => [
    state.session.data,
    isReadOnly(state)
  ]);
  const actor = useAppActor();

  const options: RadioGroupOption[] = useMemo(
    () => [
      {value: ChargingSpeed.Slow, label: T('general.chargingspeed.fine')},
      {value: ChargingSpeed.Fast, label: T('general.chargingspeed.faster')}
    ],
    []
  );

  const phases = phaseType === PhaseType.ThreePhaseStar ? 3 : 1;
  const chargingSpeedKw = maximumCurrent * phases * 0.23;
  const chargingSpeedKph = 5 * chargingSpeedKw;

  return (
    <BasicTab tab={TabKey.ChargingSpeed} textParams={{chargingSpeed: chargingSpeedKph.toFixed(0)}}>
      <RadioGroup
        value={chargingSpeed || ''}
        options={options}
        setValue={value => actor.updateSession({chargingSpeed: value as ChargingSpeed})}
        disabled={readOnly}
      />
    </BasicTab>
  );
}
