import {useCallback, useEffect, useMemo, useState} from 'react';

import wallCableImage from '../assets/images/ev-wall-cable-white.jpg';
import wallSocketImage from '../assets/images/ev-wall-socket.jpg';
import BasicTab from '../components/BasicTab';
import ChoiceGroup from '../components/ChoiceGroup';
import {RadioGroupOption} from '../components/RadioGroup';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {CableType, MountingMethod} from '../models/ConfiguratorData';
import {getEVWallPrice, getEVBasePrice, PublicPrices} from '../models/PublicPrices';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

import {isReadOnly} from '../redux/AppState';
import {formatCurrencyWhole} from '../utils/Formatting';

export default function CableTab() {
  const [data, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const {cable, numberOfChargingPoints, mountingMethod} = data;

  const actor = useAppActor();
  const [prices, setPrices] = useState<PublicPrices>({});
  useEffect(() => {
    api.getPrices().then(setPrices);
  }, []);

  const handleChange = useCallback((type: string) => actor.updateSession({cable: type as CableType}), [actor]);

  const options: RadioGroupOption[] = useMemo(() => {
    const chargingPoints = numberOfChargingPoints === 2 ? 2 : 1;

    const result: RadioGroupOption[] = [
      {
        value: CableType.NoCable,
        label: T('charger.cable.socket'),
        icon: wallSocketImage
      }
    ];

    if (mountingMethod === MountingMethod.Wall) {
      const wallPriceSocket = getEVWallPrice(data, prices, CableType.NoCable);
      const wallPrice8m = getEVWallPrice(data, prices, CableType.Cable8m);
      const price =
        wallPriceSocket === undefined || wallPrice8m === undefined
          ? undefined
          : (wallPrice8m - wallPriceSocket) * chargingPoints;

      result.push({
        value: CableType.Cable8m,
        label: T('charger.cable.8m'),
        sublabel: price === undefined ? undefined : T('generic.surcharge', {price: formatCurrencyWhole(price)}),
        icon: wallCableImage
      });
    }

    return result;
  }, [numberOfChargingPoints, mountingMethod, prices, data]);

  return (
    <BasicTab tab={TabKey.Cable}>
      <ChoiceGroup
        as="buttons"
        options={options}
        value={cable}
        onChange={handleChange}
        width={250}
        height={250}
        disabled={readOnly}
        noBorder={true}
      />
      {cable !== CableType.NoCable && <p>{T('charger.cable.canBeCut')}</p>}
      <p>
        <i className="fas fa-info-circle" />
        &nbsp;
        {T('model.offering.disclaimer')}
      </p>
    </BasicTab>
  );
}
