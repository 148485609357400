import React, {useCallback} from 'react';

import {Row} from 'react-bootstrap';

import monoPhaseImage from '../assets/images/sticker-1F.png';
import deltaImage from '../assets/images/sticker-delta.png';
import starImage from '../assets/images/sticker-star.png';
import BasicTab from '../components/BasicTab';
import Col from '../components/Col';
import DontKnowWarning from '../components/DontKnowWarning';
import {Gallery} from '../components/Gallery';
import GallerySelectButton from '../components/GallerySelectButton';
import {T} from '../core/Translate';
import {PhaseType} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

import {isReadOnly} from '../redux/AppState';
import {imageUrl} from '../utils/Images';

function PhasesImages() {
  return (
    <>
      <h3>{T('electrical.phases.info.fusebox')}</h3>
      <Row>
        <Col md={4}>
          <img src={imageUrl('/assets/images/meterbox.jpg')} />
        </Col>
        <Col md={4}>
          <img src={imageUrl('/assets/images/installation-fuse-2p.jpg')} />
          <p style={{textAlign: 'center'}}>Max 35 mm</p>
        </Col>
        <Col md={4}>
          <img src={imageUrl('/assets/images/installation-fuse-4p.jpg')} />
          <p style={{textAlign: 'center'}}>Max 70mm</p>
          <Row>
            <Col xs={6}>
              <p style={{textAlign: 'center'}}>
                <img src={imageUrl('/assets/images/installation-star.jpg')} />
                <p>{T('electrical.phases.star.label')}</p>
              </p>
            </Col>
            <Col xs={6}>
              <p style={{textAlign: 'center'}}>
                <img src={imageUrl('/assets/images/installation-delta.jpg')} />
                <p>{T('electrical.phases.delta.label')}</p>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <h3 style={{marginTop: '0.5rem'}}>{T('electrical.phases.info.differential')}</h3>
      <Row>
        <Col md={3}>
          <img src={imageUrl('/assets/images/installation-diff-2p.jpg')} />
          <p style={{textAlign: 'center'}}>Max 35 mm</p>
        </Col>
        <Col md={6}>
          <img src={imageUrl('/assets/images/installation-diff-4p.jpg')} />
          <p style={{textAlign: 'center'}}>Max 70mm</p>
        </Col>
      </Row>
    </>
  );
}

export default function PhasesTab() {
  const [phases, readOnly] = useAppSelector(state => [state.session.data.phaseType, isReadOnly(state)]);
  const actor = useAppActor();

  const handleChangePhases = useCallback(
    (phases: PhaseType) => {
      actor.updateSession({phaseType: phases});
    },
    [actor]
  );

  const handleClickedDontKnow = useCallback(() => {
    actor.updateSession({phaseType: undefined});
  }, [actor]);

  return (
    <BasicTab tab={TabKey.Phases} onClickedDontKnow={handleClickedDontKnow} extraInfo={PhasesImages}>
      <Gallery>
        <GallerySelectButton
          src={monoPhaseImage}
          width={96}
          height={96}
          selected={phases === PhaseType.SinglePhase}
          onClick={() => handleChangePhases(PhaseType.SinglePhase)}
          disabled={readOnly}
        >
          <p>
            {T('electrical.phases.single.label.a')}
            <br />
            {T('electrical.phases.single.label.b')}
            <br />
            {T('electrical.phases.single.label.c')}
          </p>
          <p>{T('electrical.phases.single.label')}</p>
        </GallerySelectButton>
        <GallerySelectButton
          src={starImage}
          width={96}
          height={96}
          selected={phases === PhaseType.ThreePhaseStar}
          onClick={() => handleChangePhases(PhaseType.ThreePhaseStar)}
          disabled={readOnly}
        >
          <p>
            {T('electrical.phases.star.label.a')}
            <br />
            {T('electrical.phases.star.label.b')}
            <br />
            {T('electrical.phases.star.label.c')}
          </p>
          <p>{T('electrical.phases.star.label')}</p>
        </GallerySelectButton>
        <GallerySelectButton
          src={deltaImage}
          width={96}
          height={96}
          selected={phases === PhaseType.ThreePhaseDelta}
          onClick={() => handleChangePhases(PhaseType.ThreePhaseDelta)}
          disabled={readOnly}
        >
          <p>
            {T('electrical.phases.delta.label.a')}
            <br />
            {T('electrical.phases.delta.label.b')}
            <br />
            {T('electrical.phases.delta.label.c')}
          </p>
          <p>{T('electrical.phases.delta.label')}</p>
        </GallerySelectButton>
      </Gallery>
      {phases === undefined && <DontKnowWarning />}
    </BasicTab>
  );
}
