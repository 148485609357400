import BasicTab from '../components/BasicTab';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';

export default function CompletedTab() {
  return (
    <BasicTab tab={TabKey.Completed}>
      <ol>
        <li>{T('confirmation.completed.step.contactForInstallation')}</li>
        <li>{T('confirmation.completed.step.support')}</li>
        {}
      </ol>
    </BasicTab>
  );
}
