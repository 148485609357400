import React from 'react';

import BasicTab from '../components/BasicTab';
import Form from '../components/inputs/Form';
import TextareaInput from '../components/inputs/TextareaInput';
import PhotoUploader from '../components/PhotoUploader';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {Photo} from '../models/Photo';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';

export default function SituationTab() {
  const [{chargerLocationDescription = ''}, photos, token, readOnly] = useAppSelector(state => [
    state.session.data,
    state.session.photos,
    state.session.token,
    isReadOnly(state)
  ]);

  const actor = useAppActor();
  const formState = useFormState();

  const handleUpload = (photos: Photo[]) => {
    actor.uploadedPhoto(photos);
  };

  const handleDelete = (id: number) => {
    api.deletePhoto(token, id).then(() => {
      actor.deletePhoto(id);
    });
  };

  return (
    <BasicTab tab={TabKey.Situation} form={formState}>
      <PhotoUploader
        label={T('charger.situation.photo')}
        photos={photos}
        tag="charging-point"
        onUploaded={handleUpload}
        onDelete={handleDelete}
        disabled={readOnly}
      />
      <p>{T('charger.situation.describe')}</p>
      <Form state={formState}>
        <TextareaInput
          name="explanation"
          label={T('charger.situation.describe.placeholder')}
          value={chargerLocationDescription}
          onChange={value => actor.updateSession({chargerLocationDescription: value})}
          disabled={readOnly}
        />
      </Form>
    </BasicTab>
  );
}
