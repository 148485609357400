import React from 'react';
import {Container} from 'react-bootstrap';

import {T} from '../core/Translate';

export default function ReadOnlyNotice() {
  return (
    <Container>
      <div className="alert alert-warning">{T('completed.notice')}</div>
    </Container>
  );
}
