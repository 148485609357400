import React from 'react';
import {Form} from 'react-bootstrap';

import Col from '../Col';

import styles from './index.module.scss';

interface SelectInputProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  children: React.ReactNode;
  sm?: number;
  md?: number;
  disabled?: boolean;
}

export default function SelectInput(props: SelectInputProps) {
  const {value, onChange, label, children, sm, md, disabled} = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return (
    <Col sm={sm} md={md} className={styles.inputGroup}>
      <Form.Group>
        <Form.Control
          placeholder={label}
          as="select"
          value={value}
          onChange={handleChange}
          className={styles.input}
          disabled={disabled}
        >
          {children as any}
        </Form.Control>
      </Form.Group>
    </Col>
  );
}
