export interface PhotoId {
  id: number;
  tag: string;
}

export interface Photo extends PhotoId {
  url: string;
  mimetype: string;
  preview?: string;
}

export function getPreviewUrl(photo: Photo) {
  return photo.preview && `data:${photo.mimetype};base64,${photo.preview}`;
}
