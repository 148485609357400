import React, {useCallback, useState} from 'react';

import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {getMainTabTitle, getNextStep, getPreviousStep, getTabInfo} from '../models/Tabs';

import {useAppActor, useAppSelector} from '../redux';

import {isReadOnly} from '../redux/AppState';
import {FormState} from '../utils/FormState';

import Button, {ButtonRow} from './Button';
import ExplanationModal from './ExplanationModal';
import MarkdownView from './MarkdownView';
import TabContainer from './TabContainer';

interface BasicTabProps {
  tab: TabKey;
  form?: FormState;
  text?: string;
  textParams?: {[key: string]: string};
  extraInfo?: () => React.ReactNode;
  children?: React.ReactNode;
  extraAction?: () => void;
  extraActionUrl?: string;
  extraActionText?: string;
  onClickedDontKnow?: () => void;
  nextDisabled?: boolean;
}

function scrollToTop() {
  const target = window.document.getElementById('onboardingwizard-top');
  if (target) {
    const rect = target.getBoundingClientRect();
    if (rect.top < 0) {
      document.body.scrollIntoView();
    }
  }
}

export default function BasicTab(props: BasicTabProps) {
  const {
    text,
    textParams,
    form,
    extraInfo,
    extraAction,
    extraActionUrl,
    extraActionText,
    onClickedDontKnow,
    nextDisabled
  } = props;

  const tabInfo = getTabInfo(props.tab);
  const [data, photos, readOnly, status] = useAppSelector(state => [
    state.session.data,
    state.session.photos,
    isReadOnly(state),
    state.session.status
  ]);
  const actor = useAppActor();
  const next = getNextStep(props.tab, data, photos, status);
  const previous = getPreviousStep(props.tab, data, photos, status);
  const completed = tabInfo.isCompleted === undefined || tabInfo.isCompleted(data, photos);
  const unsureAvailable = tabInfo.isUnsureAvailable !== undefined && tabInfo.isUnsureAvailable(data);

  const handleClickedPrevious = useCallback(() => {
    if (!previous) {
      return;
    }

    actor.setStep(previous);
    scrollToTop();
  }, [actor, previous]);

  const handleClickedNext = useCallback(() => {
    if (next && readOnly) {
      actor.setStep(next);
      scrollToTop();
    }
    if (!next || !completed) {
      return;
    }
    if (form && form.hasErrors()) {
      form.showErrors();
      return;
    }

    actor.setStep(next);
    scrollToTop();
  }, [actor, next, completed, form]);

  const handleClickedDontKnow = useCallback(() => {
    if (!next) {
      return;
    }
    if (form && form.hasErrors()) {
      form.showErrors();
      return;
    }
    if (onClickedDontKnow) {
      onClickedDontKnow();
    }

    actor.setStep(next);
    scrollToTop();
  }, [onClickedDontKnow, next, actor, form]);

  const title = T(tabInfo.title, textParams);

  return (
    <TabContainer>
      <h2 className="mobile-only">{getMainTabTitle(tabInfo.mainTab)}</h2>
      <h1 className="clearfix">
        {title}{' '}
        {tabInfo.info && <InfoButton title={title} content={T(tabInfo.info, textParams)} extraInfo={extraInfo} />}
      </h1>
      <MarkdownView content={text || T(tabInfo.text, textParams)} />
      {props.children}
      <ButtonRow>
        {previous && (
          <Button variant="link" onClick={handleClickedPrevious}>
            <i className="far fa-arrow-left" />
            &nbsp;
            {T('actions.previous')}
          </Button>
        )}
        <div style={{flexGrow: 1}} />
        {extraAction && (
          <Button variant="secondary" onClick={extraAction}>
            {extraActionText}
          </Button>
        )}
        {extraActionUrl && (
          <Button href={extraActionUrl} variant="secondary" target="_blank">
            {extraActionText}
          </Button>
        )}
        {onClickedDontKnow && (
          <Button
            variant="secondary"
            disabled={!(completed || unsureAvailable) || readOnly}
            onClick={handleClickedDontKnow}
          >
            {T('generic.dontknow')}
          </Button>
        )}
        {next && (
          <Button variant="primary" disabled={(!completed && !readOnly) || nextDisabled} onClick={handleClickedNext}>
            {T('actions.next')}
            &nbsp;
            <i className="far fa-arrow-right" />
          </Button>
        )}
      </ButtonRow>
    </TabContainer>
  );
}

interface InfoButtonProps {
  title: string;
  content: string;
  extraInfo?: () => React.ReactNode;
}

function InfoButton(props: InfoButtonProps) {
  const {title, content, extraInfo} = props;

  const [shown, setShown] = useState(false);

  const handleOpen = () => setShown(true);
  const handleClose = () => setShown(false);

  return (
    <>
      <Button variant="link" onClick={handleOpen} style={{float: 'right'}} title={T('generic.moreInfo')}>
        <i className="fas fa-info-circle" />
      </Button>
      <ExplanationModal title={title} explanation={content} show={shown} extraInfo={extraInfo} onClose={handleClose} />
    </>
  );
}
