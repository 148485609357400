import React, {useMemo} from 'react';

import BasicTab from '../components/BasicTab';
import Checkbox from '../components/inputs/Checkbox';
import Form from '../components/inputs/Form';
import {RadioGroup, RadioGroupOption} from '../components/RadioGroup';
import {T} from '../core/Translate';
import {DiggingSituation} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';

export default function HolesAndDiggingTab() {
  const [{diggingSituation, thickWalls = false}, readOnly] = useAppSelector(state => [
    state.session.data,
    isReadOnly(state)
  ]);
  const actor = useAppActor();
  const formState = useFormState();

  const options: RadioGroupOption[] = useMemo(
    () => [
      //    { value: DiggingSituation.NoDiggingNoHoles, label: T('charger.holesAndDigging.none') },
      //    { value: DiggingSituation.HolesOnly, label: T('charger.holesAndDigging.holesOnly') },
      {value: DiggingSituation.Simple, label: T('charger.holesAndDigging.simple')},
      {value: DiggingSituation.DiggingNecessary, label: T('charger.holesAndDigging.digging')}
      //    { value: DiggingSituation.Complex, label: T('charger.holesAndDigging.complex') }
    ],
    []
  );

  return (
    <BasicTab
      tab={TabKey.HolesAndDigging}
      form={formState}
      onClickedDontKnow={() => actor.updateSession({diggingSituation: DiggingSituation.DontKnow})}
    >
      <Form state={formState}>
        <RadioGroup
          value={diggingSituation || ''}
          options={options}
          setValue={value => actor.updateSession({diggingSituation: value as DiggingSituation})}
          disabled={readOnly}
        />
        {diggingSituation === DiggingSituation.Simple && (
          <Checkbox
            id="thick-walls"
            checked={thickWalls}
            label={T('charger.holesAndDigging.thickWall')}
            onChange={checked => actor.updateSession({thickWalls: checked})}
            disabled={readOnly}
          />
        )}
      </Form>
    </BasicTab>
  );
}
