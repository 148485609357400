import {Container} from 'react-bootstrap';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}
export default function Layout(props: LayoutProps) {
  const {children} = props;

  return (
    <Container fluid="xl">
      <>
        {/*<div className={styles.background} />*/}
        {children}
      </>
    </Container>
  );
}
