import {useCallback, useEffect, useMemo, useState} from 'react';

import BasicTab from '../components/BasicTab';
import ChoiceGroup from '../components/ChoiceGroup';
import {RadioGroupOption} from '../components/RadioGroup';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {SurveyMethod} from '../models/ConfiguratorData';
import {PublicPrices} from '../models/PublicPrices';
import {TabKey} from '../models/TabKeys';

import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {formatCurrencyWhole} from '../utils/Formatting';

export default function SurveyTab() {
  const [{surveyMethod}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  const [prices, setPrices] = useState<PublicPrices>({});
  useEffect(() => {
    api.getPrices().then(setPrices);
  }, []);

  const options: RadioGroupOption[] = useMemo(
    () => [
      {value: SurveyMethod.Remote, label: T('confirmation.survey.remote')},
      {
        value: SurveyMethod.OnSite,
        label: T('confirmation.survey.onsite', {
          price: prices.OnSiteSurvey ? formatCurrencyWhole(prices.OnSiteSurvey) : '---,--'
        })
      }
    ],
    [prices]
  );

  const handleChange = useCallback(
    (value: string) => {
      actor.updateSession({surveyMethod: value as SurveyMethod});
    },
    [actor]
  );

  return (
    <BasicTab
      tab={TabKey.Survey}
      textParams={{onSiteSurveyPrice: prices.OnSiteSurvey ? formatCurrencyWhole(prices.OnSiteSurvey) : '---,--'}}
    >
      <ChoiceGroup as="radio" options={options} value={surveyMethod} onChange={handleChange} disabled={readOnly} />
    </BasicTab>
  );
}
