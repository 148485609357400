import BasicTab from '../components/BasicTab';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';

export default function SameFloorTab() {
  const [{sameFloor}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  return (
    <BasicTab tab={TabKey.SameFloor}>
      <YesNoRadioGroup value={sameFloor} onChange={sameFloor => actor.updateSession({sameFloor})} disabled={readOnly} />
    </BasicTab>
  );
}
