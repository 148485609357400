import {useEffect, useRef} from 'react';

import {api} from '../core/api';
import {useAppActor, useAppSelector} from '../redux';

export default function AutoSaver() {
  const [ready, sessionToken, sessionData, step] = useAppSelector(state => [
    state.ready,
    state.session.token,
    state.session.data,
    state.session.step
  ]);
  const actor = useAppActor();

  const saveStateRef = useRef<() => void>(() => {});
  saveStateRef.current = () => {
    if (!ready) {
      return;
    }

    console.log(`Saving state (token ${sessionToken})`, ready);
    if (sessionToken) {
      api
        .updateSession(sessionToken, sessionData, step)
        .then(() => actor.clearSaveError())
        .catch(e => {
          actor.markSaveError();
          console.log(e);
        });
    } else {
      api
        .createSession(sessionData)
        .then(session => {
          localStorage.setItem('session-token', session.token);
          actor.setSessionToken(session.token);
          actor.clearSaveError();
        })
        .catch(() => actor.markSaveError());
    }
  };

  const sessionDataRef = useRef(sessionData);
  sessionDataRef.current = sessionData;

  // 1: save automatically if no changes are recorded for 5s
  useEffect(() => {
    const shortTimeout = setTimeout(() => saveStateRef.current(), 5000);
    return () => clearTimeout(shortTimeout);
  }, [sessionData]);

  // 2: save at least once every 1 minute
  useEffect(() => {
    const interval = setInterval(() => saveStateRef.current(), 60000);
    return () => clearInterval(interval);
  }, []);

  // 3: save if step has changed
  useEffect(() => saveStateRef.current(), [step]);

  return <span />;
}
