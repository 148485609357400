import {T} from '../../core/Translate';

export interface ServerSessionPhoto {
  id: number;
  sessionId: number;
  s3Bucket?: string;
  s3Object?: string;
  mimetype: string;
  preview?: Buffer;
  tag: string;
}

export function translatePhotoTag(tag: string) {
  switch (tag) {
    case 'technical-panel':
      return T('electrical.technicalPhotos.overview');
    case 'fusebox':
      return T('electrical.technicalPhotos.fusebox');
    case 'meter-detail':
    case 'meter':
      return T('electrical.technicalPhotos.smartMeterDetail');
  }

  if (tag.startsWith('inverter-')) {
    const index = tag.split('-')[1];
    return T('electrical.solarPanels.photos.inverter', {inverter: index});
  } else if (tag.startsWith('inverterfuses-')) {
    const index = tag.split('-')[1];
    return T('electrical.solarPanels.photos.fuses', {inverter: index});
  } else {
    return tag;
  }
}
