import BasicTab from '../components/BasicTab';
import Form from '../components/inputs/Form';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import {T} from '../core/Translate';
import {isUsingWifi} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';

export default function ConnectivityTab() {
  const [data, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const {hasRouterAtFusebox, hasFreePortOnRouter, userCanProvidePort, hasWifiSignal} = data;
  const actor = useAppActor();
  const formState = useFormState();

  return (
    <BasicTab tab={TabKey.Connectivity} form={formState}>
      <Form state={formState}>
        <YesNoRadioGroup
          value={hasRouterAtFusebox}
          onChange={value => actor.updateSession({hasRouterAtFusebox: value})}
          disabled={readOnly}
        />
        {hasRouterAtFusebox === true && (
          <>
            <p>{T('electrical.connectivity.hasFreePort')}</p>
            <YesNoRadioGroup
              value={hasFreePortOnRouter}
              onChange={value => actor.updateSession({hasFreePortOnRouter: value})}
              disabled={readOnly}
            />
            {hasFreePortOnRouter === false && (
              <>
                <p>{T('electrical.connectivity.userCanProvidePort')}</p>
                <YesNoRadioGroup
                  value={userCanProvidePort}
                  onChange={value => actor.updateSession({userCanProvidePort: value})}
                  disabled={readOnly}
                />
              </>
            )}
          </>
        )}
        {isUsingWifi(data) && (
          <>
            <p>{T('electrical.connectivity.hasWifi')}</p>
            <YesNoRadioGroup
              value={hasWifiSignal}
              onChange={value => actor.updateSession({hasWifiSignal: value})}
              disabled={readOnly}
            />
          </>
        )}
        {isUsingWifi(data) && hasWifiSignal === false && <p>{T('electrical.connectivity.customerProvidesRepeater')}</p>}
      </Form>
    </BasicTab>
  );
}
