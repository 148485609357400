import React, {useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import PhotoUploader, {PhotoUploaderGroup} from '../components/PhotoUploader';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {Photo} from '../models/Photo';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {imageUrl} from '../utils/Images';

function getInverterName(index: number, single: boolean) {
  return single
    ? T('electrical.solarPanels.inverters.single')
    : T('electrical.solarPanels.inverters.x', {index: (index + 1).toString()});
}

function Images() {
  return (
    <p>
      <br />
      <Row>
        <Col md={2}>
          <p style={{textAlign: 'center'}}>
            <img src={imageUrl('/assets/images/solarpanels-2p.jpg')} style={{maxWidth: 100}} />
          </p>
        </Col>
        <Col md={4}>
          <p style={{textAlign: 'center'}}>
            <img src={imageUrl('/assets/images/solarpanels-4p.jpg')} style={{maxWidth: 200}} />
          </p>
        </Col>
      </Row>
      <br />
      <img src={imageUrl('/assets/images/solar-inverter.jpg')} style={{maxWidth: 500}} />
    </p>
  );
}

export default function SolarPanelsUploadTab() {
  const [token, {numberOfInverters = 0}, photos, readOnly] = useAppSelector(state => [
    state.session.token,
    state.session.data,
    state.session.photos,
    isReadOnly(state)
  ]);
  const actor = useAppActor();

  const inverterIndices = useMemo(() => {
    const result: number[] = [];
    for (let i = 0; i < numberOfInverters; i++) {
      result.push(i);
    }
    return result;
  }, [numberOfInverters]);

  const handleUpload = (photos: Photo[]) => {
    actor.uploadedPhoto(photos);
  };

  const handleDelete = (id: number) => {
    api.deletePhoto(token, id).then(() => {
      actor.deletePhoto(id);
    });
  };

  return (
    <BasicTab tab={TabKey.SolarPanelsUpload} extraInfo={Images}>
      <PhotoUploaderGroup>
        {inverterIndices.map(index => [
          <PhotoUploader
            key={`inverter-${index}`}
            tag={`inverter-${index + 1}`}
            label={T('electrical.solarPanels.photos.inverter', {
              inverter: getInverterName(index, numberOfInverters === 1)
            })}
            photos={photos}
            onUploaded={handleUpload}
            onDelete={handleDelete}
            disabled={readOnly}
          />,
          <PhotoUploader
            key={`fuses-${index}`}
            tag={`inverterfuses-${index + 1}`}
            label={T('electrical.solarPanels.photos.fuses', {
              inverter: getInverterName(index, numberOfInverters === 1)
            })}
            photos={photos}
            onUploaded={handleUpload}
            onDelete={handleDelete}
            disabled={readOnly}
          />
        ])}
      </PhotoUploaderGroup>
    </BasicTab>
  );
}
