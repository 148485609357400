import styles from './CircularProgressBar.module.scss';

export interface CircularProgressBarProps {
  ratio: number;
  children?: React.ReactNode;
}

export function CircularProgressBar(props: CircularProgressBarProps) {
  const {ratio, children} = props;
  const rotation = ratio * 360 - 180;

  return (
    <div className={styles.circular}>
      <div className={styles.inner} />
      <div className={styles.children}>
        <div className={styles.children2}>{children}</div>
      </div>
      <div className={styles.grayCircle} />
      {ratio > 0.01 && (
        <div className={styles.circle}>
          <div className={styles.bar} style={{transform: `rotate(${rotation}deg)`}}>
            <div className={styles.progress} />
          </div>
          {ratio < 0.5 && (
            <div className={styles.coverLeft}>
              <div className={styles.coverLeftCircle} />
            </div>
          )}
          {ratio > 0.5 && <div className={styles.coverRight} />}
        </div>
      )}
    </div>
  );
}
