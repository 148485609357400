import {useMemo} from 'react';

import BasicTab from '../components/BasicTab';
import Form from '../components/inputs/Form';
import {RadioGroup} from '../components/RadioGroup';
import {T} from '../core/Translate';
import {PermissionType} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';

export default function PermissionTab() {
  const [{permission}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();
  const formState = useFormState();

  const options = useMemo(
    () => [
      {value: PermissionType.Owner, label: T('general.permission.confirm')},
      {value: PermissionType.OnBehalf, label: T('general.permission.onbehalf')}
    ],
    []
  );

  return (
    <BasicTab tab={TabKey.Permission} form={formState}>
      <Form state={formState}>
        <RadioGroup
          value={permission || ''}
          options={options}
          setValue={value => actor.updateSession({permission: value as PermissionType})}
          disabled={readOnly}
        />
      </Form>
    </BasicTab>
  );
}
