import {T} from '../core/Translate';

import {PhaseType} from './ConfiguratorData';
import {getProductInfo, ProductCategory, ProductCode} from './Product';

export enum QuoteItemCategory {
  None = 0,
  Materials = 1,
  Installation = 2
}

export function getQuoteItemCategory(code: ProductCode): QuoteItemCategory {
  const product = getProductInfo(code);
  if (product === undefined) {
    return QuoteItemCategory.None;
  }

  switch (product.category) {
    case ProductCategory.Charger:
    case ProductCategory.AdditionalMaterials:
      return QuoteItemCategory.Materials;
    case ProductCategory.Installation:
      return QuoteItemCategory.Installation;
    default:
      return QuoteItemCategory.None;
  }
}

export interface QuoteItem {
  product: ProductCode;
  amount: number;
  unitPrice: number | undefined;
  unitOfMeasurement: number | undefined;
  category: QuoteItemCategory;
  name?: string;
}

export function groupQuoteItemsByCategory(items: QuoteItem[]): {category: QuoteItemCategory; items: QuoteItem[]}[] {
  const categories: QuoteItemCategory[] = [];
  const itemsByCategory: Map<QuoteItemCategory, QuoteItem[]> = new Map();

  for (let item of items) {
    if (!itemsByCategory.has(item.category)) {
      itemsByCategory.set(item.category, []);
      categories.push(item.category);
    }

    itemsByCategory.get(item.category)!.push(item);
  }

  categories.sort();
  return categories.map(category => ({
    category,
    items: itemsByCategory.get(category) || []
  }));
}

export interface Quote {
  completeness: QuoteCompleteness;
  missing: MissingQuoteData[];
  items: QuoteItem[];
  discountValue: number | null;
}

export enum QuoteCompleteness {
  Complete = 'complete',
  SurveyMissingData = 'survey-missing-data',
  SurveyRequired = 'survey-required',
  InsufficientData = 'insufficient-data',
  ContactSales = 'contact-sales'
}

export enum MissingQuoteData {
  PhaseType = 'phasetype',
  MountingMethod = 'mounting-method',
  NumberOfChargingPoints = 'number-of-charging-points',
  CableType = 'cable-type'
}

export function translateMissingData(item: MissingQuoteData) {
  switch (item) {
    case MissingQuoteData.PhaseType:
      return T('confirmation.price.missing.phaseType');
    case MissingQuoteData.MountingMethod:
      return T('confirmation.price.missing.mountingMethod');
    case MissingQuoteData.NumberOfChargingPoints:
      return T('confirmation.price.missing.numberOfChargingPoints');
    case MissingQuoteData.CableType:
      return 'Cable type missing'; // should never happen, it's a required field
    default:
      return '?';
  }
}

export function getNumberOfCTs(phaseType: PhaseType | undefined) {
  if (phaseType === PhaseType.SinglePhase) {
    return 1;
  } else if (phaseType === PhaseType.ThreePhaseDelta) {
    return 2;
  } else {
    return 3;
  }
}

export function getQuoteText(completeness: QuoteCompleteness) {
  switch (completeness) {
    case QuoteCompleteness.InsufficientData:
      return T('confirmation.price.text.couldNotQuote');
    case QuoteCompleteness.Complete:
      return T('confirmation.price.text.standard');
    case QuoteCompleteness.ContactSales:
      return T('confirmation.price.text.contactSales');
    case QuoteCompleteness.SurveyMissingData:
      return T('confirmation.price.text.surveyRequired');
    case QuoteCompleteness.SurveyRequired:
      return T('confirmation.price.text.surveyNeeded');
  }
}

export function translateQuoteItemCategory(category: QuoteItemCategory): string {
  switch (category) {
    case QuoteItemCategory.Materials:
      return T('confirmation.price.section.charger');
    case QuoteItemCategory.Installation:
      return T('confirmation.price.section.installation');
    default:
      return category.toString();
  }
}
