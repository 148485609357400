const baseUrls: {[key: string]: string} = {
  'localhost:3000': '',
  'dashboard.smappee.net': '/evonboardingwizard',
  'dash-staging.smappee.net': '/evonboardingwizard',
  'staging.smappee.com': 'https://onboardingwizard-staging.smappee.net',
  'smappee.com': 'https://onboardingwizard.smappee.net',
  'www.smappee.com': 'https://onboardingwizard.smappee.net'
};

const baseUrl =
  baseUrls[window.location.host] === undefined
    ? `${window.location.protocol}//${window.location.host}`
    : baseUrls[window.location.host];

export function imageUrl(url: string) {
  if (url.startsWith('/')) {
    return baseUrl + url;
  } else {
    return url;
  }
}

(window as any).imageBaseUrl = baseUrl;
