import {useMemo} from 'react';

import oneImage from '../assets/images/ev-one.jpg';
import wallImage from '../assets/images/ev-wall-cable-white.jpg';
import BasicTab from '../components/BasicTab';
import ChoiceGroup from '../components/ChoiceGroup';
import {RadioGroupOption} from '../components/RadioGroup';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import {T} from '../core/Translate';
import {MountingMethod} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

import {isReadOnly} from '../redux/AppState';

export default function MountingMethodTab() {
  const [{hasWall, mountingMethod}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  const options: RadioGroupOption[] = useMemo(
    () => [
      {
        value: MountingMethod.Wall,
        label: T('charger.mountingMethod.wall.label'),
        icon: wallImage,
        tooltip: T('charger.mountingMethod.wall.tooltip')
      },
      {
        value: MountingMethod.Standing,
        label: T('charger.mountingMethod.one.label'),
        icon: oneImage,
        tooltip: T('charger.mountingMethod.one.tooltip')
      }
    ],
    []
  );

  return (
    <BasicTab tab={TabKey.MountingMethod}>
      <ChoiceGroup
        as="buttons"
        options={options}
        value={mountingMethod}
        onChange={mountingMethod => actor.updateSession({mountingMethod: mountingMethod as MountingMethod})}
        width={250}
        height={250}
        disabled={readOnly}
        noBorder
      />
      <p>
        <i className="fas fa-info-circle" />
        &nbsp;
        {T('model.offering.disclaimer')}
      </p>
    </BasicTab>
  );
}
