import {T, TranslationKey} from '../core/Translate';
import {validateEmail, validateRequired} from '../utils/Validation';

import {CableType, ConfiguratorData, MountingMethod, PhaseType, SkipWizard, willChargeSlowly} from './ConfiguratorData';
import {Photo} from './Photo';
import {SessionStatus} from './SessionData';
import {MainTabKey, TabKey} from './TabKeys';

export const numberOfTabs = 4;

export function getMainTabTitle(tab: MainTabKey) {
  switch (tab) {
    case MainTabKey.General:
      return T('tab.general');
    case MainTabKey.Electrical:
      return T('tab.electrical');
    case MainTabKey.Charger:
      return T('tab.charger');
    case MainTabKey.Confirmation:
      return T('tab.confirmation');
    default:
      return '?';
  }
}

function isTabAvailable(tab: TabInfo, data: ConfiguratorData, photos: Photo[], status: SessionStatus): boolean {
  return tab.isAvailable === undefined || tab.isAvailable(data, photos, status);
}

export function getPreviousStep(
  tab: TabKey,
  data: ConfiguratorData,
  photos: Photo[],
  status: SessionStatus
): TabKey | null {
  let index = tabs.findIndex(t => t.tab === tab) - 1;

  while (index >= 0 && !isTabAvailable(tabs[index], data, photos, status)) {
    index--;
  }

  return index < 0 ? null : tabs[index].tab;
}

export function getNextStep(tab: TabKey, data: ConfiguratorData, photos: Photo[], status: SessionStatus) {
  let index = tabs.findIndex(t => t.tab === tab) + 1;
  while (index < tabs.length && !isTabAvailable(tabs[index], data, photos, status)) {
    index++;
  }

  return index >= tabs.length ? null : tabs[index].tab;
}

export interface TabInfo {
  tab: TabKey;
  mainTab: MainTabKey;
  subTab: number;
  title: TranslationKey;
  text: TranslationKey;
  info?: TranslationKey;
  isCompleted?: (data: ConfiguratorData, photos: Photo[]) => boolean;
  isAvailable?: (data: ConfiguratorData, photos: Photo[], status: SessionStatus) => boolean;
  isUnsureAvailable?: (data: ConfiguratorData) => boolean;
}

export function isTabAccessible(tab: TabKey, data: ConfiguratorData, photos: Photo[], status: SessionStatus): boolean {
  let index = tabs.findIndex(t => t.tab === tab) - 1;
  while (index >= 0) {
    const tabInfo = tabs[index--];

    if (tabInfo.isAvailable !== undefined && !tabInfo.isAvailable(data, photos, status)) {
      continue;
    }

    if (tabInfo.isCompleted !== undefined && !tabInfo.isCompleted(data, photos)) {
      return false;
    }
  }

  return true;
}

function isPersonInfoFilled(data: ConfiguratorData) {
  return (
    validateRequired(data.firstName, '') === undefined &&
    validateRequired(data.lastName, '') === undefined &&
    validateEmail(data.email) === undefined &&
    data.agreesWithCallback === true
  );
}

function isSolarPanelsCompleted(data: ConfiguratorData) {
  if (data.hasSolar === undefined) {
    return false;
  }
  if (data.hasSolar === false) {
    return true;
  }
  if (data.numberOfInvertersUnknown) {
    return true;
  }
  if (data.numberOfInverters === undefined) {
    return false;
  }
  if (data.numberOfInverters === 0) {
    return false;
  }
  if (data.numberOfInverters > 1) {
    return true;
  } // no details if multiple inverters
  if (data.phaseType !== PhaseType.SinglePhase && data.singleInverterType === undefined) {
    return false;
  }
  if (data.inverterLocation === undefined) {
    return false;
  }

  return true;
}

function isConnectivityCompleted(data: ConfiguratorData) {
  if (data.hasRouterAtFusebox === undefined) {
    return false;
  }

  if (data.hasRouterAtFusebox) {
    if (data.hasFreePortOnRouter === undefined) {
      return false;
    }

    return data.hasFreePortOnRouter === true || data.userCanProvidePort !== undefined;
  } else {
    return data.hasWifiSignal !== undefined;
  }
}
function isTechnicalPhotosCompleted(data: ConfiguratorData, photos: Photo[]) {
  return (
    photos.some(photo => photo.tag === 'technical-panel') &&
    photos.some(photo => photo.tag === 'fusebox') &&
    photos.some(photo => photo.tag === 'meter-detail')
  );
}

function isSolarPanelUploadsCompleted(data: ConfiguratorData, photos: Photo[]) {
  if (data.hasSolar === undefined || data.hasSolar === false) {
    return true;
  }
  if (data.numberOfInvertersUnknown) {
    return true;
  }
  if (data.numberOfInverters === undefined) {
    return false;
  }

  const numberOfInverters = data.numberOfInverters;
  if (numberOfInverters === undefined || data.numberOfInverters === 0) {
    return false;
  }

  for (let i = 0; i < numberOfInverters; i++) {
    const tagForInverter = `inverter-${i + 1}`;
    const tagForFuses = `inverterfuses-${i + 1}`;
    if (!photos.some(photo => photo.tag === tagForInverter)) {
      return false;
    }
    if (!photos.some(photo => photo.tag === tagForFuses)) {
      return false;
    }
  }

  return true;
}

const defaultTabs: TabInfo[] = [
  {
    tab: TabKey.Information,
    mainTab: MainTabKey.General,
    subTab: 1,
    title: 'general.information.title',
    text: 'general.information.text',
    isCompleted: isPersonInfoFilled
  } /*, {
    tab: TabKey.SkipWizard,
    mainTab: MainTabKey.General,
    subTab: 2,
    title: 'general.skipwizard.title',
    text: 'general.skipwizard.text',
    isCompleted: data => data.skipWizard !== undefined
  }, {
    tab: TabKey.Permission,
    mainTab: MainTabKey.General,
    subTab: 2,
    title: 'general.permission.title',
    text: 'general.permission.text',
    isCompleted: data => data.permission !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  }*/,
  {
    tab: TabKey.Building,
    mainTab: MainTabKey.General,
    subTab: 2,
    title: 'general.building.title',
    text: 'general.building.text',
    isCompleted: data => data.buildingType !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.Phases,
    mainTab: MainTabKey.Electrical,
    subTab: 1,
    title: 'electrical.phases.title',
    text: 'electrical.phases.text',
    info: 'electrical.phases.info',
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.MaximumCurrent,
    mainTab: MainTabKey.Electrical,
    subTab: 2,
    title: 'electrical.maximumCurrent.title',
    text: 'electrical.maximumCurrent.text',
    info: 'electrical.maximumCurrent.info',
    isAvailable: data => data.skipWizard !== SkipWizard.Skip,
    isCompleted: data => data.maximumCurrent !== undefined,
    isUnsureAvailable: () => true
  },
  {
    tab: TabKey.Fuseboxes,
    mainTab: MainTabKey.Electrical,
    subTab: 3,
    title: 'electrical.fuseboxes.title',
    text: 'electrical.fuseboxes.text',
    isCompleted: data => data.multipleFuseboxes !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.TechnicalPhotos,
    mainTab: MainTabKey.Electrical,
    subTab: 4,
    title: 'electrical.technicalPhotos.title',
    text: 'electrical.technicalPhotos.text',
    info: 'electrical.technicalPhotos.info',
    isCompleted: isTechnicalPhotosCompleted,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.FreeTechnicalSpace,
    mainTab: MainTabKey.Electrical,
    subTab: 5,
    title: 'electrical.freeTechnicalSpace.title',
    text: 'electrical.freeTechnicalSpace.text',
    info: 'electrical.freeTechnicalSpace.info',
    isCompleted: data => data.hasFreeSpace !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.SolarPanels,
    mainTab: MainTabKey.Electrical,
    subTab: 6,
    title: 'electrical.solarPanels.title',
    text: 'electrical.solarPanels.text',
    info: 'electrical.solarPanels.info',
    isCompleted: isSolarPanelsCompleted,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.SolarPanelsUpload,
    mainTab: MainTabKey.Electrical,
    subTab: 7,
    title: 'electrical.solarPanelsUpload.title',
    text: 'electrical.solarPanelsUpload.text',
    info: 'electrical.solarPanelsUpload.info',
    isCompleted: isSolarPanelUploadsCompleted,
    isAvailable: data => data.hasSolar === true && !data.numberOfInvertersUnknown && data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.Connectivity,
    mainTab: MainTabKey.Electrical,
    subTab: 8,
    title: 'electrical.connectivity.title',
    text: 'electrical.connectivity.text',
    info: 'electrical.connectivity.info',
    isCompleted: isConnectivityCompleted,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.PreparatoryWorks,
    mainTab: MainTabKey.Electrical,
    subTab: 9,
    title: 'electrical.preparatoryWorks.title',
    text: 'electrical.preparatoryWorks.text',
    isCompleted: data => data.preparatoryWorks !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.NumberOfConnections,
    mainTab: MainTabKey.Charger,
    subTab: 1,
    title: 'charger.numberOfConnections.title',
    text: 'charger.numberOfConnections.text',
    info: 'charger.numberOfConnections.info',
    isCompleted: data => data.numberOfChargingPoints !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.MountingMethod,
    mainTab: MainTabKey.Charger,
    subTab: 2,
    title: 'charger.mountingMethod.title',
    text: 'charger.mountingMethod.text',
    info: 'charger.mountingMethod.info',
    isCompleted: data => data.mountingMethod !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.Cable,
    mainTab: MainTabKey.Charger,
    subTab: 3,
    title: 'charger.cable.title',
    text: 'charger.cable.text',
    info: 'charger.cable.info',
    isCompleted: data =>
      data.cable === CableType.NoCable ||
      (data.mountingMethod === MountingMethod.Wall && data.cable === CableType.Cable8m) ||
      (data.mountingMethod === MountingMethod.Standing && data.cable === CableType.Cable5m),
    isAvailable: data => data.skipWizard !== SkipWizard.Skip && data.mountingMethod !== MountingMethod.Standing
  },
  {
    tab: TabKey.Color,
    mainTab: MainTabKey.Charger,
    subTab: 4,
    title: 'charger.color.title',
    text: 'charger.color.text',
    isCompleted: data => data.color !== undefined,
    isAvailable: data =>
      data.skipWizard !== SkipWizard.Skip &&
      data.mountingMethod === MountingMethod.Wall &&
      data.cable !== CableType.NoCable
  },
  {
    tab: TabKey.Situation,
    mainTab: MainTabKey.Charger,
    subTab: 5,
    title: 'charger.situation.title',
    text: 'charger.situation.text',
    //isCompleted: isSituationCompleted,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.SameFloor,
    mainTab: MainTabKey.Charger,
    subTab: 6,
    title: 'charger.sameFloor.title',
    text: 'charger.sameFloor.text',
    isCompleted: data => data.sameFloor !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.CableLengths,
    mainTab: MainTabKey.Charger,
    subTab: 7,
    title: 'charger.cableLengths.title',
    text: 'charger.cableLengths.text',
    info: 'charger.cableLengths.info',
    isCompleted: data => data.estimatedCableLength !== undefined,
    isUnsureAvailable: () => true,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.HolesAndDigging,
    mainTab: MainTabKey.Charger,
    subTab: 8,
    title: 'charger.holesAndDigging.title',
    text: 'charger.holesAndDigging.text',
    isCompleted: data => data.diggingSituation !== undefined,
    isUnsureAvailable: () => true,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.CableTray,
    mainTab: MainTabKey.Charger,
    subTab: 9,
    title: 'charger.cableTray.title',
    text: 'generic.empty',
    info: 'charger.cableTray.info',
    isCompleted: data => data.cableTray !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.ExistingCharger,
    mainTab: MainTabKey.Charger,
    subTab: 10,
    title: 'charger.existingCharger.title',
    text: 'charger.existingCharger.text',
    isCompleted: data => data.existingCharger !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.ChargingSpeed,
    mainTab: MainTabKey.Charger,
    subTab: 11,
    title: 'general.chargingspeed.title',
    text: 'general.chargingspeed.text',
    info: 'general.chargingspeed.info',
    isCompleted: data => data.chargingSpeed !== undefined,
    isAvailable: data => willChargeSlowly(data) && data.skipWizard !== SkipWizard.Skip
  } /*, {
    tab: TabKey.Survey,
    mainTab: MainTabKey.Confirmation,
    subTab: 1,
    title: 'confirmation.survey.title',
    text: 'confirmation.survey.text',
    isCompleted: data => data.surveyMethod !== undefined,
    isAvailable: (data, photos) => isSurveyRequest(data, photos) && data.skipWizard !== SkipWizard.Skip
  }*/,
  {
    tab: TabKey.InstallationBillingData,
    mainTab: MainTabKey.Confirmation,
    subTab: 2,
    title: 'confirmation.installationAndBilling.title',
    text: 'confirmation.installationAndBilling.text'
  },
  {
    tab: TabKey.SubmittingRequest,
    mainTab: MainTabKey.Confirmation,
    subTab: 3,
    title: 'confirmation.submitting.title',
    text: 'confirmation.submitting.text',
    isAvailable: (data, photos, status) => status == SessionStatus.Filling
  },
  {
    tab: TabKey.Completed,
    mainTab: MainTabKey.Confirmation,
    subTab: 4,
    title: 'confirmation.completed.title',
    text: 'confirmation.completed.text'
  },
  {
    tab: TabKey.ContactSales,
    mainTab: MainTabKey.Confirmation,
    subTab: 4,
    title: 'confirmation.contactSalesTab.title',
    text: 'confirmation.contactSalesTab.text',
    isAvailable: () => false
  }
];

const UCBTabs: TabInfo[] = [
  {
    tab: TabKey.ChargerModel,
    mainTab: MainTabKey.Charger,
    subTab: 1,
    title: 'charger.chargerModel.title',
    text: 'charger.chargerModel.text',
    isCompleted: data => data.mountingMethod !== undefined && data.hasSolar !== undefined,
    isAvailable: data => data.skipWizard !== SkipWizard.Skip
  },
  {
    tab: TabKey.UCBInstallationBillingData,
    mainTab: MainTabKey.Confirmation,
    subTab: 1,
    title: 'confirmation.installationAndBilling.title',
    text: 'confirmation.installationAndBilling.text',
    isCompleted: data => data.agreesWithCallback === true
  },
  {
    tab: TabKey.SubmittingRequest,
    mainTab: MainTabKey.Confirmation,
    subTab: 2,
    title: 'confirmation.submitting.title',
    text: 'confirmation.submitting.text',
    isAvailable: (data, photos, status) => status == SessionStatus.Filling
  },
  {
    tab: TabKey.Completed,
    mainTab: MainTabKey.Confirmation,
    subTab: 3,
    title: 'confirmation.completed.title',
    text: 'confirmation.completed.text'
  }
];

export const tabs = process.env.REACT_APP_UCB_CONFIG !== undefined ? UCBTabs : defaultTabs;

const stepsInTab = tabs.reduce((steps, tab) => {
  steps[tab.mainTab] = Math.max(steps[tab.mainTab] || 0, tab.subTab);
  return steps;
}, {} as {[key: string]: number});
stepsInTab[MainTabKey.Confirmation]--;

export function getStepsInTab(tab: MainTabKey) {
  return stepsInTab[tab] || 0;
}

const UnknownTab: TabInfo = {
  tab: TabKey.Invalid,
  mainTab: MainTabKey.General,
  subTab: 1,
  title: 'invalidTab.title',
  text: 'invalidTab.text'
};

export function getTabInfo(tab: TabKey): TabInfo {
  return tabs.find(info => info.tab === tab) || UnknownTab;
}

export function getMainTab(tab: TabKey) {
  return getTabInfo(tab).mainTab;
}

export function getFirstTabOfMain(main: MainTabKey): TabKey {
  const info = tabs.find(info => info.mainTab === main);
  return info ? info.tab : TabKey.Information;
}

function translateMainTab(main: MainTabKey) {
  switch (main) {
    case MainTabKey.General:
      return T('tab.general');
    case MainTabKey.Electrical:
      return T('tab.electrical');
    case MainTabKey.Charger:
      return T('tab.charger');
    case MainTabKey.Confirmation:
      return T('tab.confirmation');
    default:
      return main;
  }
}

export function exportTabsToCSV() {
  return tabs.map(tab => [translateMainTab(tab.mainTab), T(tab.title)].join(';'));
}
