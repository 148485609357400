import React, {useState} from 'react';
import {Button, Container} from 'react-bootstrap';

import {AdminSettings} from '../models/AdminSettings';

import LoginPanel from './LoginPanel';
import SessionsPanel from './SessionsPanel';

const defaultSettings: AdminSettings = {
  sessionColumns: ['id', 'firstName', 'lastName', 'tab', 'status', 'lastUpdated']
};

export default function AdminApp() {
  const [loginToken, setLoginToken] = useState<string | null>(localStorage.getItem('admin-token'));
  const [adminSettings, setAdminSettings] = useState<AdminSettings>({
    ...defaultSettings,
    ...JSON.parse(localStorage.getItem('admin-settings') || '{}')
  });

  const handleLogin = (token: string) => {
    localStorage.setItem('admin-token', token);
    setLoginToken(token);
  };

  const handleLogout = () => {
    localStorage.removeItem('admin-token');
    setLoginToken(null);
  };

  const handleUpdateSettings = (updates: Partial<AdminSettings>) => {
    const newSettings = {...adminSettings, ...updates};
    localStorage.setItem('admin-settings', JSON.stringify(newSettings));
    setAdminSettings(newSettings);
  };

  return (
    <Container style={{marginTop: '2rem'}}>
      {loginToken !== null && (
        <div style={{float: 'right'}}>
          <Button variant="link" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      )}
      <h1>Administration</h1>
      {loginToken === null ? (
        <LoginPanel onLogin={handleLogin} />
      ) : (
        <SessionsPanel token={loginToken} settings={adminSettings} onChangeSettings={handleUpdateSettings} />
      )}
    </Container>
  );
}
