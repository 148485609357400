import {MountingMethod} from './ConfiguratorData';
import {SessionStatus} from './SessionData';
import {TabKey} from './TabKeys';

export interface AdminViewableSession {
  id: number;
  firstName: string;
  lastName: string;
  step: TabKey;
  status: SessionStatus;
  project?: string;
  updateTimestamp: string;
  type: SessionRequestType;
  mountingMethod?: MountingMethod;
  numberOfChargingPoints?: number | 'more';
  actionCode?: string;
}

export enum SessionRequestType {
  Filling = 'filling',
  Installation = 'installation',
  OnSiteSurvey = 'on-site-survey',
  RemoteSurvey = 'remote-survey',
  SkipToOnSite = 'skip-on-site',
  SkipToRemote = 'skip-remote',
  Unknown = 'unknown'
}
