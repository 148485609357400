import {useMemo, useReducer} from 'react';

let idCounter = 0;

class FormSharedState {
  id: number;
  refs: {[key: string]: HTMLInputElement} = {};

  constructor() {
    this.id = idCounter++;
  }
}

interface IFormState {
  shared: FormSharedState;
  inputs: {[key: string]: FormInputState};
  errorCount: number;
}

export class FormState {
  state: IFormState;
  dispatch: (_action: FormAction) => void;

  constructor(state: IFormState, dispatch: (action: FormAction) => void) {
    this.state = state;
    this.dispatch = dispatch;
  }

  getInput(field: string): FormInputState {
    return this.state.inputs[field] || {name: field, error: undefined, errorVisible: false, serverError: undefined};
  }

  setError(field: string, error: string | undefined) {
    const input = this.getInput(field);
    const currentError = input.error;
    if (error === currentError) {
      return;
    }

    const errorVisible = input.errorVisible && error !== undefined;
    this.updateField(field, {error, errorVisible});
  }

  remove(field: string) {
    this.removeField(field);
  }

  setErrorVisible(field: string, visible: boolean) {
    if (visible === this.getErrorVisible(field)) {
      return;
    }

    this.updateField(field, {errorVisible: visible});
  }

  getErrorVisible(field: string) {
    return this.getInput(field).errorVisible;
  }

  getRef(field: string) {
    return this.state.shared.refs[field];
  }

  clearServerErrors() {
    this.dispatch({type: 'clearServerErrors'});
  }

  setServerError(field: string, error: string | undefined) {
    if (error === this.getServerError(field)) {
      return;
    }

    this.updateField(field, {serverError: error});
  }

  setRef(field: string, element: HTMLInputElement | null) {
    if (element) {
      this.state.shared.refs[field] = element;
    } else {
      delete this.state.shared.refs[field];
    }
  }

  focus(field: string) {
    const element = this.state.shared.refs[field];
    if (element) {
      element.focus();
    }
  }

  getError(field: string): string | undefined {
    return this.getInput(field).error;
  }

  getServerError(field: string): string | undefined {
    return this.getInput(field).serverError;
  }

  getShownError(field: string): string | undefined {
    const input = this.getInput(field);
    return input.errorVisible ? input.error : input.serverError;
  }

  hasErrors() {
    return this.state.errorCount > 0;
  }

  showErrors() {
    this.dispatch({type: 'showErrors'});
  }

  updateField(name: string, updates: Partial<FormInputState>) {
    this.dispatch({type: 'updateField', name, updates});
  }

  removeField(name: string) {
    this.dispatch({type: 'removeField', name});
  }

  debug() {
    console.log(`Errors: ${this.state.errorCount}`, this.state.inputs);
  }
}

export const DummyFormState = new FormState({shared: new FormSharedState(), inputs: {}, errorCount: 0}, () => {});

interface BaseFormAction {
  type: string;
}

interface UpdateFieldFormAction extends BaseFormAction {
  type: 'updateField';
  name: string;
  updates: Partial<FormInputState>;
}

interface RemoveFieldAction extends BaseFormAction {
  type: 'removeField';
  name: string;
}

interface ShowErrorsAction extends BaseFormAction {
  type: 'showErrors';
}

interface ClearServerErrorsAction extends BaseFormAction {
  type: 'clearServerErrors';
}

type FormAction = UpdateFieldFormAction | RemoveFieldAction | ShowErrorsAction | ClearServerErrorsAction;

export function useFormState(): FormState {
  const [state, dispatch] = useReducer(
    (state: IFormState, action: FormAction) => {
      switch (action.type) {
        case 'updateField': {
          const input = state.inputs[action.name] || {
            name: action.name,
            error: undefined,
            errorVisible: false,
            serverError: undefined
          };
          const updatedInput = Object.assign({}, input, action.updates);
          let errors = state.errorCount;
          if (input.error !== undefined) {
            errors--;
          }
          if (updatedInput.error !== undefined) {
            errors++;
          }
          const updatedInputs = Object.assign({}, state.inputs, {[action.name]: updatedInput});
          return Object.assign({}, state, {inputs: updatedInputs, errorCount: errors});
        }
        case 'removeField': {
          const input = state.inputs[action.name];
          if (input === undefined) {
            return state;
          }

          const updatedInputs = Object.assign({}, state.inputs);
          delete updatedInputs[action.name];
          const errors = input.error === undefined ? state.errorCount : state.errorCount - 1;
          return Object.assign({}, state, {inputs: updatedInputs, errorCount: errors});
        }
        case 'showErrors': {
          const newInputs = {...state.inputs};
          for (let key in newInputs) {
            if (newInputs[key].error !== undefined) {
              newInputs[key] = Object.assign({}, newInputs[key], {errorVisible: true});
            }
          }
          return Object.assign({}, state, {inputs: newInputs});
        }
        case 'clearServerErrors': {
          const newInputs = {...state.inputs};
          for (let key in newInputs) {
            if (newInputs[key].error !== undefined) {
              newInputs[key] = Object.assign({}, newInputs[key], {serverError: undefined});
            }
          }
          return Object.assign({}, state, {inputs: newInputs});
        }
        default:
          return state;
      }
    },
    {shared: new FormSharedState(), inputs: {}, errorCount: 0}
  );
  return useMemo(() => new FormState(state, dispatch), [state]);
}

export interface FormInputState {
  name: string;
  error?: string;
  errorVisible: boolean;
  serverError?: string;
}
