import {ConfiguratorData} from '../models/ConfiguratorData';
import {Photo} from '../models/Photo';
import {SessionStatus, StoredSessionData} from '../models/SessionData';
import {TabKey} from '../models/TabKeys';

export enum AppActionType {
  SetSessionToken = 'setSessionToken',
  SetStep = 'setStep',
  ClearSession = 'clearSession',
  UpdateSession = 'updateSession',
  LoadSession = 'loadSession',
  Ready = 'ready',
  AddPhotos = 'addPhotos',
  DeletePhoto = 'deletePhoto',
  MarkLoadError = 'markLoadError',
  SetSessionStatus = 'setSessionStatus',
  MarkSaveError = 'markSaveError',
  ClearSaveError = 'clearSaveError'
}

interface IBaseAction {
  type: AppActionType;
}

interface SetSessionTokenAction extends IBaseAction {
  type: AppActionType.SetSessionToken;
  payload: {token: string};
}

interface SetStepAction extends IBaseAction {
  type: AppActionType.SetStep;
  payload: TabKey;
}

interface ClearSessionAction extends IBaseAction {
  type: AppActionType.ClearSession;
  payload: {};
}

interface UpdateSessionAction extends IBaseAction {
  type: AppActionType.UpdateSession;
  payload: Partial<ConfiguratorData>;
}

interface LoadSessionAction extends IBaseAction {
  type: AppActionType.LoadSession;
  payload: StoredSessionData;
}

interface ReadyAction extends IBaseAction {
  type: AppActionType.Ready;
  payload: {};
}

interface AddPhotosAction extends IBaseAction {
  type: AppActionType.AddPhotos;
  payload: Photo[];
}

interface DeletePhotoAction extends IBaseAction {
  type: AppActionType.DeletePhoto;
  payload: number;
}

interface MarkLoadErrorAction extends IBaseAction {
  type: AppActionType.MarkLoadError;
  payload: {};
}

interface SetSessionStatus extends IBaseAction {
  type: AppActionType.SetSessionStatus;
  payload: SessionStatus;
}

interface MarkSaveErrorAction extends IBaseAction {
  type: AppActionType.MarkSaveError;
  payload: {};
}

interface ClearSaveErrorAction extends IBaseAction {
  type: AppActionType.ClearSaveError;
  payload: {};
}

export type AnyAppAction =
  | SetSessionTokenAction
  | SetStepAction
  | ClearSessionAction
  | UpdateSessionAction
  | LoadSessionAction
  | ReadyAction
  | AddPhotosAction
  | DeletePhotoAction
  | MarkLoadErrorAction
  | SetSessionStatus
  | MarkSaveErrorAction
  | ClearSaveErrorAction;
