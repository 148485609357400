import React, {useMemo, useState} from 'react';

import {api} from '../core/api';
import {T} from '../core/Translate';
import {Photo} from '../models/Photo';
import {useAppSelector} from '../redux';

import {FileUploaderComponent} from './FileUploader';

import styles from './PhotoUploader.module.scss';
import PhotoUploaderCarrousel from './PhotoUploaderCarrousel';
import ProgressBar from './ProgressBar';

interface PhotoUploaderProps {
  tag: string;
  label: string;
  photos: Photo[];
  onUploaded: (photos: Photo[]) => void;
  onDelete: (id: number) => void;
  disabled?: boolean;
}

export function PhotoUploaderGroup(props: {children: React.ReactNode}) {
  return <div className={styles.group}>{props.children}</div>;
}

export default function PhotoUploader(props: PhotoUploaderProps) {
  const {label, tag, photos, onUploaded, onDelete, disabled = false} = props;
  const token = useAppSelector(state => state.session.token);

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [failed, setFailed] = useState(false);
  const handleStart = () => {
    setUploading(true);
    setFailed(false);
  };
  const handleProgress = (e: any) => {
    setProgress((e.loaded / e.total) * 100);
  };
  const handleSuccess = (e: any) => {
    setUploading(false);
    setProgress(0);
    onUploaded(e.result.files);
  };
  const handleFail = () => {
    setUploading(false);
    setFailed(true);
  };

  const filteredPhotos = useMemo(() => photos.filter(photo => photo.tag === tag), [photos, tag]);

  return (
    <div className={styles.wrapper}>
      {filteredPhotos.length === 0 ? (
        <FileUploaderComponent
          className={styles.uploader}
          url={api.getFileUploadUrl()}
          data={{token, tag}}
          style={uploading ? {display: 'none'} : undefined}
          onStart={handleStart}
          onProgress={handleProgress}
          onSuccess={handleSuccess}
          onFail={handleFail}
          disabled={disabled}
        >
          {!disabled && (
            <div className={styles.addIcon}>
              <i className="fal fa-plus" />
              {failed && <p className={styles.error}>{T('component.fileUpload.failed')}</p>}
            </div>
          )}
        </FileUploaderComponent>
      ) : (
        <PhotoUploaderCarrousel
          photos={filteredPhotos}
          tag={tag}
          token={token}
          disabled={disabled}
          onUploaded={onUploaded}
          onDeleted={onDelete}
        />
      )}
      {uploading && (
        <div className={styles.uploader}>
          <ProgressBar max={100} current={progress} width={100} />
          <span>{`${progress.toFixed(0)}%`}</span>
        </div>
      )}
      <div className={styles.label}>{label}</div>
    </div>
  );
}
