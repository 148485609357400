import React, {useCallback, useEffect} from 'react';

import {Tab} from 'react-bootstrap';

import AppLoadingIndicator from './components/AppLoadingIndicator';
import {CookieNagger} from './components/CookieNagger';
import Layout from './components/Layouts';
import ReadOnlyNotice from './components/ReadOnlyNotice';
import SaveError from './components/SaveError';
import TabsHeader from './components/TabsHeader';
import {SessionStatus} from './models/SessionData';
import {MainTabKey, TabKey} from './models/TabKeys';
import {getFirstTabOfMain, getTabInfo, isTabAccessible} from './models/Tabs';
import {useAppActor, useAppSelector} from './redux';
import {isReadOnly} from './redux/AppState';
import BuildingTypeTab from './tabs/BuildingTypeTab';
import CableLengthsTab from './tabs/CableLengthsTab';
import CableTab from './tabs/CableTab';
import CableTrayTab from './tabs/CableTrayTab';
import ChargingSpeedTab from './tabs/ChargingSpeedTab';
import ColorTab from './tabs/ColorTab';
import CompletedTab from './tabs/CompletedTab';
import ConnectivityTab from './tabs/ConnectivityTab';
import ContactSalesTab from './tabs/ContactSalesTab';
import ExistingChargerTab from './tabs/ExistingChargerTab';
import FreeTechnicalSpaceTab from './tabs/FreeTechnicalSpaceTab';
import FuseboxesTab from './tabs/FuseboxesTab';
import HolesAndDiggingTab from './tabs/HolesAndDiggingTab';
import InstallationBillingTab from './tabs/InstallationBillingTab';
import InvalidTab from './tabs/InvalidTab';
import MaximumCurrentTab from './tabs/MaximumCurrentTab';
import MountingMethodTab from './tabs/MountingMethodTab';
import NumberOfChargingPointsTab from './tabs/NumberOfChargingPointsTab';
import PermissionTab from './tabs/PermissionTab';
import {PersonInformationTab} from './tabs/PersonalInformationTab';
import PhasesTab from './tabs/PhasesTab';
import PreparatoryWorksTab from './tabs/PreparatoryWorksTab';
import {QuoteTab} from './tabs/QuoteTab';
import SameFloorTab from './tabs/SameFloorTab';
import SignQuoteTab from './tabs/SignQuoteTab';
import SignSurveyQuoteTab from './tabs/SignSurveyQuoteTab';
import SituationTab from './tabs/SituationTab';
import SkipWizardTab from './tabs/SkipWizardTab';
import SolarPanelsTab from './tabs/SolarPanelsTab';
import SolarPanelsUploadTab from './tabs/SolarPanelsUploadTab';
import SubmittingRemoteSurveyRequestTab from './tabs/SubmittingRemoteSurveyRequestTab';
import SubmittingRequestTab from './tabs/SubmittingRequestTab';
import SurveyConfirmationTab from './tabs/SurveyConfirmationTab';
import SurveyTab from './tabs/SurveyTab';
import TechnicalPhotosTab from './tabs/TechnicalPhotosTab';

export interface AppParams {
  projectId: string | undefined;
  employeeNumber: boolean;
}

interface AppProps {
  goToTab?: TabKey;
  params: AppParams;
}

function App(props: AppProps) {
  const {goToTab, params} = props;

  const [ready, {step: currentTab, data, photos}, readOnly, saveError, status, project] = useAppSelector(state => [
    state.ready,
    state.session,
    isReadOnly(state),
    state.saveError,
    state.session.status,
    state.session.data.project
  ]);
  const tab = getTabInfo(currentTab);
  const actor = useAppActor();

  const setCurrentTab = useCallback(
    (tab: MainTabKey) => {
      const targetTab = getFirstTabOfMain(tab);
      if (!isTabAccessible(targetTab, data, photos, status)) {
        return;
      }

      actor.setStep(targetTab);
    },
    [actor, data, photos, status]
  );

  useEffect(() => {
    if (ready && params.projectId && params.projectId !== project && status === SessionStatus.Filling) {
      actor.updateSession({project: params.projectId});
    }
  }, [actor, ready, params.projectId, project, status]);

  return (
    <Layout>
      {ready ? (
        <Tab.Container id="smpevc-tabs" activeKey={tab.mainTab} onSelect={key => setCurrentTab(key as MainTabKey)}>
          <TabsHeader />
          {readOnly && currentTab !== TabKey.Completed && currentTab !== TabKey.SurveyConfirmation && (
            <ReadOnlyNotice />
          )}
          <Tab.Content>
            <Tab.Pane eventKey={MainTabKey.General}>
              {tab.tab === TabKey.Invalid && <InvalidTab />}
              {currentTab === TabKey.Information && (
                <PersonInformationTab requiresEmployeeNumber={params.employeeNumber} />
              )}
              {currentTab === TabKey.SkipWizard && <SkipWizardTab />}
              {currentTab === TabKey.Permission && <PermissionTab />}
              {currentTab === TabKey.Building && <BuildingTypeTab />}
            </Tab.Pane>
            <Tab.Pane eventKey={MainTabKey.Electrical}>
              {currentTab === TabKey.Phases && <PhasesTab />}
              {currentTab === TabKey.MaximumCurrent && <MaximumCurrentTab />}
              {currentTab === TabKey.Fuseboxes && <FuseboxesTab />}
              {currentTab === TabKey.TechnicalPhotos && <TechnicalPhotosTab />}
              {currentTab === TabKey.FreeTechnicalSpace && <FreeTechnicalSpaceTab />}
              {currentTab === TabKey.SolarPanels && <SolarPanelsTab />}
              {currentTab === TabKey.SolarPanelsUpload && <SolarPanelsUploadTab />}
              {currentTab === TabKey.Connectivity && <ConnectivityTab />}
              {currentTab === TabKey.PreparatoryWorks && <PreparatoryWorksTab />}
            </Tab.Pane>
            <Tab.Pane eventKey={MainTabKey.Charger}>
              {currentTab === TabKey.NumberOfConnections && <NumberOfChargingPointsTab />}
              {currentTab === TabKey.MountingMethod && <MountingMethodTab />}
              {currentTab === TabKey.Color && <ColorTab />}
              {currentTab === TabKey.Cable && <CableTab />}
              {currentTab === TabKey.SameFloor && <SameFloorTab />}
              {currentTab === TabKey.Situation && <SituationTab />}
              {currentTab === TabKey.CableLengths && <CableLengthsTab />}
              {currentTab === TabKey.HolesAndDigging && <HolesAndDiggingTab />}
              {currentTab === TabKey.CableTray && <CableTrayTab />}
              {currentTab === TabKey.ExistingCharger && <ExistingChargerTab />}
              {currentTab === TabKey.ChargingSpeed && <ChargingSpeedTab />}
            </Tab.Pane>
            <Tab.Pane eventKey={MainTabKey.Confirmation}>
              {currentTab === TabKey.Quote && <QuoteTab />}
              {currentTab === TabKey.InstallationBillingData && <InstallationBillingTab />}
              {currentTab === TabKey.SignQuote && <SignQuoteTab />}
              {currentTab === TabKey.ContactSales && <ContactSalesTab />}
              {currentTab === TabKey.Completed && <CompletedTab />}
              {currentTab === TabKey.Survey && <SurveyTab />}
              {currentTab === TabKey.SignSurveyQuote && <SignSurveyQuoteTab />}
              {currentTab === TabKey.SubmittingRequest && <SubmittingRequestTab />}
              {currentTab === TabKey.SubmittingRemoteSurveyRequest && <SubmittingRemoteSurveyRequestTab />}
              {currentTab === TabKey.SurveyConfirmation && <SurveyConfirmationTab />}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      ) : (
        <AppLoadingIndicator goToTab={goToTab} />
      )}
      {saveError && <SaveError />}
      <CookieNagger />
    </Layout>
  );
}

export default App;
