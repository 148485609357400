import {useCallback, useMemo} from 'react';

import BasicTab from '../components/BasicTab';
import ChoiceGroup from '../components/ChoiceGroup';
import {RadioGroupOption} from '../components/RadioGroup';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

import {isReadOnly} from '../redux/AppState';

export default function NumberOfChargingPointsTab() {
  const [numberOfChargingPoints, readOnly] = useAppSelector(state => [
    state.session.data.numberOfChargingPoints,
    isReadOnly(state)
  ]);
  const actor = useAppActor();

  const handleChange = useCallback(
    (value: string) => {
      const actualValue = value === 'more' ? 'more' : (parseInt(value) as 1 | 2);
      actor.updateSession({numberOfChargingPoints: actualValue});
    },
    [actor]
  );

  const options: RadioGroupOption[] = useMemo(
    () => [
      {value: '1', label: T('charger.numberOfConnections.one')},
      //{value: '2', label: T('charger.numberOfConnections.two')},
      {value: 'more', label: T('charger.numberOfConnections.more')}
    ],
    []
  );

  return (
    <BasicTab tab={TabKey.NumberOfConnections}>
      <ChoiceGroup
        as="radio"
        options={options}
        value={(numberOfChargingPoints || '').toString()}
        onChange={handleChange}
        disabled={readOnly}
      />
    </BasicTab>
  );
}
