import {T, TranslationKey} from '../core/Translate';

export function validateRequired(value: string, label: string) {
  return value.trim().length === 0 ? T('validator.required', {name: label}) : undefined;
}

export function validateEmail(value: string) {
  return /^[^@]+@[^.]+\..+$/.test(value) ? undefined : T('validator.invalidEmail');
}

export function validateOptionalEmail(value: string) {
  if (value.length === 0) {
    return undefined;
  } else if (/^[^@]+@[^.]+\..+$/.test(value)) {
    return undefined;
  } else {
    return T('validator.invalidEmail');
  }
}

export function validateMatching(regex: RegExp, error: TranslationKey) {
  return (value: string, label: string) => {
    if (value.length === 0) return T('validator.required', {name: label});

    return regex.test(value) ? undefined : T(error);
  };
}

export function validateOptionalMatching(regex: RegExp, error: TranslationKey) {
  return (value: string) => {
    if (value.length === 0) {
      return undefined;
    }

    return regex.test(value) ? undefined : T(error);
  };
}

export const validatePostcode = validateMatching(/^[0-9]{4}$/, 'validator.invalidPostcode');
export const validateVAT = validateMatching(/^BE[ ]?[0-9]{4}[ .-]?[0-9]{3}[ .-]?[0-9]{3}$/, 'validator.invalidVAT');
export const validateAmount = validateMatching(/^[0-9]+$/, 'validator.invalidAmount');
export const validateOptionalAmount = validateOptionalMatching(/^[0-9]+$/, 'validator.invalidAmount');

export function isValidEmail(value: string): boolean {
  return validateEmail(value) === undefined;
}
