import {Modal} from 'react-bootstrap';

import styles from './ExplanationModal.module.scss';
import MarkdownView from './MarkdownView';

interface ExplanationModalProps {
  show: boolean;
  title: string;
  explanation: string;
  extraInfo?: () => React.ReactNode;
  onClose: () => void;
}

export default function ExplanationModal(props: ExplanationModalProps) {
  const {show, title, explanation, extraInfo, onClose} = props;

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      style={{color: '#15241f'}}
      className={styles.modal}
      contentClassName={styles.modalContent}
    >
      <Modal.Header closeButton className={styles.modalHeader}>
        <h5 className={styles.modalTitle}>{title}</h5>
      </Modal.Header>
      <Modal.Body style={{fontSize: 16}}>
        <>
          <MarkdownView content={explanation} />
          {extraInfo && extraInfo()}
        </>
      </Modal.Body>
    </Modal>
  );
}
