import {library, dom, IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {faAngleDoubleLeft} from '@fortawesome/pro-light-svg-icons/faAngleDoubleLeft';
import {faAngleDoubleRight} from '@fortawesome/pro-light-svg-icons/faAngleDoubleRight';
import {faAngleLeft as faLightAngleLeft} from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import {faAngleRight as faLightAngleRight} from '@fortawesome/pro-light-svg-icons/faAngleRight';
import {faCircle as faLightCircle} from '@fortawesome/pro-light-svg-icons/faCircle';
import {faPlus} from '@fortawesome/pro-light-svg-icons/faPlus';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import {faPlus as faRegularPlus} from '@fortawesome/pro-regular-svg-icons/faPlus';
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch';
import {faCircle as faSolidCircle} from '@fortawesome/pro-solid-svg-icons/faCircle';
import {faCircleNotch} from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import {faFileDownload} from '@fortawesome/pro-solid-svg-icons/faFileDownload';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {faPlusCircle} from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import {faTrash} from '@fortawesome/pro-solid-svg-icons/faTrash';

// I don't understand why the type is not inferred here
export function registerIcons() {
  library.add(
    faLightCircle as IconDefinition,
    faSolidCircle as IconDefinition,
    faArrowLeft as IconDefinition,
    faArrowRight as IconDefinition,
    faPlus as IconDefinition,
    faInfoCircle as IconDefinition,
    faPlusCircle as IconDefinition,
    faTrash as IconDefinition,
    faRegularPlus as IconDefinition,
    faAngleLeft as IconDefinition,
    faAngleRight as IconDefinition,
    faSearch as IconDefinition,
    faCircleNotch as IconDefinition,
    faFileDownload as IconDefinition,
    faAngleDoubleLeft as IconDefinition,
    faAngleDoubleRight as IconDefinition,
    faLightAngleLeft as IconDefinition,
    faLightAngleRight as IconDefinition
  );
  dom.watch();
}
