import React, {useEffect, useMemo, useState} from 'react';
import {Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import Checkbox from '../components/inputs/Checkbox';
import Form from '../components/inputs/Form';
import TextInput from '../components/inputs/TextInput';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {getSurveyReasons, translateSurveyReason} from '../models/ConfiguratorData';
import {Discount} from '../models/Discount';
import {translateProductName} from '../models/Product';
import {
  getQuoteText,
  Quote,
  QuoteCompleteness,
  QuoteItem,
  QuoteItemCategory,
  translateMissingData
} from '../models/Quote';
import {getMissingItemDescription, IMissingItemDescription} from '../models/SessionData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {areActionCodesSupported} from '../utils/Features';
import {formatCurrency} from '../utils/Formatting';
import {useFormState} from '../utils/FormState';
import {useDelayedEffect} from '../utils/Hooks';

import styles from './QuoteTab.module.scss';

export function QuoteTab() {
  const [configuration, photos, token, readOnly] = useAppSelector(state => [
    state.session.data,
    state.session.photos,
    state.session.token,
    isReadOnly(state)
  ]);
  const actor = useAppActor();
  const formState = useFormState();

  const [quote, setQuote] = useState<Quote>();
  const [discount, setDiscount] = useState<Discount>();

  const text = quote && getQuoteText(quote.completeness);
  useEffect(() => console.log(getSurveyReasons(configuration, photos).map(translateSurveyReason)), [configuration]);
  const missingItems = useMemo(() => {
    const items = getSurveyReasons(configuration, photos)
      .map(reason => getMissingItemDescription(reason))
      .filter(item => item !== undefined) as IMissingItemDescription[];
    return items.map(item => (
      <li key={item.reason}>
        <a href="#" onClick={() => actor.setStep(item.tab)}>
          {item.description}
        </a>
      </li>
    ));
  }, [configuration, photos]);

  useEffect(() => {
    api.generateQuote(token, discount ? discount.code : undefined).then(setQuote);
  }, [discount, token]);
  useDelayedEffect(
    () => {
      if (readOnly) {
        return;
      }

      if (configuration.actionCode) {
        api.getDiscount(configuration.actionCode).then(discount => {
          setDiscount(discount);
          formState.setServerError(
            'action-code',
            discount.valid ? undefined : T('confirmation.quote.actionCode.invalid')
          );
        });
      } else {
        setDiscount(undefined);
        formState.setServerError('action-code', undefined);
      }
    },
    [configuration.actionCode],
    500
  );

  return (
    <BasicTab
      tab={TabKey.Quote}
      text={text}
      extraActionUrl={api.getQuoteDownloadUrl(token)}
      extraActionText={T('confirmation.quote.download')}
      nextDisabled={formState.getServerError('action-code') !== undefined}
    >
      {quote && (quote.missing.length > 0 || missingItems.length > 0) && (
        <ul>
          {quote.missing.map(missingItem => (
            <li key={missingItem}>{translateMissingData(missingItem)}</li>
          ))}
          {missingItems}
        </ul>
      )}
      {quote && quote.items.length > 0 && quote.completeness !== QuoteCompleteness.Complete && (
        <p>{T('confirmation.price.text.partialOffer')}</p>
      )}
      {quote && quote.items.length > 0 && <QuoteView quote={quote} />}
      {areActionCodesSupported() && (
        <>
          <p>{T('confirmation.quote.actionCode')}</p>
          <Form state={formState}>
            <Row>
              <TextInput
                name="action-code"
                value={configuration.actionCode || ''}
                label={T('confirmation.quote.actionCode.placeholder')}
                onChange={code => actor.updateSession({actionCode: code})}
                md={4}
                isValid={discount !== undefined}
                disabled={readOnly}
              />
            </Row>
          </Form>
        </>
      )}
      {quote && quote.completeness === QuoteCompleteness.Complete && (
        <Checkbox
          id="request-survey"
          checked={configuration.requestSurvey || false}
          label={T('confirmation.quote.requestSurvey')}
          onChange={checked => actor.updateSession({requestSurvey: checked})}
          disabled={readOnly}
        />
      )}
    </BasicTab>
  );
}

function QuoteView(props: {quote: Quote}) {
  const {quote} = props;
  const materials = quote.items.filter(item => item.category === QuoteItemCategory.Materials);
  const installation = quote.items.filter(item => item.category === QuoteItemCategory.Installation);
  const totalMaterials = materials.reduce((value, item) => value + (item.unitPrice || 0) * item.amount, 0);
  const totalInstallation = installation.reduce((value, item) => value + (item.unitPrice || 0) * item.amount, 0);
  const total = (totalMaterials + totalInstallation) * 1.21;

  return (
    <table className={styles.table}>
      <tbody>
        <tr className={styles.header}>
          <td colSpan={2}>{T('confirmation.price.section.charger')}</td>
        </tr>
        {materials.map(item => (
          <ItemRow key={item.product} item={item} />
        ))}
        <tr>
          <td>{T('quote.item.vat21')}</td>
          <td>€ {formatCurrency(totalMaterials * 0.21)}</td>
        </tr>
        <tr className={styles.header}>
          <td colSpan={2}>{T('confirmation.price.section.installation')}</td>
        </tr>
        {installation.map(item => (
          <ItemRow key={item.product} item={item} />
        ))}
        <tr>
          <td>{T('quote.item.vat21')}</td>
          <td>€ {formatCurrency(totalInstallation * 0.21)}</td>
        </tr>
        <tr className={styles.total}>
          <td>{T('quote.item.total')}</td>
          <td>€ {formatCurrency(total)}</td>
        </tr>
      </tbody>
    </table>
  );
}

function ItemRow(props: {item: QuoteItem}) {
  const {item} = props;
  return (
    <tr key={item.product}>
      <td>
        {item.name || translateProductName(item.product)} {item.amount > 1 && <>&times; {item.amount}</>}
      </td>
      <td>€ {item.unitPrice === undefined ? '---,--' : formatCurrency(item.unitPrice * item.amount)}</td>
    </tr>
  );
}
