export enum TabKey {
  Steps = 'steps',
  Information = 'information',
  SkipWizard = 'skip-wizard',
  Permission = 'permission',
  Building = 'building',

  Phases = 'phases',
  MaximumCurrent = 'maximum-current',
  Fuseboxes = 'fuseboxes',
  TechnicalPhotos = 'technical-photos',
  FreeTechnicalSpace = 'free-technical-space',
  SolarPanels = 'solar-panels',
  SolarPanelsUpload = 'solar-panels-upload',
  Connectivity = 'connectivity',
  PreparatoryWorks = 'preparatory-works',

  ChargerModel = 'charger-model',
  NumberOfConnections = 'number-of-connections',
  MountingMethod = 'mountingmethod',
  Cable = 'cable',
  Color = 'color',
  Situation = 'situation',
  SameFloor = 'same-floor',
  ChargingSpeed = 'charging-speed',
  CableLengths = 'cable-lengths',
  HolesAndDigging = 'holes-and-digging',
  CableTray = 'cable-tray',
  ExistingCharger = 'existing-charger',

  Quote = 'quote',
  InstallationBillingData = 'install-billing-data',
  SignQuote = 'signquote',
  Survey = 'survey',
  SignSurveyQuote = 'signsurveyquote',
  SignRemoteSurveyQuote = 'signremotesurveyquote',
  SubmittingRemoteSurveyRequest = 'submittingrsrequest',
  SurveyConfirmation = 'surveyconfirmation',
  SubmittingRequest = 'submittingrequest',
  Completed = 'completed',
  ContactSales = 'contact-sales',

  UCBInstallationBillingData = 'ucb-install-billing-data',

  Invalid = 'invalid'
}

export enum MainTabKey {
  General = 'general',
  Electrical = 'connection',
  Charger = 'charger',
  Confirmation = 'confirmation'
}

export function getMainTabIndex(key: MainTabKey): number {
  switch (key) {
    default:
      return 0;
    case MainTabKey.General:
      return 1;
    case MainTabKey.Electrical:
      return 2;
    case MainTabKey.Charger:
      return 3;
    case MainTabKey.Confirmation:
      return 4;
  }
}
