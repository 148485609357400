import {useEffect} from 'react';

import BasicTab from '../components/BasicTab';
import {api} from '../core/api';
import {SessionStatus} from '../models/SessionData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

export default function SubmittingRemoteSurveyRequestTab() {
  const [token, status] = useAppSelector(state => [state.session.token, state.session.status]);
  const actor = useAppActor();
  const waiting = status === SessionStatus.Filling;

  useEffect(() => {
    function checkSigned() {
      api.checkSurveyQuoteStatus(token).then(status => actor.setSessionStatus(status.sessionStatus));
    }

    const interval = setInterval(checkSigned, 5000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    api.createRemoteSurveyQuote(token);
  }, []);
  useEffect(() => {
    if (!waiting) {
      actor.setStep(TabKey.SurveyConfirmation);
    }
  }, [waiting]);

  return <BasicTab tab={TabKey.SubmittingRemoteSurveyRequest} nextDisabled={waiting} />;
}
