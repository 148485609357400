import React, {useEffect, useMemo, useState} from 'react';

import fillWizardIcon from '../assets/images/fill-wizard.png';
import onSiteSurveyIcon from '../assets/images/on-site-survey.png';
import remoteSurveyIcon from '../assets/images/remote-survey.png';
import BasicTab from '../components/BasicTab';
import ChoiceGroup from '../components/ChoiceGroup';
import {RadioGroupOption} from '../components/RadioGroup';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {getCombinedSkipWizardState, SkipWizard, SkipWizardCombined, SurveyMethod} from '../models/ConfiguratorData';
import {PublicPrices} from '../models/PublicPrices';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';

import {formatCurrencyWhole} from '../utils/Formatting';

export default function SkipWizardTab() {
  const [data, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const skipWizardCombined = getCombinedSkipWizardState(data);
  const actor = useAppActor();

  const [prices, setPrices] = useState<PublicPrices>({});
  useEffect(() => {
    api.getPrices().then(setPrices);
  }, []);

  const options: RadioGroupOption[] = useMemo(
    () => [
      {
        value: SkipWizardCombined.Fill,
        label: T('general.skipwizard.fill'),
        icon: fillWizardIcon
      },
      {
        value: SkipWizardCombined.SkipRemote,
        label: T('general.skipwizard.remoteSurvey', {
          price: prices.LazyRemoteSurvey ? formatCurrencyWhole(prices.LazyRemoteSurvey) : '---,--'
        }),
        icon: remoteSurveyIcon
      },
      {
        value: SkipWizardCombined.SkipOnSite,
        label: T('general.skipwizard.onSiteSurvey', {
          price: prices.OnSiteSurvey ? formatCurrencyWhole(prices.OnSiteSurvey) : '---,--'
        }),
        icon: onSiteSurveyIcon
      }
    ],
    [prices]
  );

  return (
    <BasicTab tab={TabKey.SkipWizard}>
      <ChoiceGroup
        as="buttons"
        options={options}
        value={skipWizardCombined}
        disabled={readOnly}
        width={150}
        height={150}
        onChange={value => {
          switch (value) {
            case SkipWizardCombined.Fill:
              actor.updateSession({skipWizard: SkipWizard.Fill, surveyMethod: undefined});
              return;
            case SkipWizardCombined.SkipRemote:
              actor.updateSession({skipWizard: SkipWizard.Skip, surveyMethod: SurveyMethod.Remote});
              return;
            case SkipWizardCombined.SkipOnSite:
              actor.updateSession({skipWizard: SkipWizard.Skip, surveyMethod: SurveyMethod.OnSite});
          }
        }}
      />
      {skipWizardCombined === SkipWizard.Fill && <p>{T('general.skipwizard.disclaimer')}</p>}
    </BasicTab>
  );
}
