import {useCallback} from 'react';

import {Tab} from 'react-bootstrap';

import AppLoadingIndicator from './components/AppLoadingIndicator';
import {CookieNagger} from './components/CookieNagger';
import Layout from './components/Layouts';
import ReadOnlyNotice from './components/ReadOnlyNotice';
import SaveError from './components/SaveError';
import UCBTabsHeader from './components/UCBTabsHeader';
import {MainTabKey, TabKey} from './models/TabKeys';
import {getFirstTabOfMain, getTabInfo, isTabAccessible} from './models/Tabs';
import {useAppActor, useAppSelector} from './redux';
import {isReadOnly} from './redux/AppState';
import ChargerModelTab from './tabs/ChargerModelTab';
import CompletedTab from './tabs/CompletedTab';
import SubmittingRequestTab from './tabs/SubmittingRequestTab';
import UCBInstallationBillingTab from './tabs/UCBInstallationBillingTab';

export function UCBApp(props: {country: string}) {
  const [ready, {step: currentTab, data, photos}, readOnly, saveError, status, project] = useAppSelector(state => [
    state.ready,
    state.session,
    isReadOnly(state),
    state.saveError,
    state.session.status,
    state.session.data.project
  ]);
  const tab = getTabInfo(currentTab);
  const actor = useAppActor();

  const setCurrentTab = useCallback(
    (tab: MainTabKey) => {
      const targetTab = getFirstTabOfMain(tab);
      if (!isTabAccessible(targetTab, data, photos, status)) {
        return;
      }

      actor.setStep(targetTab);
    },
    [actor, data, photos, status]
  );

  return (
    <Layout>
      {ready ? (
        <Tab.Container id="smpevc-tabs" activeKey={tab.mainTab} onSelect={key => setCurrentTab(key as MainTabKey)}>
          <UCBTabsHeader />
          {readOnly && currentTab !== TabKey.Completed && currentTab !== TabKey.SurveyConfirmation && (
            <ReadOnlyNotice />
          )}
          <Tab.Content>
            <Tab.Pane eventKey={MainTabKey.Charger}>
              {currentTab === TabKey.ChargerModel && <ChargerModelTab country={props.country} />}
            </Tab.Pane>
            <Tab.Pane eventKey={MainTabKey.Confirmation}>
              {currentTab === TabKey.UCBInstallationBillingData && <UCBInstallationBillingTab />}
              {currentTab === TabKey.SubmittingRequest && <SubmittingRequestTab />}
              {currentTab === TabKey.Completed && <CompletedTab />}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      ) : (
        <AppLoadingIndicator />
      )}
      {saveError && <SaveError />}
      <CookieNagger />
    </Layout>
  );
}
