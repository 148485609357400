import React from 'react';
import {Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import Col from '../components/Col';
import {Info} from '../components/Info';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {imageUrl} from '../utils/Images';

function CableTrayImages() {
  return (
    <Row>
      <Col md={4}>
        <img src={imageUrl('/assets/images/cable-tube.jpg')} />
      </Col>
    </Row>
  );
}

export default function CableTrayTab() {
  const [{cableTray}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  return (
    <BasicTab tab={TabKey.CableTray} extraInfo={CableTrayImages}>
      <YesNoRadioGroup
        value={cableTray}
        onChange={value => actor.updateSession({cableTray: value})}
        disabled={readOnly}
      />
      <Info>{T('charger.cableTray.text')}</Info>
    </BasicTab>
  );
}
