import {useEffect} from 'react';

import BasicTab from '../components/BasicTab';
import {api} from '../core/api';
import {SessionStatus} from '../models/SessionData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

export default function SubmittingRequestTab() {
  const [token, status, step, sessionData] = useAppSelector(state => [
    state.session.token,
    state.session.status,
    state.session.step,
    state.session.data
  ]);
  const actor = useAppActor();
  const waiting = status === SessionStatus.Filling;

  useEffect(() => {
    api
      .updateSession(token, sessionData, step) // to make sure the latest changes are also saved
      .then(() => api.submitMySessionForExecution(token))
      .then(status => actor.setSessionStatus(status.sessionStatus));
  }, []);

  useEffect(() => {
    if (!waiting) {
      actor.setStep(TabKey.Completed);
    }
  }, [waiting]);

  return <BasicTab tab={TabKey.SubmittingRequest} nextDisabled={waiting} />;
}
