import React, {useCallback, useMemo} from 'react';

import BasicTab from '../components/BasicTab';
import ChoiceGroup from '../components/ChoiceGroup';
import {T} from '../core/Translate';
import {BuildingType} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

import {isReadOnly} from '../redux/AppState';

export default function BuildingTypeTab() {
  const [buildingType, readOnly] = useAppSelector(state => [state.session.data.buildingType, isReadOnly(state)]);
  const actor = useAppActor();

  const handleTypeSelected = useCallback(
    (value: string) => actor.updateSession({buildingType: value as BuildingType}),
    [actor]
  );

  const options = useMemo(
    () => [
      {value: BuildingType.SingleFamilyHome, label: T('general.building.singleFamily')},
      {value: BuildingType.ApartmentBuilding, label: T('general.building.apartments')},
      {value: BuildingType.CommercialBuilding, label: T('general.building.commercial')}
    ],
    []
  );

  return (
    <BasicTab tab={TabKey.Building}>
      <ChoiceGroup
        as="radio"
        options={options}
        value={buildingType}
        onChange={handleTypeSelected}
        disabled={readOnly}
      />
    </BasicTab>
  );
}
