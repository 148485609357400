import {useMemo} from 'react';

import blackModel from '../assets/images/ev-wall-cable-black.jpg';
import whiteModel from '../assets/images/ev-wall-cable-white.jpg';
import BasicTab from '../components/BasicTab';
import ChoiceGroup from '../components/ChoiceGroup';
import {RadioGroupOption} from '../components/RadioGroup';
import {T} from '../core/Translate';
import {ChargerColor} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

import {isReadOnly} from '../redux/AppState';

export default function ChargerColorTab() {
  const [{color}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  const options: RadioGroupOption[] = useMemo(
    () => [
      {
        value: ChargerColor.White,
        label: T('charger.color.white'),
        icon: whiteModel
      },
      {
        value: ChargerColor.Black,
        label: T('charger.color.black'),
        icon: blackModel
      }
    ],
    []
  );

  return (
    <BasicTab tab={TabKey.Color}>
      {/*<p>{T('charger.color.message')}</p>*/}
      <ChoiceGroup
        as="buttons"
        options={options}
        value={color}
        onChange={color => actor.updateSession({color: color as ChargerColor})}
        width={250}
        height={250}
        disabled={readOnly}
        noBorder
      />
      <p>
        <i className="fas fa-info-circle" />
        &nbsp;
        {T('model.offering.disclaimer')}
      </p>
    </BasicTab>
  );
}
