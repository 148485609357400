import {Button as BsButton, ButtonProps} from 'react-bootstrap';

import {classes} from '../utils/Styles';

import styles from './Button.module.scss';

export default function Button(props: ButtonProps) {
  return (
    <BsButton {...props} className={classes(styles.button, props.className)}>
      {props.children}
    </BsButton>
  );
}

export function ButtonRow(props: {children: React.ReactNode}) {
  const {children} = props;
  return <div className={styles.buttonRow}>{children}</div>;
}
