import React, {useMemo} from 'react';

import {T} from '../core/Translate';

import {RadioGroup, RadioGroupOption} from './RadioGroup';

interface YesNoRadioGroupProps {
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export default function YesNoRadioGroup(props: YesNoRadioGroupProps) {
  const {value, onChange, disabled} = props;
  const options: RadioGroupOption[] = useMemo(
    () => [
      {value: 'y', label: T('generic.yes')},
      {value: 'n', label: T('generic.no')}
    ],
    []
  );

  return (
    <RadioGroup
      value={value === undefined ? '' : value ? 'y' : 'n'}
      options={options}
      setValue={value => onChange(value === 'y')}
      disabled={disabled}
    />
  );
}
