import {T, TranslationKey} from '../core/Translate';

export enum ProductCode {
  EVWall3PhaseSocket = 'EVW-332-BR-E-W',
  EVWall3Phase8Meter = 'EVW-332-C8R-E-W',
  EVWallBusinessSocket = 'EVWB-332-BR-E-W',
  EVWallBusiness8Meter = 'EVWB-332-C8R-E-W',
  EVBase1WithSocket = 'EVB-1332-BR-E',
  EVBase1With5Meter = 'EVB-1332-C5R-E',
  EVBase2WithSocket = 'EVB-2332-B-E',
  EVBase2With5Meter = 'EVB-2332-C5-E',

  Extension3PhaseSolar = 'EVW-SOLAR',
  CT100A = 'AC-CT-S-100A',
  EVBaseInfinityKit1Phase = 'i1-SK1',
  EVBaseInfinityKit3Phase = 'i1-SK3',
  EVBaseInfinityKit1PhaseSolar = 'i1-SK2',
  EVBaseInfinityKit3PhaseSolar = 'i1-SK6',

  StandardInstallation = 'UNIT-T-STD-INST',

  ExtraCable = 'UNIT-T-PWC-EXTRA',
  OnSiteSurvey = 'UNIT-T-ONSITE-SRVY',
  LazyRemoteSurvey = 'UNIT-T-TEL-SRVY',

  // not a real product
  InstallationCostsTBD = 'installation-costs-tbd',

  // not yet used
  DiscountOnCharger = 'discount-charger'
}

export enum ProductCategory {
  None,
  Charger,
  AdditionalMaterials,
  Installation,
  Survey
}

class ProductDefinition {
  code: ProductCode;
  name: TranslationKey;
  category: ProductCategory;

  constructor(code: ProductCode, name: TranslationKey, category: ProductCategory) {
    this.code = code;
    this.name = name;
    this.category = category;
  }
}

const products = [
  new ProductDefinition(ProductCode.EVWall3PhaseSocket, 'quote.item.wall3phaseSocket', ProductCategory.Charger),
  new ProductDefinition(ProductCode.EVWall3Phase8Meter, 'quote.item.wall3phaseCable8', ProductCategory.Charger),
  new ProductDefinition(ProductCode.EVWallBusinessSocket, 'quote.item.wallBusinessSocket', ProductCategory.Charger),
  new ProductDefinition(ProductCode.EVWallBusiness8Meter, 'quote.item.wallBusinessCable8', ProductCategory.Charger),
  new ProductDefinition(ProductCode.EVBase1WithSocket, 'quote.item.base1Socket', ProductCategory.Charger),
  new ProductDefinition(ProductCode.EVBase1With5Meter, 'quote.item.base1Cable5', ProductCategory.Charger),
  new ProductDefinition(ProductCode.EVBase2WithSocket, 'quote.item.base2Socket', ProductCategory.Charger),
  new ProductDefinition(ProductCode.EVBase2With5Meter, 'quote.item.base2Cable5', ProductCategory.Charger),

  new ProductDefinition(
    ProductCode.Extension3PhaseSolar,
    'quote.item.extension3PhaseSolar',
    ProductCategory.AdditionalMaterials
  ),
  new ProductDefinition(ProductCode.CT100A, 'quote.item.ct100a', ProductCategory.AdditionalMaterials),
  new ProductDefinition(
    ProductCode.EVBaseInfinityKit1Phase,
    'quote.item.infinityKit1Phase',
    ProductCategory.AdditionalMaterials
  ),
  new ProductDefinition(
    ProductCode.EVBaseInfinityKit3Phase,
    'quote.item.infinityKit3Phase',
    ProductCategory.AdditionalMaterials
  ),
  new ProductDefinition(
    ProductCode.EVBaseInfinityKit1PhaseSolar,
    'quote.item.infinityKit1PhaseSolar',
    ProductCategory.AdditionalMaterials
  ),
  new ProductDefinition(
    ProductCode.EVBaseInfinityKit3PhaseSolar,
    'quote.item.infinityKit3PhaseSolar',
    ProductCategory.AdditionalMaterials
  ),

  new ProductDefinition(
    ProductCode.StandardInstallation,
    'quote.item.standardInstallation',
    ProductCategory.Installation
  ),

  new ProductDefinition(
    ProductCode.InstallationCostsTBD,
    'quote.item.additionalExpensesTBD',
    ProductCategory.Installation
  ),
  new ProductDefinition(ProductCode.ExtraCable, 'quote.item.extraCable', ProductCategory.Installation),

  new ProductDefinition(ProductCode.OnSiteSurvey, 'quote.item.onSiteSurvey', ProductCategory.Survey),
  new ProductDefinition(ProductCode.LazyRemoteSurvey, 'quote.item.lazyRemoteSurvey', ProductCategory.Survey)
];

const productsByCode = products.reduce(
  (products, product) => products.set(product.code, product),
  new Map<ProductCode, ProductDefinition>()
);

export function translateProductName(code: ProductCode): string {
  const product = productsByCode.get(code);
  return T(product ? product.name : 'quote.item.unknown');
}

export function isCharger(code: ProductCode) {
  const product = productsByCode.get(code);
  return product ? product.category === ProductCategory.Charger : false;
}

export function isMaterial(code: ProductCode) {
  const product = productsByCode.get(code);
  return product
    ? product.category === ProductCategory.Charger || product.category === ProductCategory.AdditionalMaterials
    : false;
}

export function isSurvey(code: ProductCode) {
  const product = productsByCode.get(code);
  return product === undefined || product.category === ProductCategory.None;
}

export function getProductInfo(code: ProductCode) {
  return productsByCode.get(code);
}
