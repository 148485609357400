import React from 'react';
import {Nav} from 'react-bootstrap';

import {T} from '../core/Translate';
import {MainTabKey} from '../models/TabKeys';
import {getFirstTabOfMain, getStepsInTab, getTabInfo, isTabAccessible} from '../models/Tabs';
import {useAppSelector} from '../redux';
import {classes} from '../utils/Styles';

import {CircularProgressBar} from './CircularProgressBar';
import styles from './TabsHeader.module.scss';

export default function UCBTabsHeader() {
  const currentTab = useAppSelector(state => state.session.step);
  const tab = getTabInfo(currentTab);

  return (
    <Nav className={styles.navigation} id="onboardingwizard-top">
      <div className={styles.background} />
      <TabHeader
        title={T('tab.charger')}
        name={MainTabKey.Charger}
        selected={tab.mainTab}
        currentSubStep={tab.subTab}
        index={1}
      />
      <TabHeader
        title={T('tab.confirmation')}
        name={MainTabKey.Confirmation}
        selected={tab.mainTab}
        currentSubStep={tab.subTab}
        index={2}
      />
    </Nav>
  );
}
function getMainTabIndex(key: MainTabKey): number {
  switch (key) {
    default:
      return 0;
    case MainTabKey.Charger:
      return 1;
    case MainTabKey.Confirmation:
      return 2;
  }
}
interface TabHeaderProps {
  title: string;
  name: MainTabKey;
  selected: MainTabKey;
  currentSubStep: number;
  index: number;
}

function TabHeader(props: TabHeaderProps) {
  const {title, name, selected, currentSubStep, index} = props;
  const [data, photos, status] = useAppSelector(state => [
    state.session.data,
    state.session.photos,
    state.session.status
  ]);
  const completed = index <= getMainTabIndex(selected);
  const subSteps = getStepsInTab(name);
  const progress = selected === name ? (currentSubStep - 1) / subSteps : completed ? 1 : 0;
  const targetTab = getFirstTabOfMain(name);
  const disabled = !isTabAccessible(targetTab, data, photos, status);

  return (
    <Nav.Item>
      <Nav.Link
        eventKey={name}
        className={classes(styles.tabItem, completed && styles.completed, disabled && styles.disabled)}
      >
        {/*index > 1 && <div className={styles.tabLineLeft} />*/}
        {/*index < numberOfTabs && <div className={styles.tabLineRight} />*/}
        <CircularProgressBar ratio={progress}>
          <div className={styles.tabNumber}>{index}</div>
        </CircularProgressBar>
        <div className={styles.tabLabel}>{title}</div>
      </Nav.Link>
    </Nav.Item>
  );
}
