import {LanguageKey, T} from '../core/Translate';

import {ConfiguratorData, SurveyReason} from './ConfiguratorData';
import {Photo} from './Photo';
import {TabKey} from './TabKeys';

export interface SessionData {
  step: TabKey;
  data: ConfiguratorData;
  photos: Photo[];
  status: SessionStatus;
  language: LanguageKey;
}

export interface StoredSessionData extends SessionData {
  token: string;
}

export enum SessionStatus {
  Any = 'any', // only used for filtering
  NotFilling = 'not-filling', // only used for filtering

  Filling = 'filling',
  Submitted = 'submitted',
  Signed = 'signed',
  SurveySigned = 'survey-signed',

  Blocked = 'blocked', // something was wrong with this session and further processing has been blocked

  // below states are not yet final
  WillCallForSurvey = 'will-call-survey',
  WillCallForInstallationPlanning = 'will-call-planning',
  SurveyPlanned = 'survey-planned',
  AwaitingSurveyResults = 'awaiting-survey-results',
  AwaitingSignature = 'awaiting-signature',
  InstallationPlanned = 'installation-planned',
  Completed = 'completed'
}

export interface IMissingItemDescription {
  reason: SurveyReason;
  description: string;
  tab: TabKey;
}

export function getMissingItemDescription(reason: SurveyReason): IMissingItemDescription | undefined {
  switch (reason) {
    case SurveyReason.PhaseTypeUnknown:
      return {
        reason,
        description: T('missing.phaseType'),
        tab: TabKey.Phases
      };
    case SurveyReason.MaximumCurrentUnknown:
      return {
        reason,
        description: T('missing.maximumCurrent'),
        tab: TabKey.MaximumCurrent
      };
    case SurveyReason.CableLengthUnknown:
      return {
        reason,
        description: T('missing.cableLength'),
        tab: TabKey.CableLengths
      };
    case SurveyReason.NumberOfInvertersUnknown:
      return {
        reason,
        description: T('missing.numberOfInverters'),
        tab: TabKey.SolarPanels
      };
    case SurveyReason.InverterTypeUnknown:
      return {
        reason,
        description: T('missing.inverterType'),
        tab: TabKey.SolarPanels
      };
    case SurveyReason.InverterLocationUnknown:
      return {
        reason,
        description: T('missing.inverterLocation'),
        tab: TabKey.SolarPanels
      };
    case SurveyReason.TechnicalPanelPhotoMissing:
      return {
        reason,
        description: T('missing.technicalPanelPhoto'),
        tab: TabKey.TechnicalPhotos
      };
    case SurveyReason.FuseboxPhotoMissing:
      return {
        reason,
        description: T('missing.fuseboxPhoto'),
        tab: TabKey.TechnicalPhotos
      };
    case SurveyReason.MeterDetailPhotoMissing:
      return {
        reason,
        description: T('missing.meterDetailPhoto'),
        tab: TabKey.TechnicalPhotos
      };
    case SurveyReason.ChargingPointPhotoMissing:
      return {
        reason,
        description: T('missing.chargingPointPhoto'),
        tab: TabKey.Situation
      };
    case SurveyReason.InverterPhotoMissing:
      return {
        reason,
        description: T('missing.inverterPhoto'),
        tab: TabKey.SolarPanelsUpload
      };
    case SurveyReason.InverterFusesPhotoMissing:
      return {
        reason,
        description: T('missing.inverterFusesPhoto'),
        tab: TabKey.SolarPanelsUpload
      };
  }
}
