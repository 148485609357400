import React, {useState} from 'react';

import BasicTab from '../components/BasicTab';
import Form from '../components/inputs/Form';
import TextInput from '../components/inputs/TextInput';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';
import {imageUrl} from '../utils/Images';
import {validateOptionalAmount} from '../utils/Validation';

function CableLengthImages() {
  return <img src={imageUrl('/assets/images/cable-trajectory.png')} />;
}

export default function CableLengthsTab() {
  const [{estimatedCableLength}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();
  const formState = useFormState();

  const [estimatedCableLengthValue, setEstimatedCableLengthValue] = useState(
    estimatedCableLength === undefined ? '' : estimatedCableLength.toString()
  );

  const handleChange = (value: string) => {
    setEstimatedCableLengthValue(value);

    const numberValue = parseInt(value);
    actor.updateSession({
      estimatedCableLength: isNaN(numberValue) ? undefined : numberValue,
      cableLengthUnsure: false
    });
  };

  const handleClickedDontKnow = () => {
    actor.updateSession({cableLengthUnsure: true});
  };

  return (
    <BasicTab
      tab={TabKey.CableLengths}
      form={formState}
      onClickedDontKnow={handleClickedDontKnow}
      extraInfo={CableLengthImages}
    >
      <img src={imageUrl('/assets/images/cable-trajectory.png')} />
      <Form state={formState}>
        <TextInput
          name="estimated-cable-length"
          label={T('charger.cableLengths.meter')}
          value={estimatedCableLengthValue}
          suffix={T('charger.cableLengths.meter')}
          validate={validateOptionalAmount}
          onChange={handleChange}
          sm={4}
          md={3}
          disabled={readOnly}
        />
      </Form>
    </BasicTab>
  );
}
