import React from 'react';
import {Modal} from 'react-bootstrap';

import Checkbox from '../components/inputs/Checkbox';
import {Column} from '../models/Column';

interface ColumnSelectorProps<T> {
  show: boolean;
  selected: string[];
  columns: Column<T>[];
  onSelectedChanged: (columns: string[]) => void;
  onClose: () => void;
}

export default function ColumnSelector<T>(props: ColumnSelectorProps<T>) {
  const {show, selected, columns, onSelectedChanged, onClose} = props;

  const handleChanged = (column: Column<T>, checked: boolean) => {
    const newColumnNames = checked ? [...selected, column.name] : selected.filter(x => x !== column.name);
    const newColumnsSorted = columns.filter(x => newColumnNames.includes(x.name)).map(x => x.name);
    onSelectedChanged(newColumnsSorted);
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" style={{color: '#15241f'}}>
      <Modal.Header closeButton>Select columns</Modal.Header>
      <Modal.Body>
        {columns.map(column => (
          <Checkbox
            id={`column-${column.name}`}
            checked={selected.includes(column.name)}
            label={column.label}
            onChange={checked => handleChanged(column, checked)}
          />
        ))}
      </Modal.Body>
    </Modal>
  );
}
