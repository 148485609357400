import {useEffect, useRef} from 'react';

export function useDelayedEffect(callback: React.EffectCallback, dependencies: React.DependencyList, delay: number) {
  const loading = useRef(false);
  useEffect(() => {
    loading.current = true;
    const timeout = setTimeout(() => {
      loading.current = false;
      callback();
    }, delay);
    return () => clearTimeout(timeout);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return loading.current;
}
