import {classes} from '../utils/Styles';

import styles from './Col.module.scss';

interface ColProps {
  xs?: number;
  sm?: number;
  md?: number;
  className?: string;
  children: React.ReactNode;
}

export default function Col(props: ColProps) {
  const {xs, sm, md, className, children} = props;

  const classNames = classes(
    xs === undefined ? undefined : styles[`col-xs-${xs}`],
    sm === undefined ? undefined : styles[`col-sm-${sm}`],
    md === undefined ? undefined : styles[`col-md-${md}`],
    className
  );

  return <div className={classNames}>{children}</div>;
}
