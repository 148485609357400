import React, {useEffect} from 'react';
import {Button} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {SurveyMethod} from '../models/ConfiguratorData';
import {SessionStatus} from '../models/SessionData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';

import styles from './SignQuoteTab.module.scss';

export default function SignSurveyQuoteTab() {
  const [surveyMethod, token, status] = useAppSelector(state => [
    state.session.data.surveyMethod,
    state.session.token,
    state.session.status
  ]);
  const actor = useAppActor();
  const waiting = status === SessionStatus.Filling;

  useEffect(() => {
    function checkSigned() {
      api.checkSurveyQuoteStatus(token).then(status => actor.setSessionStatus(status.sessionStatus));
    }

    const interval = setInterval(checkSigned, 5000);
    return () => clearInterval(interval);
  });

  return (
    <BasicTab
      tab={surveyMethod === SurveyMethod.Remote ? TabKey.SignRemoteSurveyQuote : TabKey.SignSurveyQuote}
      nextDisabled={waiting}
    >
      <p className={styles['button-p']}>
        <Button size="lg" className={styles.button} href={api.getSignSurveyQuoteUrl(token)} target="_blank">
          {waiting ? T('confirmation.signquote.sign') : T('confirmation.signquote.view')}
        </Button>
      </p>
      {waiting && (
        <p style={{textAlign: 'center'}}>
          <i className="fas fa-spin fa-circle-notch" /> {T('confirmation.signquote.waiting')}
        </p>
      )}
    </BasicTab>
  );
}
