import dayjs from 'dayjs';
import React, {useEffect, useMemo, useState} from 'react';
import {Row, Table, Button} from 'react-bootstrap';

import Col from '../components/Col';
import Form from '../components/inputs/Form';
import SelectInput from '../components/inputs/SelectInput';
import TextInput from '../components/inputs/TextInput';
import Pagination from '../components/Pagination';
import {api} from '../core/api';
import {T} from '../core/Translate';
import {AdminSettings} from '../models/AdminSettings';
import {AdminViewableSession, SessionRequestType} from '../models/AdminViewableSession';
import {Column} from '../models/Column';
import {translateMountingMethod} from '../models/ConfiguratorData';
import {SessionStatus} from '../models/SessionData';
import {getTabInfo} from '../models/Tabs';
import {useFormState} from '../utils/FormState';

import {useDelayedEffect} from '../utils/Hooks';

import ColumnSelector from './ColumnSelector';
import CsvExportButton from './CsvExportButton';
import styles from './SessionsPanel.module.scss';

interface SessionsPanelProps {
  token: string;
  settings: AdminSettings;
  onChangeSettings: (updates: Partial<AdminSettings>) => void;
}

const COLUMNS: Column<AdminViewableSession>[] = [
  new Column('id', 'ID', session => session.id.toString()),
  new Column('firstName', 'First name', session => session.firstName),
  new Column('lastName', 'Last name', session => session.lastName),
  new Column('tab', 'Step', session => T(getTabInfo(session.step).title), {style: {width: 400}}),
  new Column('status', 'Status', session => translateSessionStatus(session.status)),
  new Column('project', 'Project', session => session.project || '?'),
  new Column('type', 'Type', session => translateRequestType(session.type)),
  new Column('mountingMethod', 'Mounting method', session =>
    session.mountingMethod ? translateMountingMethod(session.mountingMethod) : '?'
  ),
  new Column('numberOfChargingPoints', 'Charging points', session =>
    (session.numberOfChargingPoints || 'N/A').toString()
  ),
  new Column('created', 'Created', session => dayjs(session.updateTimestamp).format('DD/MM/YY HH:mm')),
  new Column('lastUpdated', 'Last updated', session => dayjs(session.updateTimestamp).format('DD/MM/YY HH:mm')),
  new Column('actionCode', 'Action code', session => session.actionCode || '')
];
const ColumnsByName = COLUMNS.reduce((indexed, column) => {
  indexed.set(column.name, column);
  return indexed;
}, new Map<string, Column<AdminViewableSession>>());

export default function SessionsPanel(props: SessionsPanelProps) {
  const {token, settings, onChangeSettings} = props;
  const {sessionColumns: selectedColumns} = settings;

  const [sessions, setSessions] = useState<AdminViewableSession[]>([]);
  const [totalSessions, setTotalSessions] = useState<number>(0);
  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState(SessionStatus.Any);
  const [project, setProject] = useState('');
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const formState = useFormState();

  useDelayedEffect(
    () => {
      api
        .searchSessions(token, filter, project, status === SessionStatus.Any ? undefined : status, offset, pageSize)
        .then(sessions => {
          setSessions(sessions.sessions);
          setTotalSessions(sessions.total);

          if (offset >= sessions.total) {
            setOffset(Math.floor(sessions.total / pageSize) * pageSize);
          }
        });
    },
    [token, filter, status, project, offset, pageSize],
    250
  );

  const handleOpenColumnSelector = () => setShowColumnSelector(true);

  const sessionRows = useMemo(() => {
    return sessions.map(session => (
      <SessionRow key={session.id} session={session} columns={selectedColumns} token={token} />
    ));
  }, [sessions, token, selectedColumns]);

  return (
    <>
      <Form state={formState}>
        <Row>
          <Col sm={3}>
            <TextInput name="filter" label="Search by name" value={filter} onChange={setFilter} />
          </Col>
          <Col sm={3}>
            <TextInput name="project" label="Search by project" value={project} onChange={setProject} />
          </Col>
          <Col sm={3}>
            <SelectInput label="Status" value={status} onChange={value => setStatus(value as SessionStatus)}>
              <option value={SessionStatus.Any}>Any status</option>
              <option value={SessionStatus.Filling}>Entering data</option>
              <option value={SessionStatus.NotFilling}>All except entering data</option>
              <option value={SessionStatus.Submitted}>Submitted</option>
              <option value={SessionStatus.Signed}>Quote signed</option>
              <option value={SessionStatus.SurveySigned}>Survey signed</option>
              <option value={SessionStatus.WillCallForInstallationPlanning}>Call for planning</option>
              <option value={SessionStatus.WillCallForSurvey}>Call for survey</option>
            </SelectInput>
          </Col>
          <Col sm={3} className={styles.buttons}>
            <Button onClick={handleOpenColumnSelector}>Select columns</Button>
            <CsvExportButton
              data={() =>
                api
                  .searchSessions(token, filter, project, status === SessionStatus.Any ? undefined : status, 0, 10000)
                  .then(sessions => sessions.sessions)
              }
              columns={COLUMNS.filter(x => selectedColumns.includes(x.name))}
              filename="sessions"
            />
          </Col>
        </Row>
      </Form>
      <Table className={styles.sessions}>
        <thead>
          <tr>
            {selectedColumns.map(columnName => {
              const column = ColumnsByName.get(columnName);
              return (
                <th key={columnName} style={column ? column.style : undefined}>
                  {column ? column.label : columnName}
                </th>
              );
            })}
            <th>PDF</th>
          </tr>
        </thead>
        <tbody>{sessionRows}</tbody>
      </Table>
      <Pagination
        offset={offset}
        limit={pageSize}
        total={totalSessions}
        onPaginate={(offset, limit) => {
          setOffset(offset);
          setPageSize(limit);
        }}
      />
      <ColumnSelector
        show={showColumnSelector}
        columns={COLUMNS}
        selected={selectedColumns}
        onClose={() => setShowColumnSelector(false)}
        onSelectedChanged={selected => onChangeSettings({sessionColumns: selected})}
      />
    </>
  );
}

interface SessionRowProps {
  session: AdminViewableSession;
  token: string;
  columns: string[];
}

function SessionRow(props: SessionRowProps) {
  const {session, token, columns} = props;
  return (
    <tr>
      {columns.map(columnName => {
        const column = ColumnsByName.get(columnName);
        return (
          <td key={columnName} style={column && column.style}>
            {column ? column.projection(session) : ''}
          </td>
        );
      })}
      <td>
        <a href={api.getSessionPDFUrl(session.id, token)} target="_blank" rel="noreferrer">
          download
        </a>
      </td>
    </tr>
  );
}

function translateSessionStatus(status: SessionStatus): string {
  switch (status) {
    case SessionStatus.Filling:
      return 'Entering data';
    case SessionStatus.Signed:
      return 'Quote signed';
    case SessionStatus.SurveySigned:
      return 'Survey signed';
    case SessionStatus.WillCallForSurvey:
      return 'Call for survey';
    case SessionStatus.WillCallForInstallationPlanning:
      return 'Call for planning';
    case SessionStatus.Submitted:
      return 'Submitted';
    default:
      return status;
  }
}

function translateRequestType(status: SessionRequestType) {
  switch (status) {
    case SessionRequestType.Filling:
      return 'Filling';
    case SessionRequestType.Installation:
      return 'Installation';
    case SessionRequestType.OnSiteSurvey:
      return 'On-site survey';
    case SessionRequestType.RemoteSurvey:
      return 'Remote survey';
    case SessionRequestType.SkipToOnSite:
      return 'Skip to on-site';
    case SessionRequestType.SkipToRemote:
      return 'Skip to remote';
    case SessionRequestType.Unknown:
      return 'Unknown';
    default:
      return status;
  }
}
