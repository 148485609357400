import $ from 'jquery';
import React, {CSSProperties} from 'react';

import 'blueimp-file-upload';
import {classes} from '../utils/Styles';

import styles from './PhotoUploader.module.scss';

/**
 * Initializes the upload component.
 *
 * The upload component should be a piece of HTML with this structure: (for example)
 *      <span id="personUploadDiv" class="pull-left btn btn-success btn-orange fileinput-button">
 *          Add Files
 *          <input type="file" name="files[]" multiple="">
 *      </span>
 *
 *  params: {
 *      formData: { data to be sent with the upload },
 *      start: function(data) to be called upon upload start,
 *      progress: function(data) to be called upon upload progress,
 *      success: function(data) to be called upon successful completion,
 *      fail: function(data) to be called upon upload failure,
 *      finish: function(data) to be called upon finish no matter the status
 *  }
 *
 * @param target target element
 * @param params upload parameters
 */

($ as any).widget('blueimp.fileupload', ($ as any).blueimp.fileupload, {
  processActions: {
    validate: function (data: any, options: any) {
      if (options.disabled) {
        return data;
      }

      var dfd = $.Deferred();
      var file = data.files[data.index];

      if (!options.acceptFileTypes.test(file.type)) {
        file.error = 'Invalid file type.';
        dfd.rejectWith(this, [data]);
      } else {
        dfd.resolveWith(this, [data]);
      }
      return dfd.promise();
    }
  }
});

export interface FileUploadParams {
  token: string;
  _id?: string;
  collection?: string;
  position?: string;
}
interface FileUploaderComponentProps {
  url: string;
  data: any;

  style?: CSSProperties;
  className?: string;

  onStart?: (e: unknown) => void;
  onProgress?: (e: unknown) => void;
  onSuccess?: (e: unknown) => void;
  onFail?: (e: unknown) => void;
  onFinish?: (e: unknown) => void;

  disabled?: boolean;

  children: React.ReactNode;
}
export class FileUploaderComponent extends React.PureComponent<FileUploaderComponentProps> {
  ref: React.RefObject<HTMLInputElement>;

  constructor(props: FileUploaderComponentProps) {
    super(props);

    this.ref = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    const target = this.ref.current;
    if (target) {
      this.initUpload(target);
    }
  }

  componentDidUpdate(oldProps: FileUploaderComponentProps) {
    if (oldProps.disabled !== this.props.disabled) {
      this.ref.current && ($(this.ref.current) as any).fileupload(this.props.disabled ? 'disable' : 'enabled');
    }
  }

  initUpload(target: HTMLInputElement) {
    const {
      url,
      data,

      onStart,
      onProgress,
      onSuccess,
      onFail,
      onFinish
    } = this.props;

    ($(target) as any).fileupload({
      url,
      dataType: 'json',
      paramName: 'file',
      formData: data,
      submit: function (e: any, data: any) {
        var $this = $(this) as any;
        data.jqXHR = $this.fileupload('send', data);
        return false;
      },
      progress: (e: any, data: any) => {
        onProgress && onProgress(data);
      }
    });

    //Bind upload event callbacks
    $(target)
      .bind('fileuploaddone', function (e, data) {
        onSuccess && onSuccess(data);
      })
      .bind('fileuploadsend', function (e, data) {
        onStart && onStart(data);
      })
      .bind('fileuploadalways', function (e, data) {
        onFinish && onFinish(data);
      })
      .bind('fileuploadfail', function (e, data) {
        onFail && onFail(data);
      });
  }

  render() {
    return (
      <div ref={this.ref} className={classes(styles.fileinputButton, this.props.className)} style={this.props.style}>
        {this.props.children}
        <input type="file" name="files[]" multiple />
      </div>
    );
  }
}
