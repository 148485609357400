import styles from './Gallery.module.scss';

interface GalleryProps {
  children: React.ReactNode;
}

export function Gallery(props: GalleryProps) {
  const {children} = props;

  return <div className={styles.gallery}>{children}</div>;
}
