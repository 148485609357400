import empty from '../languages/empty.json';
import en from '../languages/en.json';
import fr from '../languages/fr.json';
import nl from '../languages/nl.json';
// because phrase pull doesn't retrieve empty translations

export type TranslationKey = keyof (typeof en & typeof empty);

const languages: {[key: string]: Partial<typeof en & typeof empty>} = {nl, fr, en};

export enum LanguageKey {
  NL = 'nl',
  FR = 'fr',
  EN = 'en'
}

let currentLanguageKey: LanguageKey = LanguageKey.NL;
let currentLanguage = languages[currentLanguageKey];

export function T(key: TranslationKey, substitutions?: {[key: string]: string}) {
  if (substitutions) {
    let value =
      currentLanguage[key] ||
      nl[key as keyof typeof nl] ||
      en[key as keyof typeof en] ||
      empty[key as keyof typeof empty];
    for (var k in substitutions) {
      value = value.replace(`{${k}}`, substitutions[k]);
    }
    return value;
  } else {
    return (
      currentLanguage[key] ||
      nl[key as keyof typeof nl] ||
      en[key as keyof typeof en] ||
      empty[key as keyof typeof empty]
    );
  }
}

export function setCurrentLanguage(language: LanguageKey) {
  currentLanguageKey = language;
  if (!languages[language]) {
    console.log(`Invalid language: ${language}`);
  }
  currentLanguage = languages[language] || nl;
}

export function getCurrentLanguage() {
  return currentLanguageKey;
}
