import {SessionStatus, StoredSessionData} from '../models/SessionData';

export interface IAppState {
  session: StoredSessionData;
  ready: boolean;
  loadError: boolean;
  saveError: boolean;
}

export function isReadOnly(state: IAppState) {
  return state.session.status !== SessionStatus.Filling;
}
