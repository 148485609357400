import React, {useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import Form from '../components/inputs/Form';
import PasswordInput from '../components/inputs/PasswordInput';
import TextInput from '../components/inputs/TextInput';
import {api} from '../core/api';
import {useFormState} from '../utils/FormState';
import {validateRequired} from '../utils/Validation';

interface LoginPanelProps {
  onLogin: (token: string) => void;
}

export default function LoginPanel(props: LoginPanelProps) {
  const {onLogin} = props;

  const formState = useFormState();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleClickedLogin = () => {
    api.login(username, password).then(response => {
      if (response.status === 'ok') {
        onLogin(response.token);
      } else {
        formState.setServerError('username', 'Username or password invalid');
        formState.setServerError('password', 'Username or password invalid');
      }
    });
  };

  return (
    <Row>
      <Col sm={{span: 4, offset: 4}}>
        <Form state={formState}>
          <TextInput
            name="username"
            value={username}
            label="Username"
            onChange={setUsername}
            validate={validateRequired}
          />
          <PasswordInput
            name="password"
            value={password}
            label="Password"
            onChange={setPassword}
            validate={validateRequired}
          />
          <Button onClick={handleClickedLogin}>Login</Button>
        </Form>
      </Col>
    </Row>
  );
}
