import {Parser, HtmlRenderer, Node} from 'commonmark';
import {useMemo} from 'react';

import {imageUrl} from '../utils/Images';

interface MarkdownViewProps {
  content: string;
}

export default function MarkdownView(props: MarkdownViewProps) {
  const {content} = props;

  const html = useMemo(() => {
    var reader = new Parser();
    var writer = new HtmlRenderer();
    var parsed = reader.parse(content);
    processNode(parsed);
    var result = writer.render(parsed);
    return {__html: result};
  }, [content]);

  return <div dangerouslySetInnerHTML={html} />;
}

function processNode(node: Node) {
  if (node.type === 'image') {
    node.destination = imageUrl(`/assets/images/${node.destination}`);
  }

  let current = node.firstChild;
  while (current) {
    processNode(current);
    current = current.next;
  }
}
