import React, {useCallback, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';

import {api} from '../core/api';
import {getPreviewUrl, Photo} from '../models/Photo';
import {translatePhotoTag} from '../server/models/ServerSessionPhoto';

import {FileUploaderComponent} from './FileUploader';
import styles from './PhotoUploader.module.scss';

interface PhotoUploaderCarrouselProps {
  token: string;
  tag: string;
  photos: Photo[];
  onUploaded: (photos: Photo[]) => void;
  onDeleted: (id: number) => void;
  disabled: boolean;
}

export default function PhotoUploaderCarrousel(props: PhotoUploaderCarrouselProps) {
  const {token, tag, photos, onUploaded, onDeleted, disabled} = props;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index >= photos.length - 1) {
      setIndex(photos.length - 1);
    }
  }, [index, photos]);

  const handleClickedDelete = () => {
    onDeleted(photos[index].id);
  };

  const handleClickedNext = useCallback(() => setIndex(index => index + 1), []);
  const handleClickedPrevious = useCallback(() => setIndex(index => index - 1), []);

  return (
    <div className={styles.imgwrapper}>
      <img src={getPreviewUrl(photos[index])} alt={translatePhotoTag(tag)} />
      {index > 0 && (
        <Button variant="link" className={styles.previous} onClick={handleClickedPrevious}>
          <i className="far fa-angle-left" />
        </Button>
      )}
      {index < photos.length - 1 && (
        <Button variant="link" className={styles.next} onClick={handleClickedNext}>
          <i className="far fa-angle-right" />
        </Button>
      )}
      {!disabled && <MiniPhotoUploader token={token} tag={tag} onUploaded={onUploaded} />}
      <div className={styles.photoindex}>{`${index + 1}/${photos.length}`}</div>
      <div className={styles.buttons}>
        <a
          href={api.getPhotoUrl(photos[index].url)}
          target="_blank"
          rel="noreferrer"
          style={{padding: 5, display: 'inline-block'}}
        >
          <i className="far fa-search" />
        </a>
        {!disabled && (
          <Button variant="link" onClick={handleClickedDelete} className={styles.delete}>
            <i className="fas fa-trash" />
          </Button>
        )}
      </div>
    </div>
  );
}

interface MiniPhotoUploaderProps {
  token: string;
  tag: string;
  onUploaded: (photos: Photo[]) => void;
}

function MiniPhotoUploader(props: MiniPhotoUploaderProps) {
  const {token, tag, onUploaded} = props;

  const [uploading, setUploading] = useState(false);

  const handleStart = () => {
    setUploading(true);
  };

  const handleSuccess = (e: any) => {
    setUploading(false);
    onUploaded(e.result.files);
  };
  const handleFail = (e: any) => {
    setUploading(false);
    console.log(e);
  };

  return (
    <FileUploaderComponent
      url={api.getFileUploadUrl()}
      data={{token, tag}}
      className={styles.uploaderSmall}
      style={uploading ? {display: 'none'} : undefined}
      onStart={handleStart}
      onSuccess={handleSuccess}
      onFail={handleFail}
    >
      <Button variant="link">
        <div className={styles.addIconSmall}>
          <i className="fal fa-plus" />
        </div>
      </Button>
    </FileUploaderComponent>
  );
}
