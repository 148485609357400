import React, {useMemo} from 'react';

import BasicTab from '../components/BasicTab';
import {RadioGroup, RadioGroupOption} from '../components/RadioGroup';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';

export default function FuseboxesTab() {
  const [{multipleFuseboxes}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  const options: RadioGroupOption[] = useMemo(
    () => [
      {value: 'n', label: T('electrical.fuseboxes.single')},
      {value: 'y', label: T('electrical.fuseboxes.multiple')}
    ],
    []
  );

  const handleNumberChanged = (value: string) => {
    actor.updateSession({multipleFuseboxes: value === 'y'});
  };

  return (
    <BasicTab tab={TabKey.Fuseboxes}>
      <RadioGroup
        options={options}
        value={multipleFuseboxes === undefined ? '' : multipleFuseboxes ? 'y' : 'n'}
        setValue={handleNumberChanged}
        disabled={readOnly}
      />
    </BasicTab>
  );
}
