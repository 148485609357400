import Blob from 'blob';
import FileSaver from 'file-saver';

import React from 'react';

import Button from '../components/Button';
import {Column} from '../models/Column';

interface CsvExportButtonProps<T> {
  filename: string;
  data: () => Promise<T[]>;
  columns: Column<T>[];
}

export default function CsvExportButton<T>(props: CsvExportButtonProps<T>) {
  const {data, columns} = props;

  const convertToCSV = () => {
    const delimiter = ';';

    const lines = data().then(items =>
      items.map(item => {
        return columns
          .map(field => {
            let result = field.projection(item);
            if (result.includes(delimiter) || result.includes('"')) {
              result = `"${result.replace('"', '""')}"`;
            }
            return result;
          })
          .join(delimiter);
      })
    );
    const header = columns.map(field => field.name).join(delimiter);
    return lines.then(lines => `${header}\n${lines.join('\n')}`);
  };

  const handleClick = () => {
    convertToCSV().then(csvRaw => {
      const csv = csvRaw
        .normalize('NFD') // strip accents etc
        .replace(/[\u0300-\u036f]/g, '');

      const content = new Blob([csv], {type: 'text/csv;charset=utf-8'});
      const filename = `${props.filename}.csv`;
      FileSaver.saveAs(content, filename);
    });
  };

  return (
    <Button variant="link" onClick={handleClick} style={{marginLeft: '1rem'}}>
      <span className="fas fa-file-download" />
    </Button>
  );
}
