import React, {useMemo, useState} from 'react';
import {Row} from 'react-bootstrap';

import BasicTab from '../components/BasicTab';
import Col from '../components/Col';
import Checkbox from '../components/inputs/Checkbox';
import Form from '../components/inputs/Form';
import TextInput from '../components/inputs/TextInput';
import {RadioGroup, RadioGroupOption} from '../components/RadioGroup';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import {T} from '../core/Translate';
import {InverterLocation, InverterType, PhaseType} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';
import {imageUrl} from '../utils/Images';
import {validateAmount} from '../utils/Validation';

function getInverterName(index: number, single: boolean) {
  return single
    ? T('electrical.solarPanels.inverters.single')
    : T('electrical.solarPanels.inverters.x', {index: (index + 1).toString()});
}

function SolarPanelsImages() {
  return (
    <Row>
      <Col md={4}>
        <p style={{textAlign: 'center'}}>{T('electrical.solarPanels.info.monoPhase')}</p>
        <p style={{textAlign: 'center'}}>
          <img src={imageUrl('/assets/images/solarpanels-2p.jpg')} style={{maxWidth: 150}} />
        </p>
        <p style={{textAlign: 'center'}}>Max 35 mm</p>
      </Col>
      <Col md={8}>
        <p style={{textAlign: 'center'}}>{T('electrical.solarPanels.info.threePhase')}</p>
        <p style={{textAlign: 'center'}}>
          <img src={imageUrl('/assets/images/solarpanels-4p.jpg')} style={{maxWidth: 300}} />
        </p>
        <p style={{textAlign: 'center'}}>Max 70 mm</p>
      </Col>
    </Row>
  );
}

export default function SolarPanelsTab() {
  const [
    {hasSolar, numberOfInverters, numberOfInvertersUnknown, phaseType, inverterLocation, singleInverterType},
    readOnly
  ] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();
  const formState = useFormState();

  const [numberOfInvertersValue, setNumberOfInvertersValue] = useState(
    numberOfInverters === undefined ? '' : numberOfInverters.toString()
  );

  const setNumberOfInverters = (value: string) => {
    setNumberOfInvertersValue(value);

    const numberValue = parseInt(value);
    actor.updateSession({numberOfInverters: isNaN(numberValue) ? undefined : numberValue});
  };

  const hasMultipleInverters = numberOfInverters === undefined || numberOfInverters > 1;
  const inverterLocationOptions: RadioGroupOption[] = useMemo(
    () => [
      {
        value: InverterLocation.AtFusebox,
        label: T('electrical.solarPanels.inverterAtFusebox')
      },
      {
        value: InverterLocation.Elsewhere,
        label: T(
          hasMultipleInverters
            ? 'electrical.solarPanels.invertersElsewhere'
            : 'electrical.solarPanels.inverterElsewhere'
        )
      },
      {value: InverterLocation.Unknown, label: T('generic.dontknow')}
    ],
    [hasMultipleInverters]
  );

  return (
    <BasicTab tab={TabKey.SolarPanels} form={formState} extraInfo={SolarPanelsImages}>
      <Form state={formState}>
        <YesNoRadioGroup
          value={hasSolar}
          onChange={value => actor.updateSession({hasSolar: value})}
          disabled={readOnly}
        />
        {hasSolar && (
          <>
            <p>{T('electrical.solarPanels.numberOfInverters')}</p>
            {!numberOfInvertersUnknown && (
              <TextInput
                name="number-of-inverters"
                value={numberOfInvertersValue}
                onChange={setNumberOfInverters}
                validate={validateAmount}
                suffix={T('electrical.solarPanels.inverters')}
                label={T('electrical.solarPanels.inverters')}
                sm={6}
                md={5}
                disabled={readOnly || numberOfInvertersUnknown}
              />
            )}
            <Checkbox
              id="multiple-inverters-unknown"
              label={T('electrical.solarPanels.numberOfInvertersUnknown')}
              checked={numberOfInvertersUnknown}
              onChange={checked => actor.updateSession({numberOfInvertersUnknown: checked})}
              disabled={readOnly}
            />
            {!numberOfInvertersUnknown && (
              <RadioGroup
                label={T(
                  hasMultipleInverters
                    ? 'electrical.solarPanels.whereAreInverterFuses'
                    : 'electrical.solarPanels.whereIsInverterFuse'
                )}
                value={inverterLocation || ''}
                options={inverterLocationOptions}
                setValue={value => actor.updateSession({inverterLocation: value as InverterLocation})}
                disabled={readOnly}
              />
            )}
            {!numberOfInvertersUnknown && numberOfInverters === 1 && (
              <InverterTypeInput
                polyPhase={phaseType !== PhaseType.SinglePhase}
                value={singleInverterType}
                onChange={value => actor.updateSession({singleInverterType: value})}
                disabled={readOnly}
              />
            )}
          </>
        )}
      </Form>
    </BasicTab>
  );
}

interface InverterTypeInputProps {
  polyPhase: boolean;
  value?: InverterType;
  onChange: (type: InverterType) => void;
  disabled: boolean;
}

function InverterTypeInput(props: InverterTypeInputProps) {
  const {polyPhase, value, onChange, disabled} = props;

  const typeOptions: RadioGroupOption[] = useMemo(
    () => [
      {value: InverterType.Mono, label: T('electrical.solarPanels.inverterType.monoPhase')},
      {value: InverterType.Three, label: T('electrical.solarPanels.inverterType.threePhase')},
      {value: InverterType.Unknown, label: T('electrical.solarPanels.inverterType.unknown')}
    ],
    []
  );

  const name = getInverterName(0, true);

  return (
    <>
      {polyPhase && (
        <RadioGroup
          label={T('electrical.solarPanels.inverterType', {inverter: name})}
          value={value || ''}
          options={typeOptions}
          setValue={value => onChange(value as InverterType)}
          disabled={disabled}
        />
      )}
    </>
  );
}
