import React, {useEffect} from 'react';
import {Form} from 'react-bootstrap';

import {useFormContext} from '../../utils/FormContext';
import Col from '../Col';

import styles from './index.module.scss';

interface TextareaInputProps {
  name: string;
  value: string;
  label: string;
  sm?: number;
  md?: number;
  onChange: (value: string) => void;
  rows?: number;
  disabled?: boolean;
}

export default function TextareaInput(props: TextareaInputProps) {
  const {name, value, label, sm, md, onChange, rows = 4, disabled} = props;
  const formState = useFormContext();

  useEffect(() => () => formState.remove(name), [formState, name]);

  const shownError = formState.getShownError(name);

  return (
    <Col sm={sm} md={md} className={styles.inputGroup}>
      <Form.Group>
        <Form.Control
          as="textarea"
          placeholder={label}
          value={value}
          onChange={e => onChange(e.currentTarget.value)}
          className={styles.input}
          isInvalid={shownError !== undefined}
          rows={rows}
          disabled={disabled}
        />
        {shownError && <Form.Control.Feedback type="invalid">{shownError}</Form.Control.Feedback>}
      </Form.Group>
    </Col>
  );
}
