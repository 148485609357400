import {useState} from 'react';

import countryChargerModelsData from '../assets/data/countryChargerModels.json';
import oneImage from '../assets/images/ev-one.jpg';
import wallImageBlack from '../assets/images/ev-wall-cable-black.jpg';
import wallImageWhite from '../assets/images/ev-wall-cable-white.jpg';
import wallSocketImage from '../assets/images/ev-wall-socket.jpg';

import BasicTab from '../components/BasicTab';
import ChoiceGroup, {ChoiceGroupOptions} from '../components/ChoiceGroup';
import YesNoRadioGroup from '../components/YesNoRadioGroup';
import {T} from '../core/Translate';
import {CableType, ChargerColor, ChargerModel, MountingMethod} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';

const countryToChargerModels: {
  [key: string]: {
    models: ChargerModel[];
    colors?: {
      [key in ChargerModel]?: ChargerColor[];
    };
  };
} = countryChargerModelsData as any;

export default function ChargerModelTab(props: {country: string}) {
  const [{hasSolar, color, chargerModel}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  const countryPreset = props.country ? countryToChargerModels[props.country] : null;

  const [EVWallCableColor, setEVWallCableColor] = useState<ChargerColor>(
    color && chargerModel === ChargerModel.EVWallCable
      ? color
      : countryPreset?.colors?.[ChargerModel.EVWallCable]?.[0] || ChargerColor.White
  );

  const [EVWallSocketColor, setEVWallSocketColor] = useState<ChargerColor>(
    color && chargerModel === ChargerModel.EVWallSocket
      ? color
      : countryPreset?.colors?.[ChargerModel.EVWallSocket]?.[0] || ChargerColor.White
  );

  const options: ChoiceGroupOptions = [
    {
      value: ChargerModel.EVWallCable,
      label: T('charger.chargerModel.wall.label'),
      sublabel: EVWallCableColor === ChargerColor.Black ? T('charger.color.black') : T('charger.color.white'),
      icon: EVWallCableColor === ChargerColor.Black ? wallImageBlack : wallImageWhite,
      tooltip: T('charger.mountingMethod.wall.tooltip'),
      colorOptions: {
        options: [
          {
            value: ChargerColor.White,
            label: 'White',
            selected: EVWallCableColor === ChargerColor.White
          },
          {
            value: ChargerColor.Black,
            label: 'Black',
            selected: EVWallCableColor === ChargerColor.Black
          }
        ],
        onChange: color => {
          setEVWallCableColor(color as ChargerColor);
          if (chargerModel === ChargerModel.EVWallCable) {
            actor.updateSession({color: color as ChargerColor});
          }
        }
      }
    },
    {
      value: ChargerModel.EVWallSocket,
      label: T('charger.chargerModel.socket.label'),
      sublabel: EVWallSocketColor === ChargerColor.Black ? T('charger.color.black') : T('charger.color.white'),
      /**
       * @todo: Confirm color options and add correct image
       */
      icon: EVWallSocketColor === ChargerColor.Black ? wallImageBlack : wallSocketImage,
      colorOptions: {
        options: [
          {
            value: ChargerColor.White,
            label: 'White',
            selected: EVWallSocketColor === ChargerColor.White
          },
          {
            value: ChargerColor.Black,
            label: 'Black',
            selected: EVWallSocketColor === ChargerColor.Black
          }
        ],
        onChange: color => {
          setEVWallSocketColor(color as ChargerColor);
          if (chargerModel === ChargerModel.EVWallSocket) {
            actor.updateSession({color: color as ChargerColor});
          }
        }
      }
    },
    {
      value: ChargerModel.EVOne,
      label: T('charger.mountingMethod.one.label'),
      icon: oneImage,
      tooltip: T('charger.mountingMethod.one.tooltip')
    }
  ];

  const filteredOptions = options.filter(option => {
    if (!countryPreset) return true;

    const isModelAvailable = countryPreset.models.includes(option.value as ChargerModel);
    if (!isModelAvailable) return false;

    const availableColors = countryPreset.colors?.[option.value as ChargerModel] || [];
    if (option.colorOptions) {
      option.colorOptions.options = option.colorOptions.options.filter(colorOption =>
        availableColors.includes(colorOption.value as ChargerColor)
      );
    }

    return true;
  });

  const handleChargerModelChange = (chargerModel: ChargerModel) => {
    const presets = {
      [ChargerModel.EVWallCable]: {
        chargerModel: ChargerModel.EVWallCable,
        mountingMethod: MountingMethod.Wall,
        color: EVWallCableColor,
        cable: CableType.Cable8m
      },
      [ChargerModel.EVWallSocket]: {
        chargerModel: ChargerModel.EVWallSocket,
        mountingMethod: MountingMethod.Wall,
        color: EVWallSocketColor,
        cable: CableType.NoCable
      },
      [ChargerModel.EVOne]: {
        chargerModel: ChargerModel.EVOne,
        mountingMethod: MountingMethod.Standing,
        color: ChargerColor.Black,
        cable: CableType.NoCable
      }
    };

    actor.updateSession(presets[chargerModel]);
  };

  return (
    <BasicTab tab={TabKey.ChargerModel}>
      <ChoiceGroup
        as="buttons"
        options={filteredOptions}
        value={chargerModel}
        onChange={handleChargerModelChange as (value: string) => void}
        width={250}
        height={250}
        disabled={readOnly}
        showBox={true}
        noBorder
      />
      <div>
        <p>{T('electrical.solarPanels.title')}</p>
        <YesNoRadioGroup
          value={hasSolar}
          onChange={value => actor.updateSession({hasSolar: value})}
          disabled={readOnly}
        />
      </div>
    </BasicTab>
  );
}
