import {useMemo} from 'react';

import BasicTab from '../components/BasicTab';
import {RadioGroup, RadioGroupOption} from '../components/RadioGroup';
import {T} from '../core/Translate';
import {YesNoUnknown} from '../models/ConfiguratorData';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';

export default function FreeTechnicalSpaceTab() {
  const [{hasFreeSpace}, readOnly] = useAppSelector(state => [state.session.data, isReadOnly(state)]);
  const actor = useAppActor();

  const options: RadioGroupOption[] = useMemo(
    () => [
      {value: YesNoUnknown.Yes, label: T('generic.yes')},
      {value: YesNoUnknown.No, label: T('generic.no')},
      {value: YesNoUnknown.Unknown, label: T('generic.dontknow')}
    ],
    []
  );

  return (
    <BasicTab tab={TabKey.FreeTechnicalSpace}>
      <RadioGroup
        value={hasFreeSpace || ''}
        options={options}
        setValue={value => actor.updateSession({hasFreeSpace: value as YesNoUnknown})}
        disabled={readOnly}
      />
    </BasicTab>
  );
}
