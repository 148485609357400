import {T} from '../core/Translate';

import {PhotoId} from './Photo';

export interface ConfiguratorData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  agreesWithCallback?: boolean;
  skipWizard?: SkipWizard;

  project?: string;
  employeeNumber?: string;

  permission?: PermissionType;
  buildingType?: BuildingType;
  chargingSpeed?: ChargingSpeed;

  phaseType?: PhaseType;
  deltaTransformer?: boolean;
  maximumCurrent?: number;
  multipleFuseboxes?: boolean;
  hasFreeSpace?: YesNoUnknown;
  hasSolar?: boolean;
  numberOfInverters?: number;
  numberOfInvertersUnknown: boolean;
  inverterLocation?: InverterLocation;
  singleInverterType?: InverterType;
  hasRouterAtFusebox?: boolean;
  hasFreePortOnRouter?: boolean;
  userCanProvidePort?: boolean;
  hasWifiSignal?: boolean;
  preparatoryWorks?: boolean;
  preparatoryWorksInfo?: string;

  numberOfChargingPoints?: 1 | 2 | 'more';
  chargerModel?: ChargerModel;
  mountingMethod?: MountingMethod;
  color?: ChargerColor;
  hasWall?: boolean;
  cable?: CableType;
  sameFloor?: boolean;
  estimatedCableLength?: number;
  cableLengthUnsure: boolean;
  diggingSituation?: DiggingSituation;
  //numberOfHoles?: number;
  thickWalls: boolean;
  cableTray?: boolean;
  existingCharger?: boolean;
  keepExistingCharger?: boolean;

  chargerLocationDescription?: string;

  installationStreet: string;
  installationStreetNr: string;
  installationPostcode: string;
  installationCity: string;

  billingAtInstallation: boolean;
  billingFirstName: string;
  billingLastName: string;
  billingStreet: string;
  billingStreetNr: string;
  billingPostcode: string;
  billingCity: string;

  billingAsCompany: boolean;
  billingCompanyName: string;
  billingVatCode: string;

  requestSurvey?: boolean;
  actionCode?: string;

  surveyMethod?: SurveyMethod;

  explanation: string;

  // deprecated fields
}

// contains deprecated fields for migration
export interface OldConfiguratorData extends ConfiguratorData {
  hasMultipleInverters?: YesNoUnknown; // deprecated
  inverterDetails?: InverterDetail[]; // deprecated
}

export enum ChargerColor {
  White = 'white',
  Black = 'black'
}

export const DefaultConfiguratorData: ConfiguratorData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  cableLengthUnsure: false,
  thickWalls: false,
  agreesWithCallback: false,
  numberOfInvertersUnknown: false,

  color: ChargerColor.White,

  installationStreet: '',
  installationStreetNr: '',
  installationPostcode: '',
  installationCity: '',

  billingAtInstallation: true,
  billingFirstName: '',
  billingLastName: '',
  billingStreet: '',
  billingStreetNr: '',
  billingPostcode: '',
  billingCity: '',
  billingAsCompany: false,
  billingCompanyName: process.env.REACT_APP_UCB_CONFIG !== undefined ? `UCB-${process.env.REACT_APP_UCB_CONFIG}` : '',
  billingVatCode: '',

  explanation: ''
};

export enum SkipWizard {
  Fill = 'fill',
  Skip = 'skip'
}

export enum SkipWizardCombined {
  Fill = 'fill',
  SkipRemote = 'skip-remote',
  SkipOnSite = 'skip-onsite'
}

export function isSurveyFree(data: ConfiguratorData) {
  if (data.surveyMethod === SurveyMethod.OnSite) {
    return false;
  }
  if (data.skipWizard === SkipWizard.Skip) {
    return false;
  }

  return true;
}

export function getCombinedSkipWizardState(data: ConfiguratorData) {
  if (data.skipWizard !== SkipWizard.Skip) {
    return data.skipWizard;
  }

  return data.surveyMethod === SurveyMethod.OnSite ? SkipWizardCombined.SkipOnSite : SkipWizardCombined.SkipRemote;
}

export enum PermissionType {
  Owner = 'owner',
  OnBehalf = 'onbehalf'
}

export function translatePermissionType(type: PermissionType) {
  switch (type) {
    case PermissionType.Owner:
      return T('general.permission.confirm');
    case PermissionType.OnBehalf:
      return T('general.permission.onbehalf');
    default:
      return type;
  }
}

export enum BuildingType {
  SingleFamilyHome = 'single-family-home',
  ApartmentBuilding = 'apartment-building',
  CommercialBuilding = 'commercial-building'
}

export function translateBuildingType(type: BuildingType) {
  switch (type) {
    case BuildingType.SingleFamilyHome:
      return T('general.building.singleFamily');
    case BuildingType.ApartmentBuilding:
      return T('general.building.apartments');
    case BuildingType.CommercialBuilding:
      return T('general.building.commercial');
    default:
      return type;
  }
}

export enum ChargingSpeed {
  Fast = 'fast', // maximum 5 hours
  Slow = 'slow' // 10 hours or more
}

export function translateChargingSpeed(speed: ChargingSpeed) {
  switch (speed) {
    case ChargingSpeed.Fast:
      return T('general.chargingspeed.faster');
    case ChargingSpeed.Slow:
      return T('general.chargingspeed.fine');
    default:
      return speed;
  }
}

export enum PhaseType {
  SinglePhase = 'single',
  ThreePhaseStar = 'star',
  ThreePhaseDelta = 'delta'
}

export function translatePhaseType(type: PhaseType) {
  switch (type) {
    case PhaseType.SinglePhase:
      return T('electrical.phases.single.label');
    case PhaseType.ThreePhaseDelta:
      return T('electrical.phases.delta.label');
    case PhaseType.ThreePhaseStar:
      return T('electrical.phases.star.label');
    default:
      return type;
  }
}

export enum ChargerModel {
  EVWallCable = 'ev-wall-cable',
  EVWallSocket = 'ev-wall-socket',
  EVOne = 'ev-one'
}

export enum MountingMethod {
  Wall = 'wall',
  Standing = 'standing'
}

export function translateMountingMethod(method: MountingMethod) {
  switch (method) {
    case MountingMethod.Wall:
      return T('charger.mountingMethod.wall.label');
    case MountingMethod.Standing:
      return T('charger.mountingMethod.base.label');
    default:
      return method;
  }
}

export enum CableType {
  NoCable = 'socket',
  Cable2p5m = 'cable-2',
  Cable5m = 'cable-5',
  Cable8m = 'cable-8'
}

export function translateCableType(type: CableType) {
  switch (type) {
    case CableType.NoCable:
      return T('charger.cable.socket');
    case CableType.Cable2p5m:
      return T('charger.cable.2m');
    case CableType.Cable5m:
      return T('charger.cable.5m');
    case CableType.Cable8m:
      return T('charger.cable.8m');
    default:
      return type;
  }
}

export interface InverterDetail {
  type?: InverterType;
}

export enum InverterLocation {
  AtFusebox = 'at-fusebox',
  Elsewhere = 'elsewhere',
  Unknown = 'unknown'
}

export function translateInverterLocation(location: InverterLocation) {
  switch (location) {
    case InverterLocation.AtFusebox:
      return T('electrical.solarPanels.inverterAtFusebox');
    case InverterLocation.Elsewhere:
      return T('electrical.solarPanels.inverterElsewhere');
    case InverterLocation.Unknown:
      return T('generic.dontknow');
    default:
      return location;
  }
}

export enum InverterType {
  Mono = 'mono',
  Three = 'three',
  Unknown = 'unknown'
}

export function translateInverterType(type: InverterType) {
  switch (type) {
    case InverterType.Mono:
      return T('electrical.solarPanels.inverterType.monoPhase');
    case InverterType.Three:
      return T('electrical.solarPanels.inverterType.threePhase');
    case InverterType.Unknown:
      return T('generic.dontknow');
    default:
      return type;
  }
}

export enum DiggingSituation {
  NoDiggingNoHoles = 'no-digging-no-holes',
  HolesOnly = 'holes-only',
  Complex = 'complex',

  Simple = 'simple',
  DiggingNecessary = 'digging-necessary',
  DontKnow = 'dontknow'
}

export function translateDiggingSituation(situation: DiggingSituation) {
  switch (situation) {
    case DiggingSituation.NoDiggingNoHoles:
      return T('charger.holesAndDigging.none');
    case DiggingSituation.HolesOnly:
      return T('charger.holesAndDigging.holesOnly');
    case DiggingSituation.Complex:
      return T('charger.holesAndDigging.complex');

    case DiggingSituation.Simple:
      return T('charger.holesAndDigging.simple');
    case DiggingSituation.DiggingNecessary:
      return T('charger.holesAndDigging.digging');
    default:
      return situation;
  }
}

export enum SurveyMethod {
  Remote = 'remote',
  OnSite = 'onsite'
}

export function translateSurveyMethod(method: SurveyMethod) {
  switch (method) {
    case SurveyMethod.Remote:
      return T('confirmation.survey.remote');
    case SurveyMethod.OnSite:
      return T('confirmation.survey.onsite');
    default:
      return method;
  }
}

export enum YesNoUnknown {
  Yes = 'y',
  No = 'n',
  Unknown = '?'
}

export function translateYetNoUnknown(response: YesNoUnknown) {
  switch (response) {
    case YesNoUnknown.Yes:
      return T('generic.yes');
    case YesNoUnknown.No:
      return T('generic.no');
    case YesNoUnknown.Unknown:
      return T('generic.dontknow');
    default:
      return response;
  }
}

export function isDeltaTransformerAdvised(data: ConfiguratorData) {
  if (data.phaseType !== PhaseType.ThreePhaseDelta) {
    return false;
  }
  if (data.maximumCurrent === undefined) {
    return false;
  }

  return data.maximumCurrent < 25;
}

export function isGridUpgradeAdvised(data: ConfiguratorData) {
  if (data.chargingSpeed !== ChargingSpeed.Fast) {
    return false;
  }
  if (data.phaseType === PhaseType.SinglePhase) {
    return true;
  }
  if (data.maximumCurrent === undefined) {
    return false;
  } // will be survey anyways

  if (data.phaseType === PhaseType.ThreePhaseDelta) {
    return data.maximumCurrent < 25;
  } else if (data.phaseType === PhaseType.ThreePhaseStar) {
    return data.maximumCurrent < 20;
  } else {
    return false;
  }
}

export function willChargeSlowly(data: ConfiguratorData) {
  return (
    data.phaseType !== PhaseType.ThreePhaseStar && (data.maximumCurrent === undefined || data.maximumCurrent <= 25)
  );
}

export function isSurveyNeeded(data: ConfiguratorData, photos: PhotoId[]): boolean {
  return getSurveyReasons(data, photos).length > 0;
}

export function isSurveyRequest(data: ConfiguratorData, photos: PhotoId[]): boolean {
  return isSurveyNeeded(data, photos) || data.requestSurvey === true;
}

export enum SurveyReason {
  NotAHome,
  PhaseTypeUnknown,
  HasMultipleFuseboxes,
  NoFreeSpace,
  HasMultipleInverters,
  MaximumCurrentUnknown,
  MultipleChargingPoints,
  IsStandingModel,
  WallModelWithoutSuitableWall,
  NotOnSameFloor,
  CableLengthUnknown,
  CableTooLong,
  TooManyHoles,
  ChargingSpeedInsufficient,
  WallsTooThick,
  DiggingNecessary,
  DoesntAcceptCableTray,
  HasExistingCharger,
  Delta,
  NumberOfInvertersUnknown,
  InverterTypeUnknown,
  InverterLocationUnknown,
  PreparatoryWorks,
  LimitedCurrent,

  TechnicalPanelPhotoMissing,
  FuseboxPhotoMissing,
  MeterDetailPhotoMissing,
  ChargingPointPhotoMissing,
  InverterPhotoMissing,
  InverterFusesPhotoMissing
}

export function getSurveyReasons(data: ConfiguratorData, photos: PhotoId[]) {
  const reasons: SurveyReason[] = [];
  if (data.buildingType !== BuildingType.SingleFamilyHome) {
    reasons.push(SurveyReason.NotAHome);
  }
  if (data.phaseType === undefined) {
    reasons.push(SurveyReason.PhaseTypeUnknown);
  }
  if (data.multipleFuseboxes === true) reasons.push(SurveyReason.HasMultipleFuseboxes);
  if (data.hasFreeSpace !== YesNoUnknown.Yes) {
    reasons.push(SurveyReason.NoFreeSpace);
  }
  if (data.hasSolar) {
    if (data.numberOfInvertersUnknown || data.numberOfInverters === undefined) {
      reasons.push(SurveyReason.NumberOfInvertersUnknown);
    } else if (data.numberOfInverters > 1) {
      reasons.push(SurveyReason.HasMultipleFuseboxes);
    } else if (
      data.phaseType !== PhaseType.SinglePhase &&
      (data.singleInverterType === undefined || data.singleInverterType === InverterType.Unknown)
    ) {
      reasons.push(SurveyReason.InverterTypeUnknown);
    }
    if (data.inverterLocation !== InverterLocation.AtFusebox) {
      reasons.push(SurveyReason.InverterLocationUnknown);
    }
  }
  if (data.maximumCurrent === undefined) {
    reasons.push(SurveyReason.MaximumCurrentUnknown);
  } else if (data.maximumCurrent <= 16) {
    reasons.push(SurveyReason.LimitedCurrent);
  }
  if (data.numberOfChargingPoints === 'more' || (data.numberOfChargingPoints || 1) > 1) {
    reasons.push(SurveyReason.MultipleChargingPoints);
  }
  if (data.mountingMethod === MountingMethod.Standing) reasons.push(SurveyReason.IsStandingModel);
  if (data.mountingMethod === MountingMethod.Wall && data.hasWall !== true) {
    reasons.push(SurveyReason.WallModelWithoutSuitableWall);
  }
  if (data.sameFloor !== true) {
    reasons.push(SurveyReason.NotOnSameFloor);
  }
  if (data.cableLengthUnsure) {
    reasons.push(SurveyReason.CableLengthUnknown);
  }
  if ((data.estimatedCableLength || 0) > 25) {
    reasons.push(SurveyReason.CableTooLong);
  }
  //if ((data.numberOfHoles || 0) > 2)
  //  reasons.push(SurveyReason.TooManyHoles)
  if (willChargeSlowly(data) && data.chargingSpeed === ChargingSpeed.Fast) {
    reasons.push(SurveyReason.ChargingSpeedInsufficient);
  }
  if (data.diggingSituation === DiggingSituation.HolesOnly && data.thickWalls === true) {
    reasons.push(SurveyReason.WallsTooThick);
  }
  if (data.diggingSituation === DiggingSituation.DiggingNecessary) {
    reasons.push(SurveyReason.DiggingNecessary);
  }
  if (data.diggingSituation === DiggingSituation.Complex) {
    reasons.push(SurveyReason.DiggingNecessary);
  }
  if (data.cableTray !== true) {
    reasons.push(SurveyReason.DoesntAcceptCableTray);
  }
  if (data.existingCharger === true) {
    reasons.push(SurveyReason.HasExistingCharger);
  }
  if (data.phaseType === PhaseType.ThreePhaseDelta) {
    reasons.push(SurveyReason.Delta);
  }
  if (data.preparatoryWorks) {
    reasons.push(SurveyReason.PreparatoryWorks);
  }
  if (!photos.some(photo => photo.tag === 'technical-panel')) {
    reasons.push(SurveyReason.TechnicalPanelPhotoMissing);
  }
  if (!photos.some(photo => photo.tag === 'fusebox')) {
    reasons.push(SurveyReason.FuseboxPhotoMissing);
  }
  if (!photos.some(photo => photo.tag === 'meter-detail')) {
    reasons.push(SurveyReason.MeterDetailPhotoMissing);
  }
  if (!photos.some(photo => photo.tag === 'charging-point')) {
    reasons.push(SurveyReason.ChargingPointPhotoMissing);
  }

  if (data.hasSolar && !data.numberOfInvertersUnknown) {
    const numberOfInverters = data.numberOfInverters || 0;
    for (let i = 0; i < numberOfInverters; i++) {
      const tagForInverter = `inverter-${i + 1}`;
      const tagForFuses = `inverterfuses-${i + 1}`;
      if (!photos.some(photo => photo.tag === tagForInverter)) {
        reasons.push(SurveyReason.InverterPhotoMissing);
      }
      if (!photos.some(photo => photo.tag === tagForFuses)) {
        reasons.push(SurveyReason.InverterFusesPhotoMissing);
      }
    }
  }

  return reasons;
}

export function isUsingWifi(data: ConfiguratorData) {
  return (
    data.hasRouterAtFusebox === false ||
    (data.hasRouterAtFusebox === true && data.hasFreePortOnRouter === false && data.userCanProvidePort === false)
  );
}

export function migrateConfiguration(data: OldConfiguratorData): ConfiguratorData {
  if (data.numberOfInvertersUnknown === undefined) {
    data.numberOfInvertersUnknown = false;
  }

  if (data.hasMultipleInverters !== undefined) {
    if (data.hasMultipleInverters === YesNoUnknown.No) {
      data.numberOfInverters = 1;
    } else if (data.hasMultipleInverters === YesNoUnknown.Unknown) {
      data.numberOfInvertersUnknown = true;
    }
    data.hasMultipleInverters = undefined;
  }

  return data;
}

export function translateSurveyReason(reason: SurveyReason) {
  switch (reason) {
    case SurveyReason.NotAHome:
      return T('survey.notAHome');
    case SurveyReason.PhaseTypeUnknown:
      return T('survey.phaseTypeUnknown');
    case SurveyReason.HasMultipleFuseboxes:
      return T('survey.hasMultipleFuseboxes');
    case SurveyReason.NoFreeSpace:
      return T('survey.noFreeSpace');
    case SurveyReason.HasMultipleInverters:
      return T('survey.hasMultipleInverters');
    case SurveyReason.MaximumCurrentUnknown:
      return T('survey.maximumCurrentUnknown');
    case SurveyReason.MultipleChargingPoints:
      return T('survey.multipleChargingPoints');
    case SurveyReason.IsStandingModel:
      return T('survey.isStandingModel');
    case SurveyReason.WallModelWithoutSuitableWall:
      return T('survey.wallModelWithoutSuitableWall');
    case SurveyReason.NotOnSameFloor:
      return T('survey.notOnSameFloor');
    case SurveyReason.CableLengthUnknown:
      return T('survey.cableLengthUnknown');
    case SurveyReason.CableTooLong:
      return T('survey.cableTooLong');
    case SurveyReason.TooManyHoles:
      return T('survey.tooManyHoles');
    case SurveyReason.ChargingSpeedInsufficient:
      return T('survey.chargingSpeedInsufficient');
    case SurveyReason.WallsTooThick:
      return T('survey.wallsTooThick');
    case SurveyReason.DiggingNecessary:
      return T('survey.diggingNecessary');
    case SurveyReason.DoesntAcceptCableTray:
      return T('survey.doesntAcceptCableTray');
    case SurveyReason.HasExistingCharger:
      return T('survey.hasExistingCharger');
    case SurveyReason.Delta:
      return T('survey.delta');
    case SurveyReason.NumberOfInvertersUnknown:
      return T('survey.numberOfInvertersUnknown');
    case SurveyReason.InverterTypeUnknown:
      return T('survey.inverterTypeUnknown');
    case SurveyReason.InverterLocationUnknown:
      return T('survey.inverterLocationUnknown');
    case SurveyReason.PreparatoryWorks:
      return T('survey.preparatoryWorks');
    case SurveyReason.LimitedCurrent:
      return T('survey.limitedCurrent');
    case SurveyReason.TechnicalPanelPhotoMissing:
      return T('survey.technicalPanelPhotoMissing');
    case SurveyReason.FuseboxPhotoMissing:
      return T('survey.fuseboxPhotoMissing');
    case SurveyReason.MeterDetailPhotoMissing:
      return T('survey.meterDetailPhotoMissing');
    case SurveyReason.ChargingPointPhotoMissing:
      return T('survey.chargingPointPhotoMissing');
    case SurveyReason.InverterFusesPhotoMissing:
      return T('survey.inverterFusesPhotoMissing');
    default:
      return T('survey.default');
  }
}
