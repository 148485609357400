import {useMemo} from 'react';

import BasicTab from '../components/BasicTab';
import Form from '../components/inputs/Form';
import TextareaInput from '../components/inputs/TextareaInput';
import {RadioGroup} from '../components/RadioGroup';
import {T} from '../core/Translate';
import {TabKey} from '../models/TabKeys';
import {useAppActor, useAppSelector} from '../redux';
import {isReadOnly} from '../redux/AppState';
import {useFormState} from '../utils/FormState';

export default function PreparatoryWorksTab() {
  const [{preparatoryWorks, preparatoryWorksInfo = ''}, readOnly] = useAppSelector(state => [
    state.session.data,
    isReadOnly(state)
  ]);
  const actor = useAppActor();
  const formState = useFormState();

  const options = useMemo(
    () => [
      {value: 'y', label: T('electrical.preparatoryWorks.yes')},
      {value: 'n', label: T('electrical.preparatoryWorks.no')}
    ],
    []
  );

  return (
    <BasicTab tab={TabKey.PreparatoryWorks} form={formState}>
      <Form state={formState}>
        <RadioGroup
          value={preparatoryWorks === undefined ? '' : preparatoryWorks ? 'y' : 'n'}
          options={options}
          setValue={value => actor.updateSession({preparatoryWorks: value === 'y'})}
          disabled={readOnly}
        />
        {preparatoryWorks && (
          <>
            <p>{T('electrical.preparatoryWorks.info')}</p>
            <TextareaInput
              name="preparatoryWorksInfo"
              label={T('electrical.preparatoryWorks.info')}
              value={preparatoryWorksInfo}
              onChange={value => actor.updateSession({preparatoryWorksInfo: value})}
              sm={12}
            />
          </>
        )}
      </Form>
    </BasicTab>
  );
}
